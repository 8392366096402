import React from "react";
import FormContent from "./FormContent";
import Vision from "./vision";
import Banner from "./Banner";

const Home = () => {
  //   const email = "hello@ebbiapp.com";
  //   const subject = "Let's Talk with Ebbi";
  //   const body = `Thank you for showing interest, We will provide you access of our app on your phone soon
  // Thank you for you patience`;
  // const handleClick = () => {
  //   window.location.href = `mailto:${email}?subject=${encodeURIComponent(
  //     subject
  //   )}&body=${encodeURIComponent(body)}`;
  // };
  return (
    <>
      <Banner />
      <Vision />
      <FormContent />
    </>
  );
};

export default Home;
