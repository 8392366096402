import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const MainLayout = ({ selectedLanguage, setSelectedLanguage }) => {
  // const location = useLocation();
  return (
    <div>
      <div className={`text-off_white bg-primary`}>
        {/* {location.pathname === "/" ? (
          
        ) : null} */}
        <Header
          setSelectedLanguage={setSelectedLanguage}
          selectedLanguage={selectedLanguage}
        />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
