import React from "react";
import { Ebbi_gif, ScreenLP01 } from "../../assets";
import { Link } from "react-router-dom";
import { AppStoreIcon, PlayStoreIcon } from "../../assets/svg/AllSvg";
import strings from "../../components/localization";

const Banner = () => {
  return (
    <div
      className="h-screen flex justify-center items-center relative bg-cover bg-top bg-no-repeat"
      style={{
        backgroundImage: `url(${ScreenLP01})`,
      }}
    >
      <div className="container max-w-[750px] md:translate-x-5 md:translate-y-5 font-rajdhani px-6 w-full">
        <div className="flex items-center space-x-16 mb-3">
          <div className="pt-6">
            <div className="max-w-[300px] space-y-3 leading-[20px] sm:mb-3">
              <p className="md:hidden">Studio M presents :</p>
              <p className="font-rajdhani lg:text-[30px] text-[28px] sm:leading-8 leading-7 break-keep sm:font-semibold font-bold">
                {strings?.banner?.text1} <br /> {strings?.banner?.text2}
              </p>
              <div className="font-rajdhani lg:text-[30px] text-[28px] sm:leading-8 leading-7 break-keep sm:font-semibold font-bold py-1 px-3 relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:bg-primary before:h-full before:rounded before:-rotate-[0.5deg] before:-z-[0]">
                <p className="relative z-10">{strings?.banner?.text3}</p>
              </div>
              <div className="space-y-2">
                <p>{strings?.banner?.text4}</p>
                <p>{strings?.banner?.text5}</p>
                <p>{strings?.banner?.text6}</p>
              </div>
              <div className="flex gap-1">
                <div className="sm:max-w-28 max-w-32">
                  <Link
                    target="_blank"
                    to={"https://apps.apple.com/in/app/ebbi/id6705127544"}
                  >
                    <AppStoreIcon className="w-full h-full" />
                  </Link>
                </div>
                <div className="sm:max-w-28 max-w-32">
                  <Link
                    target="_blank"
                    to={"https://apps.apple.com/in/app/ebbi/id6705127544"}
                  >
                    <PlayStoreIcon className="w-full h-full" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="text-[10px]">
              <p>{strings?.banner?.text7}</p>
              <p>{strings?.banner?.text8}</p>
            </div>
            <div className="w-full md:hidden block mt-3">
              <Link
                target="_blank"
                to={"https://apps.apple.com/in/app/ebbi/id6705127544"}
              >
                <button className="bg-primary inline-block font-spaceGrotesk rounded-full font-bold text-2xl py-5 px-12 mb-3 w-full   no-underline">
                  let's talk
                </button>
              </Link>
            </div>
          </div>
          <div className="md:block hidden md:min-w-[258px]">
            <img
              src={Ebbi_gif}
              alt="phone"
              className="w-full h-[34rem] object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
