import React from "react";
import strings from "../../components/localization";
import { icon_1, icon_2 } from "../../assets";
const Vision = () => {
  return (
    <div className="lg:mt-8 md:mt-16 mt-0 lg:py-10 md:py-4" id="vision">
      <div className="container max-w-[750px] sm:px-8 px-6">
        <div className="grid-1 md:grid md:grid-cols-2 md:gap-x-10 md:gap-0 gap-0 lg:pt-2 md:pb-2 md:py-0 pt-5 pb-3 ">
          <h2 class="uppercase font-rajdhani 2xl:text-[40px] xl:text-[35px] lg:text-[35px] text-[28px] leading-10 font-bold">
            {strings?.vision?.visionTitle}
          </h2>
          <div className="flex flex-col justify-between col-start-1">
            <div className="lg:mt-3 md:mt-3 mt-0">
              <p class="font-rajdhani 2xl:text-xl xl:text-lg lg:text-base md:text-[15px] text-base 2xl:leading-6 xl:leading-5 lg:leading-4 md:leading-4 leading-[18px] font-medium">
                {strings?.vision?.text}
              </p>
            </div>
          </div>
          <div className="flex flex-col font-rajdhani lg:mt-3 md:mt-3 mt-0">
            <p className="font-rajdhani 2xl:text-xl xl:text-lg lg:text-base md:text-[15px] text-base 2xl:leading-6 xl:leading-5 lg:leading-4 md:leading-4 leading-[18px] font-medium">
              {strings?.vision?.text1}
            </p>
          </div>
          <div className="relative md:text-center flex justify-center col-span-2 sm:my-10 my-4 pt-10 ">
            <img
              src={icon_1}
              className="absolute top-0 left-0 lg:translate-y-0 sm:-translate-y-7 sm:w-14 w-8"
              alt={`"`}
            />
            <img
              src={icon_2}
              className="absolute sm:bottom-0 bottom-6 sm:right-0 right-5 sm:w-14 w-8"
              alt={`"`}
            />
            <div className="xl:max-w-[680px]">
              <h2 class="font-rajdhani lg:text-[28px] text-base lg:pt-8 xl:pt-8 sm:leading-8 leading-6 font-semibold break-keep ">
                {strings?.vision?.text2}
              </h2>
              <p className="font-rajdhani 2xl:text-xl xl:text-lg lg:text-base md:text-[15px] text-base leading-10 pt-4 font-medium">
                {strings?.vision?.ceoName}
              </p>
            </div>
          </div>
          <div className="md:mt-3 mt-0 ">
            <p class="font-rajdhani 2xl:text-xl xl:text-lg lg:text-base md:text-[15px] text-base 2xl:leading-6 xl:leading-5 lg:leading-4 md:leading-4 leading-[18px] font-medium ">
              {strings?.vision?.text3}
            </p>
            <div className="lg:mt-4 md:mt-3 mt-3 sm:block hidden">
              <h2 className="uppercase 2xl:text-[40px] xl:text-[35px] lg:text-[35px] text-[28px] leading-1 font-bold "></h2>
            </div>
            <p class="font-rajdhani 2xl:text-xl xl:text-lg lg:text-base md:text-[15px] text-base 2xl:leading-6 xl:leading-5 lg:leading-4 md:leading-4 leading-[18px] font-medium  sm:mt-0 mt-4 ">
              {strings?.vision?.text4}
            </p>
          </div>
          <div className="md:mt-3 mt-0 ">
            <p class="font-rajdhani 2xl:text-xl xl:text-lg lg:text-base md:text-[15px] text-base 2xl:leading-6 xl:leading-5 lg:leading-4 md:leading-4 leading-[18px] font-medium   sm:mt-0 mt-4">
              {strings?.vision?.text5}
            </p>
            <div className="lg:mt-3 md:mt-3 mt-3 sm:block hidden">
              <h2 className="uppercase 2xl:text-[40px] xl:text-[35px] lg:text-[35px] text-[28px] leading-1 font-bold "></h2>
            </div>
            <p class="font-rajdhani 2xl:text-xl xl:text-lg lg:text-base md:text-[15px] text-base 2xl:leading-6 xl:leading-5 lg:leading-4 md:leading-4 leading-[18px] font-medium   sm:mt-0 mt-4">
              {strings?.vision?.text6}
            </p>
            <div className="lg:mt-4 md:mt-3 mt-4 sm:block hidden">
              <h2 className="uppercase 2xl:text-[40px] xl:text-[35px] lg:text-[35px] text-[28px] leading-1 font-bold "></h2>
            </div>
            <p class="font-rajdhani 2xl:text-xl xl:text-lg lg:text-base md:text-[15px] text-base 2xl:leading-6 xl:leading-5 lg:leading-4 md:leading-4 leading-[18px] font-medium   sm:mt-0 mt-4">
              {strings?.vision?.text7}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
