import { useRef, useState } from "react";
import strings from "../../components/localization";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { submitInquiry } from "../../redux/form/slice";

const FormContent = () => {
  const dispatch = useDispatch();
  const recaptcha = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedAboutOption, setSelectedAboutOption] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isSuccessMsg, setIsSuccessMsg] = useState(null);
  const [isErrorMsg, setIsErrorMsg] = useState(null);

  const SITE_KEY = "6LfuJ-opAAAAAAjouu_B-aIWERlDV8MNGEBOD4OG";

  const validate = () => {
    const newErrors = {};
    if (!name) newErrors.name = strings?.form?.nameErrorMsg;
    if (!email) newErrors.email = strings?.form?.emailErrorMsg;
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = strings?.form?.validEmailErrormsg;
    }
    if (!message) newErrors.message = strings?.form?.messageErrorMsg;
    if (!selectedAboutOption) newErrors.about = strings?.form?.aboutErrorMsg;
    if (!recaptchaToken) newErrors.recaptcha = strings?.form?.reCaptcha;
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      const data = {
        name: name,
        email: email,
        message: message,
        about: selectedAboutOption,
      };
      try {
        const res = await dispatch(submitInquiry(data));
        setIsSubmitting(false);
        if (res) {
          setIsSuccessMsg(res?.payload?.message);
          e.target.reset();
          recaptcha?.current?.reset();
          setName("");
          setEmail("");
          setMessage("");
          setSelectedAboutOption("");
          setRecaptchaToken(null);
          setTimeout(() => {
            setIsSuccessMsg(null);
          }, 3000);
        } else {
          setTimeout(() => {
            setIsErrorMsg("Something went wrong");
          }, 3000);
        }
      } catch (error) {
        setIsSubmitting(false);
        setIsSuccessMsg(null);
        setIsErrorMsg(error ? error.toString() : "Something went wrong");
      }
    } else {
      setErrors(validationErrors);
      setIsSubmitting(false);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (errors?.name) {
      setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (errors?.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    if (errors?.message) {
      setErrors((prevErrors) => ({ ...prevErrors, message: "" }));
    }
  };

  const handleAboutOption = (value) => {
    setSelectedAboutOption(value);
    if (errors?.about) {
      setErrors((prevErrors) => ({ ...prevErrors, about: "" }));
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    if (errors?.recaptcha) {
      setErrors((prevErrors) => ({ ...prevErrors, recaptcha: "" }));
    }
  };

  return (
    <>
      <div
        className="relative lg:py-10 md:py-10 form-content-wrapper"
        id="form-content"
      >
        <div className="absolute inset-0 z-0 lg:block md:block hidden">
          <div className="absolute top-0 left-0 right-0 w-full h-20 bg-off_white skew-y-1"></div>
          <div className="absolute bottom-0 left-0 right-0 w-full h-16 bg-off_white -skew-y-1"></div>
        </div>
        <section className="relative bg-off_white">
          <div className="container max-w-[750px] sm:px-8 px-6 mx-auto">
            <form onSubmit={handleSubmit}>
              <div className="grid-1 grid lg:gap-x-20 xl:gap-x-24 md:gap-x-14 md:gap-0 gap-6 md:grid-cols-2 grid-cols-1 lg:grid-cols-2 lg:pt-2 md:pb-2 md:py-0 pt-5 pb-3">
                <div className="flex flex-col justify-between font-rajdhani">
                  <div className="lg:ml-3 md:ml-3 ml-0">
                    <h2 className="uppercase 2xl:text-[40px] xl:text-[35px] lg:text-[35px] text-[28px] leading-10 text-primary font-bold">
                      {strings?.form?.formHeader}
                    </h2>
                    <p className="font-rajdhani 2xl:text-xl xl:text-lg lg:text-base md:text-[15px] text-base 2xl:leading-6 xl:leading-5 lg:leading-4 md:leading-4 leading-[18px] font-medium sm:mt-0 mt-4 text-primary">
                      {strings?.form?.formDescription}
                    </p>

                    <div className="lg:mt-6 md:mt-6 mt-5">
                      {errors?.about && (
                        <div className="bg-red-200 px-3 py-3 my-4 w-[250px] rounded-md text-lg flex items-center max-w-lg">
                          <svg
                            viewBox="0 0 24 24"
                            className="text-red-600 w-4 h-4 sm:w-4 sm:h-4 mr-3"
                          >
                            <path
                              fill="currentColor"
                              d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
                            ></path>
                          </svg>
                          <span className="text-red-600 text-sm font-medium">
                            *{errors?.about}
                          </span>
                        </div>
                      )}
                      <p className="lg:text-[20px] text-[18px] leading-6 font-bold text-primary mb-2">
                        {" "}
                        {strings?.form?.radioBtnHeader}
                      </p>
                      <div className="mt-3 checkbox-content">
                        <div className="flex items-center lg:mb-3 md:mb-3 mb-1">
                          <label className="radio-container lg:ms-2 md:ms-2 ms-0 lg:text-[20px]  text-base lg:leading-6 md:leading-5 leading-6 font-medium text-primary">
                            {strings?.form?.radioText1}
                            <input
                              type="radio"
                              name="radio"
                              // value={strings?.form?.radioText1}
                              onChange={() =>
                                handleAboutOption(strings?.form?.radioText1)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="flex items-center lg:mb-3 md:mb-3 mb-1">
                          <label className="radio-container lg:ms-2 md:ms-2 ms-0 lg:text-[20px]  text-base lg:leading-6 md:leading-5 leading-6 font-medium text-primary">
                            {strings?.form?.radioText2}
                            <input
                              type="radio"
                              name="radio"
                              value={strings?.form?.radioText2}
                              onChange={() =>
                                handleAboutOption(strings?.form?.radioText2)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="flex items-center lg:mb-3 md:mb-3 mb-1">
                          <label className="radio-container lg:ms-2 md:ms-2 ms-0 lg:text-[20px]  text-base lg:leading-6 md:leading-5 leading-6 font-medium text-primary">
                            {strings?.form?.radioText3}
                            <input
                              type="radio"
                              name="radio"
                              value={strings?.form?.radioText3}
                              onChange={() =>
                                handleAboutOption(strings?.form?.radioText3)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="flex items-center lg:mb-3 md:mb-3 mb-1">
                          <label className="radio-container lg:ms-2 md:ms-2 ms-0 lg:text-[20px]  text-base lg:leading-6 md:leading-5 leading-6 font-medium text-primary">
                            {strings?.form?.radioText4}
                            <input
                              type="radio"
                              name="radio"
                              value={strings?.form?.radioText4}
                              onChange={() =>
                                handleAboutOption(strings?.form?.radioText4)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="flex items-center lg:mb-3 md:mb-3 mb-1">
                          <label className="radio-container lg:ms-2 md:ms-2 ms-0 lg:text-[20px]  text-base lg:leading-6 md:leading-5 leading-6 font-medium text-primary">
                            {strings?.form?.radioText5}
                            <input
                              type="radio"
                              name="radio"
                              value={strings?.form?.radioText5}
                              onChange={() =>
                                handleAboutOption(strings?.form?.radioText5)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="flex items-center lg:mb-3 md:mb-3 mb-1">
                          <label className="radio-container lg:ms-2 md:ms-2 ms-0 lg:text-[20px]  text-base lg:leading-6 md:leading-5 leading-6 font-medium text-primary">
                            {strings?.form?.radioText6}
                            <input
                              type="radio"
                              name="radio"
                              value={strings?.form?.radioText6}
                              onChange={() =>
                                handleAboutOption(strings?.form?.radioText6)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="lg:ml-3 md:ml-3 ml-0">
                    <p className="text-primary text-sm font-rajdhani font-medium lg:mt-12 md:mt-12 lg:block hidden md:block">
                      {strings?.form?.subText}
                    </p>
                  </div>
                </div>

                <div className="mr-3">
                  <div className="">
                    {isErrorMsg && (
                      <div className="bg-red-200 px-6 py-3 my-4 rounded-md text-lg flex items-center mx-auto max-w-lg">
                        <svg
                          viewBox="0 0 24 24"
                          className="text-red-600 w-4 h-4 sm:w-4 sm:h-4 mr-3"
                        >
                          <path
                            fill="currentColor"
                            d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
                          ></path>
                        </svg>
                        <span className="bg-red-600 text-sm font-normal">
                          {isErrorMsg}
                        </span>
                      </div>
                    )}
                    {isSuccessMsg && (
                      <div className="bg-green-200 px-6 py-3 my-4 rounded-md text-lg flex items-center mx-auto max-w-lg">
                        <svg
                          viewBox="0 0 24 24"
                          className="text-green-600 w-4 h-4 sm:w-4 sm:h-4 mr-3"
                        >
                          <path
                            fill="currentColor"
                            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                          ></path>
                        </svg>
                        <span className="text-green-600 text-sm font-normal">
                          {isSuccessMsg}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col justify-end lg:mt-11 md:mt-11 mt-2 font-rajdhani">
                    <div className="">
                      <label
                        htmlFor="name"
                        className="block lg:text-xl md:text-lg text-lg lg:leading-6 md:leading-5 leading-6 font-medium text-primary mb-1"
                      >
                        {strings?.form?.nameInput}
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="block w-full h-11 py-2 px-2 text-sm rounded border border-primary font-medium text-black"
                          placeholder=""
                          value={name}
                          onChange={handleNameChange}
                        />
                        {errors?.name && (
                          <p className="text-errorRed text-sm font-medium mt-1">
                            *{errors?.name}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="lg:mt-6 md:mt-6 mt-5">
                      <label
                        htmlFor="email"
                        className="block lg:text-xl md:text-lg text-lg lg:leading-6 md:leading-5 leading-6 font-medium text-primary mb-1"
                      >
                        {strings?.form?.nameEmail}
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          name="email"
                          id="email"
                          className="block text-black w-full h-11 py-2 px-2 text-sm rounded border border-primary font-medium"
                          placeholder=""
                          value={email}
                          onChange={handleEmailChange}
                        />
                        {errors?.email && (
                          <p className="text-errorRed text-sm font-medium mt-1">
                            *{errors?.email}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="lg:mt-6 md:mt-6 mt-5">
                      <label
                        htmlFor="message"
                        className="block lg:text-xl md:text-lg text-lg lg:leading-6 md:leading-5 leading-6 font-medium text-primary mb-1"
                      >
                        {strings?.form?.nameMessage}
                      </label>
                      <div className="relative">
                        <textarea
                          type="text"
                          rows="4"
                          name="message"
                          id="message"
                          className="block w-full px-3 py-1 text-sm text-black leading-6 rounded border border-primary font-medium "
                          placeholder=""
                          maxlength="50"
                          value={message}
                          onChange={handleMessageChange}
                        />
                        {errors?.message && (
                          <p className="text-errorRed text-sm font-medium mt-1">
                            *{errors?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-5">
                      <ReCAPTCHA
                        ref={recaptcha}
                        sitekey={SITE_KEY}
                        onChange={handleRecaptchaChange}
                      />
                      {errors.recaptcha && (
                        <div className="text-errorRed text-sm font-medium mt-1">
                          *{errors?.recaptcha}
                        </div>
                      )}
                    </div>
                    <div className="flex items-center justify-center md:justify-end lg:justify-end mt-9">
                      <button
                        type="submit"
                        className="flex items-center justify-center gap-3 bg-primary text-off_white rounded-[35px] lg:w-[220px] lg:h-[56px]
                                         md:w-[220px] md:h-[56px] w-[260px] h-[56px] text-[19px] font-bold font-rajdhani"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-off_white"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        ) : (
                          strings?.form?.submitBtn
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="lg:ml-3 md:ml-3 ml-0">
                  <p className="text-primary text-sm font-rajdhani font-medium lg:hidden block md:hidden">
                    {strings?.form?.subText}
                  </p>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
};

export default FormContent;
