import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
  en: {
    banner: {
      text1: "Get instant help for",
      text2: "the emotional moments in your everyday life.",
      text3: "Anytime. Everywhere.",
      text4:
        "ebbi is your personal AI agent in a safe and anonymous therapy chat form, hosted by psychologists, therapists and live coaches loaded with hours of educative audios.",
      text5:
        "ebbi listens, understands and advises 24/7 - both in individual and couple sessions with friends, family or partners.",
      text6: "ebbi is here to help you navigate life‘s journey.",
      text7:
        "*Subscription Plan: €12/month, billed monthly, cancelable at any time",
      text8:
        "**Currently available in Dansk, Deutsch, English, Français, Itialiano, Nederlands, Spanish, Suomi, Svenska",
    },
    text1: `The world is changing.`,
    text2: `So is the nature of psychological <br/> guidance.`,
    text3: `With ebbi it's all about putting your well-being
    first. Our app offers AI-powered conversations in
    combination with highly educative and motivative
    audio content covering topics like mindfulness or
    personal growth. Whether as a life coach or a
    relationship mediator, ebbi accompanies users on
    their journey to deeper self-understanding and
    mental wellness.`,
    text4:
      "*Subscription Plan: € 48/month, billed monthly, cancelable at any time.",
    text5:
      "**Couple therapy from two separate devices possible through a one-time in-app purchase.",
    text6:
      "***Currently available in Dansk, Deutsch, English, Français, Nederlands, Suomi and Svenska. More languages will follow.",
    form: {
      formHeader: "SAY HELLO",
      formDescription:
        "Do you have any questions or suggestions? We are here for you and always endeavor to answer as quickly as possible.",
      radioBtnHeader: "What is it about?",
      radioText1: "Customer feedback",
      radioText2: "Press inquiry*",
      radioText3: "Cooperation*",
      radioText4: "Keynote*",
      radioText5: "Sponsoring",
      radioText6: "Others",
      nameInput: "Enter your name",
      nameEmail: "Enter your email",
      nameMessage: "Enter your message",
      subText: "*Professional email required",
      submitBtn: "Submit",
      nameErrorMsg: "Name is required",
      emailErrorMsg: "Email is required",
      validEmailErrormsg: "Invalid email",
      messageErrorMsg: "Message is required",
      reCaptcha: "Please complete the ReCAPTCHA",
      aboutErrorMsg: "Please select about Option",
    },
    footer: {
      footerDescription: "Copyright © 2024",
      footerDescription1: "STUDIO M ",
      footerDescription2: "Lda. All rights reserved.",
      footerDescription3: "General terms and conditions.",
      footerDescription4: "Privacy policy.",
      footerDescription5: "Follow us on",
      footerDescription6: "and",
      instagramText: "Instagram",
      linkedInText: "LinkedIn",
    },
    cookie_policy: {
      policy: "Cookie Policy of ebbi",
      policy1: `This document informs Users about the technologies that help this
              Application to achieve the purposes described below. Such
              technologies allow the Owner to access and store information (for
              example by using a Cookie) or use resources (for example by
              running a script) on a User’s device as they interact with this
              Application.`,
      policy2: ` For simplicity, all such technologies are defined as "Trackers"
              within this document – unless there is a reason to differentiate.
              For example, while Cookies can be used on both web and mobile
              browsers, it would be inaccurate to talk about Cookies in the
              context of mobile apps as they are a browser-based Tracker. For
              this reason, within this document, the term Cookies is only used
              where it is specifically meant to indicate that particular type of
              Tracker.`,
      policy3: ` Some of the purposes for which Trackers are used may also require
              the User's consent. Whenever consent is given, it can be freely
              withdrawn at any time following the instructions provided in this
              document.`,
      policy4: ` This Application uses Trackers managed directly by the Owner
              (so-called “first-party” Trackers) and Trackers that enable
              services provided by a third-party (so-called “third-party”
              Trackers). Unless otherwise specified within this document,
              third-party providers may access the Trackers managed by them.`,
      policy5: `  The validity and expiration periods of Cookies and other similar
              Trackers may vary depending on the lifetime set by the Owner or
              the relevant provider. Some of them expire upon termination of the
              User’s browsing session. In addition to what’s specified in the
              descriptions within each of the categories below, Users may find
              more precise and updated information regarding lifetime
              specification as well as any other relevant information — such as
              the presence of other Trackers — in the linked privacy policies of
              the respective third-party providers or by contacting the Owner.`,
      policy6: `How this Application uses Trackers`,
      policy7: `Necessary`,
      policy8: `This Application uses so-called “technical” Cookies and other
                similar Trackers to carry out activities that are strictly
                necessary for the operation or delivery of the Service.`,
      policy9: `Trackers managed by third parties`,
      policy10: `iubenda Privacy Controls and Cookie Solution (iubenda srl)`,
      policy11: ` The iubenda Privacy Controls and Cookie Solution allows the
                Owner to collect and store Users’ preferences related to the
                processing of personal information, and in particular to the use
                of Cookies and other Trackers on this Application.`,
      policy12: `Personal Data processed: IP address and Trackers.`,
      policy13: `Place of processing: Italy`,
      policy14: `Privacy Policy.`,
      policy15: `Storage duration:`,
      policy16: `_iub_cs-*: 1 year`,
      policy17: `Functionality`,
      policy18: `This Application uses Trackers to enable basic interactions and
      functionalities, allowing Users to access selected features of
      the Service and facilitating the User's communication with the
      Owner.`,
      policy19: `Trackers managed by third parties`,
      policy20: `Mailing list or newsletter ()`,
      policy21: `By registering on the mailing list or for the newsletter, the
                User’s email address will be added to the contact list of those
                who may receive email messages containing information of
                commercial or promotional nature concerning this Application.
                Your email address might also be added to this list as a result
                of signing up to this Application or after making a purchase.`,
      policy22: `Personal Data processed: email address, Trackers and Usage Data.`,
      policy23: `Experience`,
      policy24: `This Application uses Trackers to improve the quality of the
                user experience and enable interactions with external content,
                networks and platforms.`,
      policy25: `Trackers managed by third parties`,
      policy26: `Google Fonts (Google LLC)`,
      policy27: ` Google Fonts is a typeface visualization service provided by
                Google LLC that allows this Application to incorporate content
                of this kind on its pages.`,
      policy28: `Personal Data processed: Trackers and Usage Data.`,
      policy29: `Place of processing: United States`,
      policy30: `Privacy Policy.`,
      policy31: `Facebook Like button and social widgets (Meta Platforms, Inc.)`,
      policy32: `The Facebook Like button and social widgets are services
                allowing interaction with the Facebook social network provided
                by Meta Platforms, Inc.`,
      policy33: `Personal Data processed: Trackers and Usage Data.`,
      policy34: `Place of processing: United States`,
      policy35: `Privacy Policy.`,
      policy36: `Opt out.`,
      policy37: `Storage duration:`,
      policy38: `_fbp: 3 months`,
      policy39: `How to manage preferences and provide or withdraw consent on
                this Application`,
      policy40: `Whenever the use of Trackers is based on consent, users can
                provide or withdraw such consent by setting or updating their
                preferences via the relevant privacy choices panel available on
                this Application.`,
      policy41: `With regard to any third-party Trackers, Users can manage their
                preferences via the related opt-out link (where provided), by
                using the means indicated in the third party's privacy policy,
                or by contacting the third party.`,
      policy42: `How to control or delete Cookies and similar technologies via
                your device settings`,
      policy43: `Users may use their own browser settings to:`,
      policy44: `See what Cookies or other similar technologies have been set
                  on the device;`,
      policy45: `Block Cookies or similar technologies;`,
      policy46: `Clear Cookies or similar technologies from the browser.`,
      policy47: `The browser settings, however, do not allow granular control of consent by category.`,
      policy48: `Users can, for example, find information about how to manage Cookies in the most commonly used browsers at the following addresses:`,
      policy49: `Google Chrome`,
      policy50: `Mozilla Firefox`,
      policy51: `Apple Safari`,
      policy52: `Microsoft Internet Explorer`,
      policy53: `Microsoft Edge`,
      policy54: `Brave`,
      policy55: `Opera`,
      policy56: `Users may also manage certain categories of Trackers used on
                mobile apps by opting out through relevant device settings such
                as the device advertising settings for mobile devices, or
                tracking settings in general (Users may open the device settings
                and look for the relevant setting).`,
      policy57: `Consequences of denying the use of Trackers`,
      policy58: `Users are free to decide whether or not to allow the use of
                Trackers. However, please note that Trackers help this
                Application to provide a better experience and advanced
                functionalities to Users (in line with the purposes outlined in
                this document). Therefore, if the User chooses to block the use
                of Trackers, the Owner may be unable to provide related
                features.`,
      policy59: `Owner and Data Controller`,
      policy60: `Studio M, Unipessoal Lda.`,
      policy61: `David Marx Frh von Mueller`,
      policy62: `Stio Pelourinho`,
      policy63: `7630-578 Sao teotonio`,
      policy64: `Beja`,
      policy65: `Portugal`,
      policy66: `Owner contact email:`,
      policy67: `hello@ebbiapp.com`,
      policy68: `Since the use of third-party Trackers through this Application
                cannot be fully controlled by the Owner, any specific references
                to third-party Trackers are to be considered indicative. In
                order to obtain complete information, Users are kindly requested
                to consult the privacy policies of the respective third-party
                services listed in this document.`,
      policy69: `Given the objective complexity surrounding tracking
                technologies, Users are encouraged to contact the Owner should
                they wish to receive any further information on the use of such
                technologies by this Application.`,
      policy70: `Definitions and legal references`,
      policy71: `Personal Data (or Data)`,
      policy72: `Any information that directly, indirectly, or in connection with
                other information — including a personal identification number —
                allows for the identification or identifiability of a natural
                person.`,
      policy73: `Usage Data`,
      policy74: `Information collected automatically through this Application (or
                third-party services employed in this Application), which can
                include: the IP addresses or domain names of the computers
                utilized by the Users who use this Application, the URI
                addresses (Uniform Resource Identifier), the time of the
                request, the method utilized to submit the request to the
                server, the size of the file received in response, the numerical
                code indicating the status of the server's answer (successful
                outcome, error, etc.), the country of origin, the features of
                the browser and the operating system utilized by the User, the
                various time details per visit (e.g., the time spent on each
                page within the Application) and the details about the path
                followed within the Application with special reference to the
                sequence of pages visited, and other parameters about the device
                operating system and/or the User's IT environment.`,
      policy75: `User`,
      policy76: ` The individual using this Application who, unless otherwise
                specified, coincides with the Data Subject.`,
      policy77: `Data Subject`,
      policy78: `The natural person to whom the Personal Data refers.`,
      policy79: `Data Processor (or Processor)`,
      policy80: `The natural or legal person, public authority, agency or other
                body which processes Personal Data on behalf of the Controller,
                as described in this privacy policy.`,
      policy81: `Data Controller (or Owner)`,
      policy82: `The natural or legal person, public authority, agency or other
                body which, alone or jointly with others, determines the
                purposes and means of the processing of Personal Data, including
                the security measures concerning the operation and use of this
                Application. The Data Controller, unless otherwise specified, is
                the Owner of this Application.`,
      policy83: `This Application`,
      policy84: `The means by which the Personal Data of the User is collected
                and processed.`,
      policy85: `Service`,
      policy86: `The service provided by this Application as described in the
                relative terms (if available) and on this site/application.`,
      policy87: `European Union (or EU)`,
      policy88: ` Unless otherwise specified, all references made within this
                document to the European Union include all current member states
                to the European Union and the European Economic Area.`,
      policy89: `Cookie`,
      policy90: `Cookies are Trackers consisting of small sets of data stored in
                the User's browser.`,
      policy91: `Tracker`,
      policy92: `  Tracker indicates any technology - e.g Cookies, unique
                identifiers, web beacons, embedded scripts, e-tags and
                fingerprinting - that enables the tracking of Users, for example
                by accessing or storing information on the User’s device.`,
      policy93: `Legal information`,
      policy94: `This privacy policy relates solely to this Application, if not
                stated otherwise within this document.`,
      policy95: `Latest update: September 18,
                2024`,
    },
    terms_and_conditions: {
      tac: `Terms and Conditions of ebbi`,
      tac1: `These Terms govern`,
      tac2: `the use of this Application, and,`,
      tac3: `any other related Agreement or legal relationship with the Owner`,
      tac4: `in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.`,
      tac5: `The User must read this document carefully.`,
      tac6: `Although the entire contractual relationship relating to these
              Products is entered into solely by the Owner and Users, Users
              acknowledge and agree that, where this Application has been
              provided to them via the Apple App Store, Apple may enforce these
              Terms as a third-party beneficiary.`,
      tac7: `This Application is provided by:`,
      tac8: `Studio M, Unipessoal Lda.`,
      tac9: `David Marx Frh von Mueller`,
      tac10: `Stio Pelourinho`,
      tac11: `Beja`,
      tac12: `Portugal`,
      tac13: `Owner contact email:`,
      tac14: `hello@ebbiapp.com`,
      tac15: `What the User should know at a glance`,
      tac16: `The Service/this Application is only intended for Consumers.`,
      tac17: `This Application automatically extends Product subscriptions.
                  Information about the a) extension period, b) termination
                  details and c) termination notice can be found in the relevant
                  section of these Terms.`,
      tac18: `TERMS OF USE`,
      tac19: `Unless otherwise specified, the terms of use detailed in this
                section apply generally when using this Application.`,
      tac20: ` Single or additional conditions of use or access may apply in
                specific scenarios and in such cases are additionally indicated
                within this document.`,
      tac21: `By using this Application, Users confirm to meet the following
                requirements:`,
      tac22: `Users must qualify as
                  Consumers;`,
      tac23: `Users aren’t located in a country that is subject to a U.S.
                  government embargo, or that has been designated by the U.S.
                  government as a “terrorist-supporting” country;`,
      tac24: `Users aren’t listed on any U.S. government list of prohibited
                  or restricted parties;`,
      tac25: `Conditions for account registration`,
      tac26: `Registration of User accounts on this Application is subject to
                the conditions outlined below. By registering, Users agree to
                meet such conditions.`,
      tac27: ` Accounts registered by bots or any other automated methods are
                  not permitted.`,
      tac28: ` Unless otherwise specified, each User must register only one
                  account.`,
      tac29: ` Unless explicitly permitted, a User account may not be shared
                  with other persons.`,
      tac30: `Content on this Application`,
      tac31: `Unless where otherwise specified or clearly recognizable, all
                content available on this Application is owned or provided by
                the Owner or its licensors.`,
      tac32: `The Owner undertakes its utmost effort to ensure that the
                content provided on this Application infringes no applicable
                legal provisions or third-party rights. However, it may not
                always be possible to achieve such a result. In such cases,
                without prejudice to any legal prerogatives of Users to enforce
                their rights, Users are kindly asked to preferably report
                related complaints using the contact details provided in this
                document.`,
      tac33: `Rights regarding content on this Application - All rights reserved`,
      tac34: `The Owner holds and reserves all intellectual property rights
                for any such content.`,
      tac35: `Users may not, therefore, use such content in any way that is
                not necessary or implicit in the proper use of the Service.`,
      tac36: ` In particular, but without limitation, Users may not copy,
                download, share (beyond the limits set forth below), modify,
                translate, transform, publish, transmit, sell, sublicense, edit,
                transfer/assign to third parties or create derivative works from
                the content available on this Application, nor allow any third
                party to do so through the User or their device, even without
                the User's knowledge.`,
      tac37: ` Where explicitly stated on this Application, the User may
                download, copy and/or share some content available through this
                Application for its sole personal and non-commercial use and
                provided that the copyright attributions and all the other
                attributions requested by the Owner are correctly implemented.`,
      tac38: `Any applicable statutory limitation or exception to copyright
                shall stay unaffected.`,
      tac39: ` Removal of content from parts of this Application available
                through the App Store`,
      tac40: `If the reported content is deemed objectionable, it will be
                removed within 24 hours and the User who provided the content
                will be barred from using the Service.`,
      tac41: `Access to external resources`,
      tac42: `Through this Application Users may have access to external
                resources provided by third parties. Users acknowledge and
                accept that the Owner has no control over such resources and is
                therefore not responsible for their content and availability.`,
      tac43: ` Conditions applicable to any resources provided by third
                parties, including those applicable to any possible grant of
                rights in content, result from each such third parties’ terms
                and conditions or, in the absence of those, applicable statutory
                law.`,
      tac44: `Acceptable use`,
      tac45: `This Application and the Service may only be used within the
                scope of what they are provided for, under these Terms and
                applicable law.`,
      tac46: `Users are solely responsible for making sure that their use of
                this Application and/or the Service violates no applicable law,
                regulations or third-party rights.`,
      tac47: `Therefore,`,
      tac48: `the Owner reserves the right to take any appropriate measure
                  to protect its legitimate interests including by denying Users
                  access to this Application or the Service, terminating
                  contracts, reporting any misconduct performed through this
                  Application or the Service to the competent authorities – such
                  as judicial or administrative authorities - whenever Users
                  engage or are suspected to engage in any of the following
                  activities:`,
      tac49: `violate laws,
                  regulations and/or these Terms;`,
      tac50: `infringe any third-party
                  rights;`,
      tac51: `considerably impair the
                  Owner’s legitimate interests;`,
      tac52: `offend the Owner or any
                  third party.`,
      tac53: `API usage terms`,
      tac54: `Users may access their data relating to this Application via the
                Application Program Interface (API). Any use of the API,
                including use of the API through a third-party product/service
                that accesses this Application, is bound by these Terms and, in
                addition, by the following specific terms:`,
      tac55: ` the User expressly understands and agrees that the Owner bears
                  no responsibility and shall not be held liable for any damages
                  or losses resulting from the User’s use of the API or their
                  use of any third-party products/services that access data
                  through the API.`,
      tac56: `TERMS AND CONDITIONS OF SALE`,
      tac57: ` Provision of personal data`,
      tac58: `To access or receive some of the Products provided via this
                Application as part of the Service, Users may be required to
                provide their personal data as indicated on this Application.`,
      tac59: `Paid Products`,
      tac60: `Some of the Products provided on this Application, as part of
                the Service, are provided on the basis of payment.`,
      tac61: `The fees, duration and conditions applicable to the purchase of
                such Products are described below and in the dedicated sections
                of this Application.`,
      tac62: `Product description`,
      tac63: `Prices, descriptions or availability of Products are outlined in
                the respective sections of this Application and are subject to
                change without notice.`,
      tac64: ` While Products on this Application are presented with the
                greatest accuracy technically possible, representation on this
                Application through any means (including, as the case may be,
                graphic material, images, colors, sounds) is for reference only
                and implies no warranty as to the characteristics of the
                purchased Product.`,
      tac65: `The characteristics of the chosen Product will be outlined
                during the purchasing process.`,
      tac66: `Purchasing process`,
      tac67: `Any steps taken from choosing a Product to order submission form
                part of the purchasing process.`,
      tac68: `The purchasing process includes these steps:`,
      tac69: ` Users must choose the desired Product and verify their
                  purchase selection.`,
      tac70: ` After having reviewed the information displayed in the
                  purchase selection, Users may place the order by submitting
                  it.`,
      tac71: `Order submission`,
      tac72: `When the User submits an order, the following applies:`,
      tac73: `The submission of an order determines contract conclusion and
                  therefore creates for the User the obligation to pay the
                  price, taxes and possible further fees and expenses, as
                  specified on the order page.`,
      tac74: ` In case the purchased Product requires an action from the
                  User, such as the provision of personal information or data,
                  specifications or special wishes, the order submission creates
                  an obligation for the User to cooperate accordingly.`,
      tac75: `Upon submission of the order, Users will receive a receipt
                  confirming that the order has been received.`,
      tac76: `All notifications related to the described purchasing process
                shall be sent to the email address provided by the User for such
                purposes.`,
      tac77: `Prices`,
      tac78: `Users are informed during the purchasing process and before
                order submission, about any fees, taxes and costs (including, if
                any, delivery costs) that they will be charged.`,
      tac79: `Prices on this Application are displayed:`,
      tac80: `either exclusive or inclusive of any applicable fees, taxes
                  and costs, depending on the section the User is browsing`,
      tac81: `Offers and discounts`,
      tac82: `The Owner may offer discounts or provide special offers for the
                purchase of Products. Any such offer or discount shall always be
                subject to the eligibility criteria and the terms and conditions
                set out in the corresponding section of this Application.`,
      tac83: `Offers and discounts are always granted at the Owner’s sole
                discretion.`,
      tac84: `Repeated or recurring offers or discounts create no claim/title
                or right that Users may enforce in the future.`,
      tac85: `Depending on the case, discounts or offers shall be valid for a
                limited time only or while stocks last. If an offer or discount
                is limited by time, the time indications refer to the time zone
                of the Owner, as indicated in the Owner’s location details in
                this document, unless otherwise specified.`,
      tac86: `Coupons`,
      tac87: `Offers or discounts can be based on Coupons`,
      tac88: `If breach of the conditions applicable to Coupons occurs, the
                Owner can legitimately refuse to fulfill its contractual
                obligations and expressly reserves the right to take appropriate
                legal action to protect its rights and interests.`,
      tac89: `Notwithstanding the provisions below, any additional or
                diverging rules applicable to using the Coupon displayed in the
                corresponding information page or on the Coupon itself shall
                always prevail.`,
      tac90: `Unless otherwise stated, these rules apply to the use of
                Coupons:`,
      tac91: `Each Coupon is only valid when used in the manner and within
                  the timeframe specified on the website and/or the Coupon;`,
      tac92: `A Coupon may only be
                  applied, in its entirety, at the actual time of purchase –
                  partial use is not permitted;`,
      tac93: ` Unless otherwise stated, single-use Coupons may only be used
                  once per purchase and therefore may only be applied a single
                  time even in cases involving installment-based purchases;`,
      tac94: `A Coupon cannot be
                  applied cumulatively;`,
      tac95: `The Coupon must be redeemed exclusively within the time
                  specified in the offer. After this period, the Coupon will
                  automatically expire, precluding any possibility for the User
                  to claim the relevant rights, including cash-out;`,
      tac96: `The User is not entitled to any credit/refund/compensation if
                  there is a difference between the value of the Coupon and the
                  redeemed value;`,
      tac97: `The Coupon is intended solely for non–commercial use. Any
                  reproduction, counterfeiting and commercial trade of the
                  Coupon is strictly forbidden, along with any illegal activity
                  related to the purchase and/or use of the Coupon.`,
      tac98: `Methods of payment`,
      tac99: `Information related to accepted payment methods is made
                available during the purchasing process.`,
      tac100: `Some payment methods may only be available subject to additional
                conditions or fees. In such cases related information can be
                found in the dedicated section of this Application.`,
      tac101: `All payments are independently processed through third-party
                services. Therefore, this Application does not collect any
                payment information – such as credit card details – but only
                receives a notification once the payment has been successfully
                completed. The User may read the privacy policy of this
                Application to learn more about the data processing and Users’
                rights regarding their data.`,
      tac102: `If a payment through the available methods fails or is refused
                by the payment service provider, the Owner shall be under no
                obligation to fulfill the purchase order. If a payment fails or
                is refused, the Owner reserves the right to claim any related
                expenses or damages from the User.`,
      tac103: `Purchase via app stor`,
      tac104: `This Application or specific Products available for sale on this
                Application must be purchased via a third-party app store. To
                access such purchases, Users must follow the instructions
                provided on the relevant online store (such as "Apple App Store"
                or "Google Play"), which may vary depending on the particular
                device in use.`,
      tac105: `Some payment methods may only be available subject to additional
                Unless otherwise specified, purchases done via third-party
                online stores are also subject to such third-parties’ terms and
                conditions, which, in case of any inconsistency or conflict,
                shall always prevail upon these Terms.`,
      tac106: `Users purchasing through such third-party online stores must
                therefore read such terms and conditions of sale carefully and
                accept them`,
      tac107: `Retention of Product ownership`,
      tac108: `Until payment of the total purchase price is received by the
                Owner, any Products ordered shall not become the User’s property`,
      tac109: `Retention of usage rights`,
      tac110: `Users do not acquire any rights to use the purchased Product
                until the total purchase price is received by the Owner.`,
      tac111: `Delivery`,
      tac112: `Deliveries are made to the address indicated by the User and in
                the manner specified in the order summary`,
      tac113: `Upon delivery, Users should verify the content of the delivery
                and report anomalies without undue delay, using the contact
                details provided in this document or as described in the
                delivery note. Users may refuse to accept the parcel if visibly
                damaged.`,
      tac114: `Goods are delivered to the countries or territories specified in
                the relevant section of this Application.`,
      tac115: `  Delivery times are specified on this Application or during the
                purchasing process.`,
      tac116: `Failed delivery`,
      tac117: `The Owner cannot be held responsible for delivery errors due to
                inaccuracies or incompleteness in the execution of the purchase
                order by the User, nor for any damages or delays after handover
                to any carrier arranged by the User and not offered or
                recommended by the Owner.`,
      tac118: `If the goods are not received or collected at the time or within
                the deadline specified, the goods will be returned to the Owner,
                who will contact the User to schedule a second delivery attempt
                or to agree on the future course of action.`,
      tac119: `Unless otherwise agreed, any delivery attempt starting from the
                second shall be at the User’s expense.`,
      tac120: `Performance of services`,
      tac121: `The purchased service shall be performed or made available
                within the timeframe specified on this Application or as
                communicated before the order submission.`,
      tac122: `Contract duration`,
      tac123: `Trial period`,
      tac124: `Users have the option to test this Application or selected
                Products during a limited and non-renewable trial period, at no
                cost. Some features or functions of this Application may not be
                available to Users during the trial period. Further conditions
                applicable to the trial period, including its duration, will be
                specified on this Application.`,
      tac125: `The trial period shall end automatically and shall not convert
                into any paid Product unless the User actively purchases such
                paid Product.`,
      tac126: `Subscriptions`,
      tac127: `Subscriptions allow Users to receive a Product continuously or regularly over time. Details regarding the type of subscription and
termination are outlined below.`,
      tac128: `Fixed-term subscriptions`,
      tac129: `Paid fixed-term subscriptions start on the day the payment is received by the Owner and last for the subscription period chosen by the User or otherwise specified during the purchasing process.`,
      tac130: `Once the subscription period expires, the Product shall no longer be accessible.`,
      tac131: `Subscriptions handled via Apple Account`,
      tac132: `Users may subscribe to a Product using the Apple Account
              associated with their Apple App Store account by using the
              relevant process on this Application. When doing so, Users
              acknowledge and accept that`,
      tac133: `any payment due shall be charged to their Apple Account;`,
      tac134: `subscriptions are automatically renewed for the same duration
                unless the User cancels at least 24 hours before the current
                period expires;`,
      tac135: `any and all fees or payments due for renewal will be charged
                within 24-hours before the end of the current period;`,
      tac136: `subscriptions can be managed or cancelled in the Users’Apple App
                Store account settings.`,
      tac137: ` The above shall prevail upon any conflicting or diverging
              provision of these Terms.`,
      tac138: `Termination`,
      tac139: ` Subscriptions may be terminated by sending a clear and unambiguous
            termination notice to the Owner using the contact details provided
            in this document, or — if applicable — by using the corresponding
            controls inside this Application.`,
      tac140: `Automatic extension of fixed-term subscriptions`,
      tac141: ` At the end of the initial term, subscriptions are automatically
            extended for an unlimited period, unless the User terminates before
            the end of such term.`,
      tac142: ` The fee due upon extension will be charged on the payment method
            that the User chose during purchase.`,
      tac143: `After extension, the subscription will last for an indefinite period
            and may be terminated monthly.`,
      tac144: `The User shall receive a reminder of the upcoming unlimited
            extension with reasonable advance, outlining the procedure to be
            followed in order to prevent the extension or terminate the
            subscription thereafter`,
      tac145: `Termination`,
      tac146: `Extended subscriptions may be terminated at any time by sending a
            clear and unambiguous termination notice to the Owner using the
            contact details provided in this document, or — if applicable — by
            using the corresponding controls inside this Application.`,
      tac147: ` If the notice of termination is received by the Owner by the end of
            the current month, the subscription shall expire at the end of such
            month.`,
      tac148: `User rights`,
      tac149: `Right of withdrawal`,
      tac150: ` Unless exceptions apply, the User may be eligible to withdraw from
              the contract within the period specified below (generally 14
              days), for any reason and without justification. Users can learn
              more about the withdrawal conditions within this section.`,
      tac151: `The right of withdrawal does not apply on this Application`,
      tac152: ` Users acknowledge and accept that the right of withdrawal does not
              apply to contracts concluded over this Application due to the
              nature of its offering.`,
      tac153: `UK User rights`,
      tac154: `Right to cancel`,
      tac155: ` Unless exceptions apply, Users who are Consumers in the United
              Kingdom have a legal right of cancellation under UK law and may be
              eligible to withdraw from contracts made online (distance
              contracts) within the period specified below (generally 14 days),
              for any reason and without justification.`,
      tac156: `Users that do not qualify as Consumers, cannot benefit from the
              rights described in this section. Users can learn more about the
              cancellation conditions within this section.`,
      tac157: ` Exercising the right to cancel`,
      tac158: `To exercise their right to cancel, Users must send to the Owner an
              unequivocal statement of their intention to withdraw from the
              contract. To this end, Users may use the model withdrawal form
              available from within the “definitions” section of this document.
              Users are, however, free to express their intention to withdraw
              from the contract by making an unequivocal statement in any other
              suitable way. In order to meet the deadline within which they can
              exercise such right, Users must send the withdrawal notice before
              the cancellation period expires.`,
      tac159: `When does the cancellation period expire?`,
      tac160: `In case of purchase of a digital content not supplied in a
                  tangible medium,`,
      tac161: `the cancellation period expires 14 days after the day that the
                contract is entered into, unless the User has waived the right
                to cancel`,
      tac162: ` Effects of cancellation`,
      tac163: `Users who correctly withdraw from a contract will be reimbursed by
              the Owner for all payments made to the Owner, including, if any,
              those covering the costs of delivery`,
      tac164: ` However, any additional costs resulting from the choice of a
              particular delivery method other than the least expensive type of
              standard delivery offered by the Owner, will not be reimbursed.`,
      tac165: `Such reimbursement shall be made without undue delay and, in any
              event, no later than 14 days from the day on which the Owner is
              informed of the User’s decision to withdraw from the contract.
              Unless otherwise agreed with the User, reimbursements will be made
              using the same means of payment as used to process the initial
              transaction. In any event, the User shall not incur any costs or
              fees as a result of such reimbursement.`,
      tac166: `Exceptions from the right to cancel`,
      tac167: `The right to cancel does not apply to contracts:`,
      tac168: `for the supply of digital content which is not supplied on a
                tangible medium if the performance has begun with the consumer’s
                prior express consent and with their acknowledgment that their
                right to cancel is thereby lost;`,
      tac169: `Brazilian User rights`,
      tac170: `Right of regret`,
      tac171: `Unless an applicable exception is stated below, Users who are
                Consumers in Brazil have a legal right of regret under Brazilian
                law. This means that the Consumer has the right to withdraw from
                contracts made online (distance contracts or any contract signed
                away from business premises) within seven (7) days of the date
                the contract was entered into or the receipt of the product or
                service, for any reason and without justification. Users that do
                not qualify as Consumers, cannot benefit from the rights
                described in this section. The right of regret may be exercised
                by the Consumer via contact channels listed at the beginning of
                this document and in accordance with the guidelines in this
                section.`,
      tac172: `The right of regret does not apply on this Application`,
      tac173: `  Users acknowledge and accept that the right of regret does not
                apply to contracts concluded over this Application due to the
                nature of its offering.`,
      tac174: `Guarantees`,
      tac175: `Legal guarantee of conformity for goods under EU law`,
      tac176: `Under EU law, for a minimum period of 2 years from delivery,
                traders guarantee the conformity of the goods they sell to
                Consumers.`,
      tac177: ` Where Users qualify as European Consumers, the legal guarantee
                of conformity for goods applies to the items available on this
                Application in accordance with the laws of the country of their
                habitual residence.`,
      tac178: `National laws of such country may grant Users broader rights.`,
      tac179: `Consumers who do not qualify as European may benefit from legal
                guarantee of conformity rights in accordance with the
                legislation of the country of their habitual residence.`,
      tac180: `Legal guarantee of conformity for Digital Products under EU law`,
      tac181: `Where Users qualify as European Consumers, the legal guarantee
                of conformity applies to the Digital Products available on this
                Application in accordance with the laws of the country of their
                habitual residence.`,
      tac182: `National laws of such country may grant Users broader rights.`,
      tac183: `Scope of conformity of Digital Products`,
      tac184: `Some of the Digital Products available on this Application may
                have characteristics deviating from the objective requirements
                of conformity, such as their interoperability, compatibility,
                fitness for purpose, functionality, etc. Further information is
                to be found in the dedicated sections of this Application and
                will be pointed out during the purchasing process.`,
      tac185: `Forfeiture of conformity claims with regard to Digital Products`,
      tac186: `Where the Owner provides one or more updates to the Digital
                Product purchased, the User is required to install and/or
                implement all such updates according to the instructions
                provided by the Owner whenever informed to do so. Failure to
                install or apply any such updates may result in forfeiture of
                conformity claims with respect to the Digital Product.`,
      tac187: `Conformity to contract for Consumers in the United Kingdom`,
      tac188: `Users in the United Kingdom qualifying as Consumers have a right
                to receive goods that are in conformity with the contract.`,
      tac189: `Legal guarantee of conformity for goods for Consumers in Brazil`,
      tac190: `The legal guarantee applicable to goods sold by this Application
                (both physical and digital) complies with the following terms,
                according to the Consumer Protection Code:`,
      tac191: `non-durable goods shall have a thirty-day (30 day) guarantee;
                  and`,
      tac192: `durable goods shall have a ninety-day (90 day) guarantee.`,
      tac193: `The warranty period starts from the date of goods delivery.`,
      tac194: `The warranty is not applicable in cases of misuse, natural
                events or if it has been subjected to any maintenance other than
                that provided by this Application.`,
      tac195: `The warranty may be claimed through the contact channels
                provided by this Application. The Owner shall bear the costs of
                shipping the goods for technical assessment, if necessary.`,
      tac196: `The Owner, at its own discretion, may also offer a contractual
                warranty in addition to the legal warranty. The regulations
                applicable to contractual warranties can be found in the
                specifications provided by this Application. If no such
                information is provided, only the statutory provisions shall
                apply.`,
      tac197: `Legal guarantee of conformity for services for Consumers in
                Brazil`,
      tac198: `The legal guarantee applicable to services sold by this
                Application complies with the following terms, according to the
                Consumer Protection Code:`,
      tac199: `non-durable services shall have a thirty-day (30 day)
                  guarantee; and`,
      tac200: `durable services shall have a ninety-day (90 day) guarantee.`,
      tac201: `The warranty period starts from the end of the performance of
                services.`,
      tac202: `The warranty is not applicable in cases of service misuse,
                natural events or if it has been subjected to any maintenance
                other than that provided by this Application.`,
      tac203: `The warranty may be claimed through the contact channels
                provided by this Application. If applicable, the Owner shall
                bear the costs of shipping any goods for technical assessment.
                The Owner, at its own discretion, may also offer a contractual
                warranty in addition to the legal warranty.`,
      tac204: `The regulations applicable to contractual warranties can be
                found in the specifications provided by this Application. If no
                such information is provided, only the statutory provisions
                shall apply.`,
      tac205: `Liability and indemnification`,
      tac206: `Unless otherwise explicitly stated or agreed with Users, the
                Owner’s liability for damages in connection with the execution
                of the Agreement shall be excluded, limited and/or reduced to
                the maximum extent permitted by applicable law.`,
      tac207: `Indemnification`,
      tac208: `The User agrees to indemnify and hold the Owner and its
                  subsidiaries, affiliates, officers, directors, agents,
                  co-branders, partners and employees harmless from and against
                  any claim or demand — including but not limited to lawyer's
                  fees and costs — made by any third party due to or in relation
                  with any culpable violation of these Terms, third-party rights
                  or statutory provisions connected to the use of the Service by
                  the User or its affiliates, officers, directors, agents,
                  co-branders, partners and employees to the extent allowed by
                  applicable law.`,
      tac209: ` The above also applies to any claims exercised by third
                  parties (including but not limited to the Owner’s clients or
                  customers) against the Owner related to Digital Products
                  provided by the User such as, for instance, conformity claims.`,
      tac210: ` Limitation of liability`,
      tac211: ` Unless otherwise explicitly stated and without prejudice to
                  applicable law, Users shall have no right to claim damages
                  against the Owner (or any natural or legal person acting on
                  its behalf).`,
      tac212: `This does not apply to damages to life, health or physical
                  integrity, damages resulting from the breach of material
                  contractual obligations such as any obligation strictly
                  necessary to achieve the purpose of the contract, and/or
                  damages resulting from intent or gross negligence, as long as
                  this Application has been appropriately and correctly used by
                  the User`,
      tac213: `Unless damages have been caused by way of intent or gross
                  negligence, or they affect life, health or physical integrity,
                  the Owner shall only be liable to the extent of typical and
                  foreseeable damages at the moment the contract was entered
                  into.`,
      tac214: `Australian User`,
      tac215: `Limitation of liability`,
      tac216: ` Nothing in these Terms excludes, restricts or modifies any
                    guarantee, condition, warranty, right or remedy which the
                    User may have under the Competition and Consumer Act 2010
                    (Cth) or any similar state and territory legislation and
                    which cannot be excluded, restricted or modified
                    (non-excludable right). To the fullest extent permitted by
                    law, our liability to the User, including liability for a
                    breach of a non-excludable right and liability which is not
                    otherwise excluded under these Terms, is limited, at the
                    Owner’s sole discretion, to the re-performance of the
                    services or the payment of the cost of having the services
                    supplied again.`,
      tac217: `US Users`,
      tac218: `Disclaimer of Warranties`,
      tac219: `This Application is provided strictly on an “as is” and “as
                    available” basis. Use of the Service is at Users’ own risk.
                    To the maximum extent permitted by applicable law, the Owner
                    expressly disclaims all conditions, representations, and
                    warranties — whether express, implied, statutory or
                    otherwise, including, but not limited to, any implied
                    warranty of merchantability, fitness for a particular
                    purpose, or non-infringement of third-party rights. No
                    advice or information, whether oral or written, obtained by
                    the User from the Owner or through the Service will create
                    any warranty not expressly stated herein.`,
      tac220: `Without limiting the foregoing, the Owner, its subsidiaries,
                    affiliates, licensors, officers, directors, agents,
                    co-branders, partners, suppliers and employees do not
                    warrant that the content is accurate, reliable or correct;
                    that the Service will meet Users’ requirements; that the
                    Service will be available at any particular time or
                    location, uninterrupted or secure; that any defects or
                    errors will be corrected; or that the Service is free of
                    viruses or other harmful components. Any content downloaded
                    or otherwise obtained through the use of the Service is
                    downloaded at Users' own risk and Users shall be solely
                    responsible for any damage to Users’ computer system or
                    mobile device or loss of data that results from such
                    download or Users’ use of the Service.`,
      tac221: `The Owner does not warrant, endorse, guarantee, or assume
                    responsibility for any product or service advertised or
                    offered by a third party through the Service or any
                    hyperlinked website or service, and the Owner shall not be a
                    party to or in any way monitor any transaction between Users
                    and third-party providers of products or services.`,
      tac222: `The Service may become inaccessible or it may not function
                    properly with Users’ web browser, mobile device, and/or
                    operating system. The owner cannot be held liable for any
                    perceived or actual damages arising from Service content,
                    operation, or use of this Service.`,
      tac223: `The Service may become inaccessible or it may not function
                    properly with Users’ web browser, mobile device, and/or
                    operating system. The owner cannot be held liable for any
                    perceived or actual damages arising from Service content,
                    operation, or use of this Service.`,
      tac224: `Federal law, some states, and other jurisdictions, do not
                    allow the exclusion and limitations of certain implied
                    warranties. The above exclusions may not apply to Users.
                    This Agreement gives Users specific legal rights, and Users
                    may also have other rights which vary from state to state.
                    The disclaimers and exclusions under this agreement shall
                    not apply to the extent prohibited by applicable law.`,
      tac225: ` Limitations of liability`,
      tac226: `To the maximum extent permitted by applicable law, in no
                    event shall the Owner, and its subsidiaries, affiliates,
                    officers, directors, agents, co-branders, partners,
                    suppliers and employees be liable for:`,
      tac227: ` any indirect, punitive, incidental, special, consequential
                      or exemplary damages, including without limitation damages
                      for loss of profits, goodwill, use, data or other
                      intangible losses, arising out of or relating to the use
                      of, or inability to use, the Service; and`,
      tac228: ` any damage, loss or injury resulting from hacking,
                      tampering or other unauthorized access or use of the
                      Service or User account or the information contained
                      therein;`,
      tac229: `any errors, mistakes, or inaccuracies of content;`,
      tac230: `personal injury or property damage, of any nature
                      whatsoever, resulting from User access to or use of the
                      Service;`,
      tac231: `any unauthorized access to or use of the Owner’s secure
                      servers and/or any and all personal information stored
                      therein;`,
      tac232: `any interruption or cessation of transmission to or from
                      the Service;`,
      tac233: ` any bugs, viruses, trojan horses, or the like that may be
                      transmitted to or through the Service;`,
      tac234: ` any errors or omissions in any content or for any loss or
                      damage incurred as a result of the use of any content
                      posted, emailed, transmitted, or otherwise made available
                      through the Service; and/or`,
      tac235: `the defamatory, offensive, or illegal conduct of any User
                      or third party. In no event shall the Owner, and its
                      subsidiaries, affiliates, officers, directors, agents,
                      co-branders, partners, suppliers and employees be liable
                      for any claims, proceedings, liabilities, obligations,
                      damages, losses or costs in an amount exceeding the amount
                      paid by User to the Owner hereunder in the preceding 12
                      months, or the period of duration of this agreement
                      between the Owner and User, whichever is shorter.`,
      tac236: `This limitation of liability section shall apply to the
                    fullest extent permitted by law in the applicable
                    jurisdiction whether the alleged liability is based on
                    contract, tort, negligence, strict liability, or any other
                    basis, even if the User has been advised of the possibility
                    of such damage.`,
      tac237: ` Some jurisdictions do not allow the exclusion or limitation
                    of incidental or consequential damages, therefore the above
                    limitations or exclusions may not apply to the User. The
                    terms give User specific legal rights, and User may also
                    have other rights which vary from jurisdiction to
                    jurisdiction. The disclaimers, exclusions, and limitations
                    of liability under the terms shall not apply to the extent
                    prohibited by applicable law.`,
      tac238: ` Indemnification`,
      tac239: `The User agrees to defend, indemnify and hold the Owner and
                    its subsidiaries, affiliates, officers, directors, agents,
                    cobranders, partners, suppliers and employees harmless from
                    and against any and all claims or demands, damages,
                    obligations, losses, liabilities, costs or debt, and
                    expenses, including, but not limited to, legal fees and
                    expenses, arising from`,
      tac240: `User’s use of and access to the Service, including any
                      data or content transmitted or received by User;`,
      tac241: ` User’s violation of these terms, including, but not
                      limited to, User’s breach of any of the representations
                      and warranties set forth in these terms;`,
      tac242: `User’s violation of any third-party rights, including, but
                      not limited to, any right of privacy or intellectual
                      property rights;`,
      tac243: `User’s violation of any statutory law, rule, or
                      regulation;`,
      tac244: `any content that is submitted from User’s account,
                      including third party access with User’s unique username,
                      password or other security measure, if applicable,
                      including, but not limited to, misleading, false, or
                      inaccurate information;`,
      tac245: `User’s wilful misconduct; or`,
      tac246: `statutory provision by User or its affiliates, officers,
                      directors, agents, co-branders, partners, suppliers and
                      employees to the extent allowed by applicable law.`,
      tac247: `Common provisions`,
      tac248: `No Waiver`,
      tac249: `The Owner’s failure to assert any right or provision under
                    these Terms shall not constitute a waiver of any such right
                    or provision. No waiver shall be considered a further or
                    continuing waiver of such term or any other term.`,
      tac250: `Service interruption`,
      tac251: `To ensure the best possible service level, the Owner
                    reserves the right to interrupt the Service for maintenance,
                    system updates or any other changes, informing the Users
                    appropriately.`,
      tac252: `Within the limits of law, the Owner may also decide to
                    suspend or discontinue the Service altogether. If the
                    Service is discontinued, the Owner will cooperate with Users
                    to enable them to withdraw personal data or information and
                    will respect Users' rights relating to continued product use
                    and/or compensation, as provided for by applicable law.`,
      tac253: `Additionally, the Service might not be available due to
                    reasons outside the Owner’s reasonable control, such as
                    “force majeure” events (infrastructural breakdowns or
                    blackouts etc.).`,
      tac254: `Privacy policy`,
      tac255: ` To learn more about the use of their personal data, Users
                    may refer to the privacy policy of this Application.`,
      tac256: `Intellectual property rights`,
      tac257: `Without prejudice to any more specific provision of these
                    Terms, any intellectual property rights, such as copyrights,
                    trademark rights, patent rights and design rights related to
                    this Application are the exclusive property of the Owner or
                    its licensors and are subject to the protection granted by
                    applicable laws or international treaties relating to
                    intellectual property.`,
      tac258: `All trademarks — nominal or figurative — and all other
                    marks, trade names, service marks, word marks,
                    illustrations, images, or logos appearing in connection with
                    this Application are, and remain, the exclusive property of
                    the Owner or its licensors and are subject to the protection
                    granted by applicable laws or international treaties related
                    to intellectual property.`,
      tac259: `Changes to these Terms`,
      tac260: ` The Owner reserves the right to amend or otherwise modify
                    these Terms at any time. In such cases, the Owner will
                    appropriately inform the User of these changes.`,
      tac261: `Such changes will only affect the relationship with the User
                    from the date communicated to Users onwards.`,
      tac262: `The continued use of the Service will signify the User’s
                      acceptance of the revised Terms.`,
      tac263: `If Users do not wish to be bound by the changes, they must
                    stop using the Service and may terminate the Agreement.`,
      tac264: `The applicable previous version will govern the relationship
                    prior to the User's acceptance. The User can obtain any
                    previous version from the Owner.`,
      tac265: `If legally required, the Owner will notify Users in advance
                    of when the modified Terms will take effect.`,
      tac266: `Assignment of contract`,
      tac267: `The Owner reserves the right to transfer, assign, dispose of
                    by novation, or subcontract any or all rights or obligations
                    under these Terms, taking the User’s legitimate interests
                    into account. Provisions regarding changes of these Terms
                    will apply accordingly`,
      tac268: `Users may not assign or transfer their rights or obligations
                    under these Terms in any way, without the written permission
                    of the Owner`,
      tac269: `Contacts`,
      tac270: `All communications relating to the use of this Application
                    must be sent using the contact information stated in this
                    document`,
      tac271: `Severability`,
      tac272: `Should any provision of these Terms be deemed or become
                    invalid or unenforceable under applicable law, the
                    invalidity or unenforceability of such provision shall not
                    affect the validity of the remaining provisions, which shall
                    remain in full force and effect.`,
      tac273: `US Users`,
      tac274: `Any such invalid or unenforceable provision will be
                    interpreted, construed and reformed to the extent reasonably
                    required to render it valid, enforceable and consistent with
                    its original intent. These Terms constitute the entire
                    Agreement between Users and the Owner with respect to the
                    subject matter hereof, and supersede all other
                    communications, including but not limited to all prior
                    agreements, between the parties with respect to such subject
                    matter. These Terms will be enforced to the fullest extent
                    permitted by law.`,
      tac275: `EU Users`,
      tac276: `Should any provision of these Terms be or be deemed void,
                    invalid or unenforceable, the parties shall do their best to
                    find, in an amicable way, an agreement on valid and
                    enforceable provisions thereby substituting the void,
                    invalid or unenforceable parts. In case of failure to do so,
                    the void, invalid or unenforceable provisions shall be
                    replaced by the applicable statutory provisions, if so
                    permitted or stated under the applicable law.`,
      tac277: `Without prejudice to the above, the nullity, invalidity or
                    impossibility to enforce a particular provision of these
                    Terms shall not nullify the entire Agreement, unless the
                    severed provisions are essential to the Agreement, or of
                    such importance that the parties would not have entered into
                    the contract if they had known that the provision would not
                    be valid, or in cases where the remaining provisions would
                    translate into an unacceptable hardship on any of the
                    parties.`,
      tac278: `Governing law`,
      tac279: `These Terms are governed by the law of the place where the
                    Owner is based, as disclosed in the relevant section of this
                    document, without regard to conflict of laws principles.`,
      tac280: `Prevalence of national law`,
      tac281: `However, regardless of the above, if the law of the country
                    that the User is located in provides for higher applicable
                    consumer protection standards, such higher standards shall
                    prevail.`,
      tac282: `Exception for Consumers in Switzerland`,
      tac283: `If the User qualifies as a Consumer in Switzerland, Swiss
                    law will apply.`,
      tac284: `Exception for Consumers in Brazil`,
      tac285: ` If the User qualifies as a Consumer in Brazil and the
                    product and/or service is commercialized in Brazil,
                    Brazilian law will apply`,
      tac286: `Venue of jurisdiction`,
      tac287: `The exclusive competence to decide on any controversy
                    resulting from or connected to these Terms lies with the
                    courts of the place where the Owner is based, as displayed
                    in the relevant section of this document.`,
      tac288: `Exception for Consumers in Europe`,
      tac289: `The above does not apply to any Users that qualify as
                    European Consumers, nor to Consumers based in the United
                    Kingdom, Switzerland, Norway or Iceland.`,
      tac290: `Exception for Consumers in Brazil`,
      tac291: `The above does not apply to Users in Brazil that qualify as
                    Consumers.`,
      tac292: `Accessibility`,
      tac293: `The Owner is committed to making the content accessible to
                  Users with disabilities. If Users have a disability and are
                  unable to access any portion of this Application due to their
                  disability, they should give a notice including a detailed
                  description of the issue encountered. If the issue is readily
                  identifiable and resolvable in accordance with
                  industry-standard information technology tools and techniques,
                  the Owner commits to promptly address it.`,
      tac294: `Dispute resolution`,
      tac295: `Amicable dispute resolution`,
      tac296: `Users may bring any disputes to the Owner who will try to
                    resolve them amicably.`,
      tac297: `While Users' right to take legal action shall always remain
                    unaffected, in the event of any controversy regarding the
                    use of this Application or the Service, Users are kindly
                    asked to contact the Owner at the contact details provided
                    in this document.`,
      tac298: `The User may submit the complaint including a brief
                    description and if applicable, the details of the related
                    order, purchase, or account, to the Owner’s email address
                    specified in this document.`,
      tac299: `The Owner will process the complaint without undue delay and
                    within 2 days of receiving it.`,
      tac300: `Online dispute resolution for Consumers`,
      tac301: `The European Commission has established an online platform
                    for alternative dispute resolutions that facilitates an
                    out-of-court method for solving disputes related to and
                    stemming from online sale and service contracts.`,
      tac302: `As a result, any European Consumer or Consumer based in
                    Norway, Iceland, or Liechtenstein can use such platform for
                    resolving disputes stemming from contracts which have been
                    entered into online. The platform is`,
      tac303: `available at the following link.`,
      tac304: `Germany: Dispute resolution procedure with Consumer
                    conciliation boards`,
      tac305: `The Owner does not participate in alternative dispute
                    resolution procedures for Consumers under the German
                    Verbraucherstreitbeilegungsgesetz.`,
      tac306: `France: Mediation`,
      tac307: `Within one year of submitting a written complaint to the
                    Owner regarding any dispute stemming from these Terms,
                    Consumers have the right to initiate a mediation procedure
                    before`,
      tac308: `any mediation body approved by the French government. The
                      relevant list is available at the following link:`,
      tac309: `https://www.economie.gouv.fr/mediation-conso/mediateurs-references.`,
      tac310: `Definitions and legal references`,
      tac311: `This Application (or this Application)`,
      tac312: `The property that enables the provision of the Service.`,
      tac313: `Agreement`,
      tac314: `Any legally binding or contractual relationship between the
                    Owner and the User, governed by these Terms.`,
      tac315: `Brazilian (or Brazil)`,
      tac316: `Applies where a User, regardless of nationality, is in
                    Brazil.`,
      tac317: `Coupon`,
      tac318: `Any code or voucher, in print or electronic form, which
                    allows the User to purchase the Product at a discounted
                    price.`,
      tac319: `Digital Product`,
      tac320: `Is a Product that consists of:`,
      tac321: `content produced and supplied in digital form; and/or`,
      tac322: `a service that allows for the creation, processing,
                      storing or accessing data in a digital form or the sharing
                      or any other form of interaction with digital data
                      uploaded or created by the User or any other user of this
                      Application.`,
      tac323: `European (or Europe)`,
      tac324: `Applies where a User, regardless of nationality, is in the
                    EU.`,
      tac325: `Owner (or We)`,
      tac326: `Indicates the natural person(s) or legal entity that
                    provides this Application and/or the Service to Users.`,
      tac327: `Product`,
      tac328: `A good or service available through this Application, such
                    as e.g. physical goods, digital files, software, booking
                    services etc., and any other types of products separately
                    defined herein, such as Digital Products.`,
      tac329: `Service`,
      tac330: `The service provided by this Application as described in
                    these Terms and on this Application.`,
      tac331: `Terms`,
      tac332: `All provisions applicable to the use of this Application
                    and/or the Service as described in this document, including
                    any other related documents or agreements, and as updated
                    from time to time.`,
      tac333: `United Kingdom (or UK)`,
      tac334: `Applies where a User, regardless of nationality, is in the
                    United Kingdom.`,
      tac335: `User (or You)`,
      tac336: `Indicates any natural person or legal entity using this
                    Application.`,
      tac337: `Consumer`,
      tac338: `Consumer is any User qualifying as such under applicable law`,
      tac339: `Latest update: September 18, 2024`,
    },
    privacy_policy: {
      prp: `Privacy Policy of ebbi`,
      prp1: `This Application collects some Personal Data from its Users`,
      prp2: `This document can be printed for reference by using the print
            command in the settings of any browser`,
      prp3: `Owner and Data Controller`,
      prp4: `Studio M, Unipessoal Lda.`,
      prp5: `David Marx Frh von Mueller`,
      prp6: `Stio Pelourinho`,
      prp7: `7630-578 Sao teotonio`,
      prp8: `Beja`,
      prp9: `Portugal`,
      prp10: ` Owner contact email:`,
      prp11: `  : hello@ebbiapp.com`,
      prp12: `Types of Data collected`,
      prp13: `Among the types of Personal Data that this Application collects,
                by itself or through third parties, there are: email address;
                Usage Data; Trackers; IP address; various types of Data; User
                ID; language; crash data; diagnostics; product interaction;
                purchase history; country`,
      prp14: `Complete details on each type of Personal Data collected are
                provided in the dedicated sections of this privacy policy or by
                specific explanation texts displayed prior to the Data
                collection. Personal Data may be freely provided by the User,
                or, in case of Usage Data, collected automatically when using
                this Application. Unless specified otherwise, all Data requested
                by this Application is mandatory and failure to provide this
                Data may make it impossible for this Application to provide its
                services. In cases where this Application specifically states
                that some Data is not mandatory, Users are free not to
                communicate this Data without consequences to the availability
                or the functioning of the Service.`,
      prp15: `Users who are uncertain about which Personal Data is mandatory
                are welcome to contact the Owner. Any use of Cookies – or of
                other tracking tools — by this Application or by the owners of
                third-party services used by this Application serves the purpose
                of providing the Service required by the User, in addition to
                any other purposes described in the present document and in the
                Cookie Policy.`,
      prp16: `Users are responsible for any third-party Personal Data
                obtained, published or shared through this Application`,
      prp17: `Mode and place of processing the Data`,
      prp18: `Methods of processing`,
      prp19: `The Owner takes appropriate security measures to prevent
                  unauthorized access, disclosure, modification, or unauthorized
                  destruction of the Data.`,
      prp20: ` The Data processing is carried out using computers and/or IT
                  enabled tools, following organizational procedures and modes
                  strictly related to the purposes indicated. In addition to the
                  Owner, in some cases, the Data may be accessible to certain
                  types of persons in charge, involved with the operation of
                  this Application (administration, sales, marketing, legal,
                  system administration) or external parties (such as
                  third-party technical service providers, mail carriers,
                  hosting providers, IT companies, communications agencies)
                  appointed, if necessary, as Data Processors by the Owner. The
                  updated list of these parties may be requested from the Owner
                  at any time.`,
      prp21: `Place`,
      prp22: `The Data is processed at the Owner's operating offices and in
                  any other places where the parties involved in the processing
                  are located.`,
      prp23: `Depending on the User's location, data transfers may involve
                  transferring the User's Data to a country other than their
                  own. To find out more about the place of processing of such
                  transferred Data, Users can check the section containing
                  details about the processing of Personal Data.`,
      prp24: `Retention time`,
      prp25: `Unless specified otherwise in this document, Personal Data
                  shall be processed and stored for as long as required by the
                  purpose they have been collected for and may be retained for
                  longer due to applicable legal obligation or based on the
                  Users’ consent.`,
      prp26: `The purposes of processing`,
      prp27: `The Data concerning the User is collected to allow the Owner to
                provide its Service, comply with its legal obligations, respond
                to enforcement requests, protect its rights and interests (or
                those of its Users or third parties), detect any malicious or
                fraudulent activity, as well as the following: Contacting the
                User, Displaying content from external platforms, Interaction
                with external social networks and platforms, Collection of
                privacy-related preferences, Data transfer outside the EU, Beta
                Testing, Hosting and backend infrastructure and Platform
                services and hosting.`,
      prp28: `For specific information about the Personal Data used for each
                purpose, the User may refer to the section “Detailed information
                on the processing of Personal Data”.`,
      prp29: `Detailed information on the processing of Personal Data`,
      prp30: `Personal Data is collected for the following purposes and using
                the following services:`,
      prp31: `Beta Testing`,
      prp32: `This type of service makes it possible to manage User access
                  to this Application, or parts of it, for the purpose of
                  testing a certain feature or the entire Application.`,
      prp33: ` The service provider may automatically collect data related to
                  crashes and statistics related to the User's use of this
                  Application in a personally identifiable form.`,
      prp34: ` App Center - Beta Testing (Microsoft Corporation)`,
      prp35: ` App Center - Beta Testing is a beta testing service provided
                  by Microsoft Corporation. This Application may also send push
                  notifications to the User.`,
      prp36: `Personal Data processed: crash data; email address; language;
                  User ID`,
      prp37: `Place of processing: United States –`,
      prp38: `Privacy Policy`,
      prp39: `Collection of privacy-related preferences`,
      prp40: ` This type of service allows this Application to collect and
                  store Users’ preferences related to the collection, use, and
                  processing of their personal information, as requested by the
                  applicable privacy legislation.`,
      prp41: ` iubenda Privacy Controls and Cookie Solution (iubenda srl)`,
      prp42: `The iubenda Privacy Controls and Cookie Solution allows the
                  Owner to collect and store Users’ preferences related to the
                  processing of personal information, and in particular to the
                  use of Cookies and other Trackers on this Application.`,
      prp43: `Personal Data processed: IP address; Trackers.`,
      prp44: `Place of processing: Italy –`,
      prp45: `Privacy Policy`,
      prp46: `Contacting the User`,
      prp47: ` Contact form ()`,
      prp48: `By filling in the contact form with their Data, the User
                  authorizes this Application to use these details to reply to
                  requests for information, quotes or any other kind of request
                  as indicated by the form’s header.`,
      prp49: `Personal Data processed: email address; Usage Data.`,
      prp50: `Mailing list or newsletter ()`,
      prp51: `By registering on the mailing list or for the newsletter, the
                  User’s email address will be added to the contact list of
                  those who may receive email messages containing information of
                  commercial or promotional nature concerning this Application.
                  Your email address might also be added to this list as a
                  result of signing up to this Application or after making a
                  purchase.`,
      prp52: `Personal Data processed: email address; Trackers; Usage Data.`,
      prp53: `Displaying content from external platforms`,
      prp54: `This type of service allows you to view content hosted on
                external platforms directly from the pages of this Application
                and interact with them. Such services are often referred to as
                widgets, which are small elements placed on a website or app.
                They provide specific information or perform a particular
                function and often allow for user interaction. This type of
                service might still collect web traffic data for the pages where
                the service is installed, even when Users do not use it`,
      prp55: `Google Fonts (Google LLC)`,
      prp56: `Google Fonts is a typeface visualization service provided by
                  Google LLC that allows this Application to incorporate content
                  of this kind on its pages.`,
      prp57: `Personal Data processed: Trackers; Usage Data.`,
      prp58: `Place of processing: United States –`,
      prp59: `Privacy Policy.`,
      prp60: `Hosting and backend infrastructure`,
      prp61: `This type of service has the purpose of hosting Data and files
                  that enable this Application to run and be distributed as well
                  as to provide a ready-made infrastructure to run specific
                  features or parts of this Application.`,
      prp62: `PSome services among those listed below, if any, may work
                  through geographically distributed servers, making it
                  difficult to determine the actual location where the Personal
                  Data are stored.`,
      prp63: `Amazon Web Services (AWS) (Amazon Web Services, Inc.)`,
      prp64: ` Amazon Web Services (AWS) is a hosting and backend service
                  provided by Amazon Web Services, Inc.`,
      prp65: `Personal Data processed: various types of Data as specified in
                  the privacy policy of the service.`,
      prp66: `Place of processing: Germany –`,
      prp67: `Privacy Policy.`,
      prp68: `Interaction with external social networks and platforms`,
      prp69: `This type of service allows interaction with social networks
                  or other external platforms directly from the pages of this
                  Application.`,
      prp70: `The interaction and information obtained through this
                  Application are always subject to the User’s privacy settings
                  for each social network.`,
      prp71: `This type of service might still collect traffic data for the
                  pages where the service is installed, even when Users do not
                  use it.`,
      prp72: `It is recommended to log out from the respective services in
                  order to make sure that the processed data on this Application
                  isn’t being connected back to the User’s profile.`,
      prp73: ` Facebook Like button and social widgets (Meta Platforms, Inc.)`,
      prp74: `The Facebook Like button and social widgets are services
                  allowing interaction with the Facebook social network provided
                  by Meta Platforms, Inc.`,
      prp75: `Personal Data processed: Trackers; Usage Data.`,
      prp76: `Place of processing: United States –`,
      prp77: `Privacy Policy.`,
      prp78: `Opt out.`,
      prp79: `Platform services and hosting`,
      prp80: `These services have the purpose of hosting and running key
                  components of this Application, therefore allowing the
                  provision of this Application from within a unified platform.
                  Such platforms provide a wide range of tools to the Owner –
                  e.g. analytics, user registration, commenting, database
                  management, e-commerce, payment processing – that imply the
                  collection and handling of Personal Data.`,
      prp81: `Some of these services work through geographically distributed
                  servers, making it difficult to determine the actual location
                  where the Personal Data are stored.`,
      prp82: `App Store Connect (Apple Inc.)`,
      prp83: `This Application is distributed on Apple's App Store, a
                  platform for the distribution of mobile apps, provided by
                  Apple Inc.`,
      prp84: `App Store Connect enables the Owner to manage this Application
                  on Apple's App Store. Depending on the configuration, App
                  Store Connect provides the Owner with analytics data on user
                  engagement and app discovery, marketing campaigns, sales,
                  in-app`,
      prp85: `purchases, and payments to measure the performance of this
                  Application. App Store Connect only collects such data from
                  Users who have agreed to share them with the Owner. Users may
                  find more information on how to opt out via their device
                  settings`,
      prp86: `here`,
      prp87: `.`,
      prp88: `Personal Data processed: diagnostics; product interaction;
                  purchase history; Usage Data; User ID.`,
      prp89: `Place of processing: United States –`,
      prp90: `Privacy Policy`,
      prp91: `.`,
      prp92: ` Google Play Store (Google Ireland Limited)`,
      prp93: `This Application is distributed on the Google Play Store, a
                  platform for the distribution of mobile apps, provided by
                  Google Ireland Limited.`,
      prp94: `Users may opt-out of this analytics feature directly through
                  their device settings. More information on how to manage
                  analysis settings can be found on`,
      prp95: `this page`,
      prp96: `.`,
      prp97: `Personal Data processed: Usage Data.`,
      prp98: `Place of processing: Ireland –.`,
      prp99: `Privacy Policy`,
      prp100: `.`,
      prp101: `Ionos (IONOS Group SE)`,
      prp102: `Ionos is a platform provided by IONOS Group SE that allows the
                  Owner to build, run, and host this Application.`,
      prp103: `Personal Data processed: country.`,
      prp104: `Place of processing: Germany –`,
      prp105: `Privacy Policy`,
      prp106: `.`,
      prp107: ` Cookie Policy`,
      prp108: `This Application uses Trackers. To learn more, Users may
                  consult the`,
      prp109: `Cookie Policy`,
      prp110: `.`,
      prp111: `Further Information for Users`,
      prp112: `Legal basis of processing`,
      prp113: `Google Fonts is a typeface visualization service provided by
                  Google LLC that allows this Application to incorporate content
                  of this kind on its pages.`,
      prp114: `The Owner may process Personal Data relating to Users if one
                  of the following applies:`,
      prp115: `Users have given their consent for one or more specific
                    purposes.`,
      prp116: ` provision of Data is necessary for the performance of an
                    agreement with the User and/or for any pre-contractual
                    obligations thereof;`,
      prp117: `processing is necessary for compliance with a legal
                    obligation to which the Owner is subject;`,
      prp118: `processing is related to a task that is carried out in the
                    public interest or in the exercise of official authority
                    vested in the Owner;`,
      prp119: `processing is necessary for the purposes of the legitimate
                    interests pursued by the Owner or by a third party.`,
      prp120: `In any case, the Owner will gladly help to clarify the
                  specific legal basis that applies to the processing, and in
                  particular whether the provision of Personal Data is a
                  statutory or contractual requirement, or a requirement
                  necessary to enter into a contract.`,
      prp121: `Further information about retention time`,
      prp122: `Unless specified otherwise in this document, Personal Data
                  shall be processed and stored for as long as required by the
                  purpose they have been collected for and may be retained for
                  longer due to applicable legal obligation or based on the
                  Users’ consent.`,
      prp123: `Therefore:`,
      prp124: `Personal Data collected for purposes related to the
                    performance of a contract between the Owner and the User
                    shall be retained until such contract has been fully
                    performed.`,
      prp125: `Personal Data collected for the purposes of the Owner’s
                    legitimate interests shall be retained as long as needed to
                    fulfill such purposes. Users may find specific information
                    regarding the legitimate interests pursued by the Owner
                    within the relevant sections of this document or by
                    contacting the Owner.`,
      prp126: `The Owner may be allowed to retain Personal Data for a longer
                  period whenever the User has given consent to such processing,
                  as long as such consent is not withdrawn. Furthermore, the
                  Owner may be obliged to retain Personal Data for a longer
                  period whenever required to fulfil a legal obligation or upon
                  order of an authority. Once the retention period expires,
                  Personal Data shall be deleted. Therefore, the right of
                  access, the right to erasure, the right to rectification and
                  the right to data portability cannot be enforced after
                  expiration of the retention period.`,
      prp127: `The rights of Users based on the General Data Protection
                  Regulation (GDPR)`,
      prp128: `Users may exercise certain rights regarding their Data
                  processed by the Owner.`,
      prp129: `In particular, Users have the right to do the following, to
                  the extent permitted by law:`,
      prp130: `Withdraw their consent at any time.`,
      prp131: `Users have the right to withdraw consent where they have
                    previously given their consent to the processing of their
                    Personal Data.`,
      prp132: `Object to processing of their Data.`,
      prp133: `Users have the right to object to the processing of their
                    Data if the processing is carried out on a legal basis other
                    than consent.`,
      prp134: `Access their Data.`,
      prp135: `Users have the right to learn if Data is being processed by
                    the Owner, obtain disclosure regarding certain aspects of
                    the processing and obtain a copy of the Data undergoing
                    processing.`,
      prp136: `Verify and seek rectification.`,
      prp137: ` Users have the right to verify the accuracy of their Data
                    and ask for it to be updated or corrected.`,
      prp138: `Restrict the processing of their Data.`,
      prp139: `Users have the right to restrict the processing of their
                    Data. In this case, the Owner will not process their Data
                    for any purpose other than storing it.`,
      prp140: `Have their Personal Data deleted or otherwise removed.`,
      prp141: `Users have the right to obtain the erasure of their Data
                    from the Owner.`,
      prp142: `Receive their Data and have it transferred to another
                      controller.`,
      prp143: `Users have the right to receive their Data in a structured,
                    commonly used and machine readable format and, if
                    technically feasible, to have it transmitted to another
                    controller without any hindrance.`,
      prp144: `odge a complaint.`,
      prp145: `Users have the right to bring a claim before their competent
                    data protection authority.`,
      prp146: `Users are also entitled to learn about the legal basis for
                  Data transfers abroad including to any international
                  organization governed by public international law or set up by
                  two or more countries, such as the UN, and about the security
                  measures taken by the Owner to safeguard their Data.`,
      prp147: `Details about the right to object to processing`,
      prp148: `Where Personal Data is processed for a public interest, in the
                  exercise of an official authority vested in the Owner or for
                  the purposes of the legitimate interests pursued by the Owner,
                  Users may object to such processing by providing a ground
                  related to their particular situation to justify the
                  objection.`,
      prp149: `Users must know that, however, should their Personal Data be
                  processed for direct marketing purposes, they can object to
                  that processing at any time, free of charge and without
                  providing any justification. Where the User objects to
                  processing for direct marketing purposes, the Personal Data
                  will no longer be processed for such purposes. To learn
                  whether the Owner is processing Personal Data for direct
                  marketing purposes, Users may refer to the relevant sections
                  of this document.`,
      prp150: `How to exercise these rights`,
      prp151: `Any requests to exercise User rights can be directed to the
                  Owner through the contact details provided in this document.
                  Such requests are free of charge and will be answered by the
                  Owner as early as possible and always within one month,
                  providing Users with the information required by law. Any
                  rectification or erasure of Personal Data or restriction of
                  processing will be communicated by the Owner to each
                  recipient, if any, to whom the Personal Data has been
                  disclosed unless this proves impossible or involves
                  disproportionate effort. At the Users’ request, the Owner will
                  inform them about those recipients.`,
      prp152: `Transfer of Personal Data outside of the European Union`,
      prp153: `Transfer of Personal Data outside of the European Union`,
      prp154: `Data transfer to countries that guarantee European standards`,
      prp155: `If this is the condition for Data transfer, the transfer of
                  Personal Data from the EU to third countries is carried out
                  according to an adequacy decision of the European Commission.
                  The European Commission adopts adequacy decisions for specific
                  countries whenever it considers that country to possess and
                  provide Personal Data protection standards comparable to those
                  set forth by EU data protection legislation. Users can find an
                  updated list of all adequacy decisions issued on the European
                  Commission's website.`,
      prp156: `Additional information about Data collection and processing`,
      prp157: `Legal action`,
      prp158: `The User's Personal Data may be used for legal purposes by the
                  Owner in Court or in the stages leading to possible legal
                  action arising from improper use of this Application or the
                  related Services. The User declares to be aware that the Owner
                  may be required to reveal personal data upon request of public
                  authorities.`,
      prp159: `Additional information about User's Personal Data`,
      prp160: `In addition to the information contained in this privacy
                  policy, this Application may provide the User with additional
                  and contextual information concerning particular Services or
                  the collection and processing of Personal Data upon request.`,
      prp161: `System logs and maintenance`,
      prp162: `For operation and maintenance purposes, this Application and
                  any third-party services may collect files that record
                  interaction with this Application (System logs) or use other
                  Personal Data (such as the IP Address) for this purpose.`,
      prp163: `Information not contained in this policy`,
      prp164: `More details concerning the collection or processing of
                  Personal Data may be requested from the Owner at any time.
                  Please see the contact information at the beginning of this
                  document.`,
      prp165: `Changes to this privacy policy`,
      prp166: `The Owner reserves the right to make changes to this privacy
                  policy at any time by notifying its Users on this page and
                  possibly within this Application and/or - as far as
                  technically and legally feasible - sending a notice to Users
                  via any contact information available to the Owner. It is
                  strongly recommended to check this page often, referring to
                  the date of the last modification listed at the bottom.`,
      prp167: `Should the changes affect processing activities performed on
                  the basis of the User’s consent, the Owner shall collect new
                  consent from the User, where required.`,
      prp168: `Definitions and legal references`,
      prp169: `Personal Data (or Data)`,
      prp170: `Any information that directly, indirectly, or in connection
                  with other information — including a personal identification
                  number — allows for the identification or identifiability of a
                  natural person.`,
      prp171: `Usage Data`,
      prp172: `Information collected automatically through this Application
                  (or third-party services employed in this Application), which
                  can include: the IP addresses or domain names of the computers
                  utilized by the Users who use this Application, the URI
                  addresses (Uniform Resource Identifier), the time of the
                  request, the method utilized to submit the request to the
                  server, the size of the file received in response, the
                  numerical code indicating the status of the server's answer
                  (successful outcome, error, etc.), the country of origin, the
                  features of the browser and the operating system utilized by
                  the User, the various time details per visit (e.g., the time
                  spent on each page within the Application) and the details
                  about the path followed within the Application with special
                  reference to the sequence of pages visited, and other
                  parameters about the device operating system and/or the User's
                  IT environment.`,
      prp173: `User`,
      prp174: `The individual using this Application who, unless otherwise
                  specified, coincides with the Data Subject.`,
      prp175: `Data Subject`,
      prp176: `The natural person to whom the Personal Data refers.`,
      prp177: `Data Processor (or Processor)`,
      prp178: `The natural or legal person, public authority, agency or other
                  body which processes Personal Data on behalf of the
                  Controller, as described in this privacy policy.`,
      prp179: ` Data Controller (or Owner)`,
      prp180: `The natural or legal person, public authority, agency or other
                  body which, alone or jointly with others, determines the
                  purposes and means of the processing of Personal Data,
                  including the security measures concerning the operation and
                  use of this Application. The Data Controller, unless otherwise
                  specified, is the Owner of this Application.`,
      prp181: `This Application`,
      prp182: `The means by which the Personal Data of the User is collected
                  and processed.`,
      prp183: `Service`,
      prp184: `The service provided by this Application as described in the
                  relative terms (if available) and on this site/application.`,
      prp185: `European Union (or EU)`,
      prp186: `Unless otherwise specified, all references made within this
                  document to the European Union include all current member
                  states to the European Union and the European Economic Area.`,
      prp187: `Cookie`,
      prp188: `Cookies are Trackers consisting of small sets of data stored
                  in the User's browser.`,
      prp189: `Tracker`,
      prp190: `Tracker indicates any technology - e.g Cookies, unique
                  identifiers, web beacons, embedded scripts, e-tags and
                  fingerprinting - that enables the tracking of Users, for
                  example by accessing or storing information on the User’s
                  device.`,
      prp191: `Legal information`,
      prp192: `This privacy policy relates solely to this Application, if not
                  stated otherwise within this document.`,
      prp193: `This privacy policy relates solely to this Application, if not
                  Latest update: September 18, 2024`,
    },
    vision: {
      visionTitle: "VISION",
      text: "At Studio M, we combine the power of artificial intelligence with human empathy and expertise. Our vision is clear: to democratize the right to mental health care and make it a universal good—available to everyone, anytime, anywhere. ",

      text1:
        "In a world where more than one billion people suffer from mental health disorders, traditional solutions alone are not enough.",

      text2:
        "Behind ebbi lies a deep personal passion for psychological well-being and the belief that every person, regardless of time, place, or financial means, should have access to quality psychological care.",

      ceoName: "D. Marx, founder",

      text3:
        "With ebbi, we break down the barriers that stand between people and the help they need. High costs, long wait times, geographic limitations, or personal incompatibility with a therapist? Those hurdles are a thing of the past! ebbi proves that artificial intelligence can take on the role of an empathetic counselor—always available, always understanding. With every interaction, ebbi becomes smarter, learning and offering deep, personalized support.",

      text4:
        "Our first two AI-powered apps, ebbi and FU, address everything from mental well-being and empowerment to genuine learning assistance. They provide immediate support for anyone in the emotional moments of everyday life. ",
      text5:
        "What sets us apart is the seamless fusion of innovative mobile technology and a deep understanding of human needs.",
      text6:
        "Our first apps are just the beginning of a revolutionary process that will change the lives of billions of people. We stand on the threshold of a groundbreaking era in mental health care—an era in which every person receives the support they deserve, in every emotional moment of their lives. And we're not alone! We are joined by endless possibilities arising from the rapid development of artificial intelligence.",
      text7: "Together for a better future.",
    },
  },
  de: {
    banner: {
      text1: "Erhalte sofortige Unterstützung für die",
      text2: "emotionalen Momente in deinem Alltag.",
      text3: "Jederzeit. Überall.",
      text4:
        "ebbi ist dein persönlicher KI-Agent in einem sicheren und anonymen Therapiechat, unterstützt von Psycholog:innen, Therapeut:innen und Life-Coaches – vollgepackt mit jeder Menge Lern-Audios.",
      text5:
        "ebbi hört zu, versteht und berät rund um die Uhr – sowohl in Einzelsitzungen als auch bei Paar- oder Gruppensitzungen mit Freund:innen, Familie oder Partner:innen.",
      text6: "ebbi ist da, um dich auf deinem Lebensweg zu begleiten.",
      text7:
        "*Abonnementplan: 12 €/Monat, monatlich abgerechnet, jederzeit kündbar",
      text8:
        "**Derzeit verfügbar in Dänisch, Deutsch, Englisch, Französisch, Italienisch, Niederländisch, Spanisch, Finnisch, und Schwedisch.",
    },
    text1: `Die Welt verändert sich.`,
    text2: `So auch die Art der psychologischen <br/> Begleitung.`,
    text3: `Bei ebbi steht Ihr Wohlbefinden im Mittelpunkt. Unsere App bietet KI-gestützte Gespräche in Kombination mit äußerst lehrreichen und motivierenden Audioinhalten zu Themen wie Achtsamkeit oder persönlichem Wachstum. Ob als Life Coach oder Beziehungsmediator, ebbi begleitet Nutzer auf ihrer Reise zu tieferem Selbstverständnis und mentalem Wohlbefinden.`,
    text4:
      "*Abonnementplan: 24 €/Monat, monatlich abgerechnet, jederzeit kündbar",
    text5:
      "**Paartherapie von zwei separaten Geräten aus möglich durch einen einmaligen In-App-Kauf",
    text6:
      "***Derzeit verfügbar in Dänisch, Deutsch, Englisch, Finnisch, Französisch, Niederländisch und Schwedisch.",
    form: {
      formHeader: "SAG HALLO",
      formDescription:
        "Hast du Fragen oder Anregungen? Wir sind für dich da und stets bemüht, schnellstmöglich zu antworten.",
      radioBtnHeader: "Worum geht es?",
      radioText1: "Kundenbewertung",
      radioText2: "Presseanfrage*",
      radioText3: "Zusammenarbeit*",
      radioText4: "Keynote*",
      radioText5: "Sponsoring",
      radioText6: "Anderes",
      nameInput: "Gib deinen Namen ein",
      nameEmail: "Gib deine E-Mail Adresse ein",
      nameMessage: "Gib deine Nachricht ein",
      subText: "*Professionelle E-Mail erforderlich",
      submitBtn: "Absenden",
      nameErrorMsg: "Name ist erforderlich",
      emailErrorMsg: "E-Mail ist erforderlich",
      validEmailErrormsg: "Ungültige E-Mail",
      messageErrorMsg: "Nachricht ist erforderlich",
      reCaptcha: "Bitte vervollständigen Sie das ReCAPTCHA",
      aboutErrorMsg: "Bitte wählen Sie eine Option aus",
    },
    footer: {
      footerDescription1: "Urheberrecht © 2024 ",
      footerDescription1: "STUDIO M ",
      footerDescription2: "Lda. Alle Rechte vorbehalten.",
      footerDescription3: "Allgemeine Geschäftsbedingungen.",
      footerDescription4: "Datenschutzrichtlinie",
      footerDescription5: "Folge uns auf",
      footerDescription6: "und",
      instagramText: "Instagram",
      linkedInText: "LinkedIn",
    },
    terms_and_conditions: {
      tac: `Allgemeine Geschäftsbedingungen von ebbi`,
      tac1: `Diese Bedingungen gelten`,
      tac2: `die Nutzung dieser Anwendung und,`,
      tac3: `jede andere damit verbundene Vereinbarung oder Rechtsbeziehung mit dem Eigentümer`,
      tac4: `in rechtsverbindlicher Weise. Großgeschriebene Wörter werden im entsprechenden Abschnitt dieses Dokuments definiert.`,
      tac5: `Der Benutzer muss dieses Dokument sorgfältig lesen.`,
      tac6: `Obwohl das gesamte Vertragsverhältnis sich auf diese bezieht
              Produkte werden ausschließlich vom Eigentümer und den Benutzern, den Benutzern, abgeschlossen
              erkennen Sie an und stimmen Sie dem zu, wo sich diese Anwendung befindet
              Ihnen über den Apple App Store zur Verfügung gestellte Rechte vorbehalten, kann Apple diese durchsetzen
              Konditionen als Drittbegünstigter.`,
      tac7: `Diese Anwendung wird bereitgestellt von:`,
      tac8: `Studio M, Unipessoal Lda.`,
      tac9: `David Marx Frh von Mueller`,
      tac10: `Stio Pelourinho`,
      tac11: `Beja`,
      tac12: `Portugal`,
      tac13: `Kontakt-E-Mail des Eigentümers:`,
      tac14: `hello@ebbiapp.com`,
      tac15: `Was der Nutzer auf einen Blick wissen solltes`,
      tac16: `Der Dienst/diese Anwendung ist nur für Verbraucher bestimmt.`,
      tac17: `Diese Anwendung verlängert Produktabonnements automatisch.
                  Informationen zur a) Verlängerungsfrist, b) Kündigung
                  Einzelheiten und c) Kündigung finden Sie in der jeweiligen
                  Abschnitt dieser Bedingungen.`,
      tac18: `NUTZUNGSBEDINGUNGEN`,
      tac19: `Sofern nicht anders angegeben, gelten die hier aufgeführten Nutzungsbedingungen
                Abschnitt gelten allgemein für die Verwendung dieser Anwendung.`,
      tac20: ` Es können einzelne oder zusätzliche Nutzungs- oder Zugangsbedingungen gelten
                Es handelt sich um bestimmte Szenarien und in solchen Fällen wird zusätzlich darauf hingewiesen
                innerhalb dieses Dokuments.`,
      tac21: `Durch die Nutzung dieser Anwendung bestätigen Benutzer, Folgendes zu erfüllen
                Anforderungen:`,
      tac22: `Benutzer müssen sich als qualifizieren
                  Verbraucher;`,
      tac23: `Benutzer befinden sich nicht in einem Land, das einem US-amerikanischen Gesetz unterliegt.
                  ein Regierungsembargo verhängt wurde oder von den USA verhängt wurde.
                  Regierung als „Terroristen unterstützendes“ Land;`,
      tac24: `Benutzer sind auf keiner Verbotsliste der US-Regierung aufgeführt
                  oder eingeschränkte Parteien;`,
      tac25: `Bedingungen für die Kontoregistrierung`,
      tac26: `Die Registrierung von Benutzerkonten für diese Anwendung unterliegt der Registrierung
                die unten aufgeführten Bedingungen. Mit der Registrierung erklären sich die Nutzer damit einverstanden
                solche Bedingungen erfüllen.`,
      tac27: ` Von Bots oder anderen automatisierten Methoden registrierte Konten sind
                  nicht erlaubt.`,
      tac28: ` Sofern nicht anders angegeben, darf jeder Benutzer nur einen registrieren
                  Konto.`,
      tac29: ` Sofern nicht ausdrücklich erlaubt, darf ein Benutzerkonto nicht weitergegeben werden
                  mit anderen Personen.`,
      tac30: `Inhalt dieser Anwendung`,
      tac31: `Sofern nicht anders angegeben oder klar erkennbar, alle
                Der in dieser Anwendung verfügbare Inhalt ist Eigentum von oder wird von bereitgestellt
                der Eigentümer oder seine Lizenzgeber.`,
      tac32: `Der Eigentümer unternimmt alle Anstrengungen, um sicherzustellen, dass die
                Der in dieser Anwendung bereitgestellte Inhalt verstößt nicht gegen geltendes Recht
                gesetzliche Bestimmungen oder Rechte Dritter. Möglicherweise ist dies jedoch nicht der Fall
                Es ist immer möglich, ein solches Ergebnis zu erzielen. In solchen Fällen
                unbeschadet etwaiger gesetzlicher Vorrechte der Benutzer zur Durchsetzung
                Benutzer werden gebeten, sich über ihre Rechte zu informieren
                diesbezügliche Beschwerden unter den hier angegebenen Kontaktdaten zu richten
                dokumentieren.`,
      tac33: `Rechte bezüglich des Inhalts dieser Anwendung – Alle Rechte vorbehalten`,
      tac34: `Der Eigentümer besitzt und behält sich alle geistigen Eigentumsrechte vor
                für solche Inhalte.`,
      tac35: `Daher ist es den Nutzern untersagt, solche Inhalte in irgendeiner Weise zu nutzen
                nicht notwendig oder implizit für die ordnungsgemäße Nutzung des Dienstes.`,
      tac36: ` Insbesondere, aber ohne Einschränkung, dürfen Benutzer nicht kopieren,
                herunterladen, teilen (über die unten dargelegten Grenzen hinaus), ändern,
                übersetzen, umwandeln, veröffentlichen, übertragen, verkaufen, unterlizenzieren, bearbeiten,
                an Dritte übertragen/abtreten oder davon abgeleitete Werke erstellen
                der in dieser Anwendung verfügbaren Inhalte noch Dritten gestatten
                Partei, dies über den Benutzer oder sein Gerät zu tun, auch ohne
                das Wissen des Benutzers.`,
      tac37: ` Sofern in dieser Anwendung ausdrücklich angegeben, kann der Benutzer dies tun
                Einige hierdurch verfügbare Inhalte herunterladen, kopieren und/oder teilen
                Anwendung für den ausschließlichen persönlichen und nichtkommerziellen Gebrauch und
                vorausgesetzt, dass die Copyright-Angaben und alle anderen
                Vom Eigentümer angeforderte Zuschreibungen werden korrekt umgesetzt.`,
      tac38: `Alle anwendbaren gesetzlichen Beschränkungen oder Ausnahmen vom Urheberrecht
                bleiben davon unberührt.`,
      tac39: ` Entfernung von Inhalten aus Teilen dieser Anwendung möglich
                über den App Store`,
      tac40: `Wenn der gemeldete Inhalt als anstößig erachtet wird, wird dies der Fall sein
                innerhalb von 24 Stunden entfernt und der Benutzer, der den Inhalt bereitgestellt hat
                wird von der Nutzung des Dienstes ausgeschlossen.`,
      tac41: `Zugriff auf externe Ressourcen`,
      tac42: `Über diese Anwendung haben Benutzer möglicherweise Zugriff auf externe
                von Dritten bereitgestellte Ressourcen. Benutzer erkennen an und
                Akzeptieren Sie, dass der Eigentümer keine Kontrolle über diese Ressourcen hat und dies auch ist
                daher nicht für deren Inhalt und Verfügbarkeit verantwortlich.`,
      tac43: ` Bedingungen, die für alle von Dritten bereitgestellten Ressourcen gelten
                Parteien, einschließlich derjenigen, die für eine mögliche Gewährung von gelten
                Rechte am Inhalt ergeben sich aus den jeweiligen Geschäftsbedingungen Dritter
                und Bedingungen oder, in Ermangelung solcher, anwendbare gesetzliche Bestimmungen
                Gesetz.`,
      tac44: `Akzeptable Verwendung`,
      tac45: `Diese Anwendung und der Dienst dürfen nur innerhalb des verwendet werden
                Umfang dessen, wofür sie gemäß diesen Bedingungen vorgesehen sind und
                geltendes Recht.`,
      tac46: `Die Benutzer sind allein dafür verantwortlich, sicherzustellen, dass ihre Nutzung gewährleistet ist
                diese Anwendung und/oder der Dienst gegen kein geltendes Recht verstößt,
                Vorschriften oder Rechte Dritter.`,
      tac47: `daher,`,
      tac48: `Der Eigentümer behält sich das Recht vor, geeignete Maßnahmen zu ergreifen
                  um seine berechtigten Interessen zu schützen, einschließlich durch die Ablehnung von Benutzern
                  Zugriff auf diese Anwendung oder den Dienst, beendet
                  Verträge und melden dabei etwaiges Fehlverhalten
                  Antragstellung bzw. Leistungserbringung bei den zuständigen Behörden – wie z
                  B. Justiz- oder Verwaltungsbehörden – wann immer Benutzer
                  sich an einer der folgenden Handlungen beteiligen oder im Verdacht stehen, sich daran zu beteiligen
                  Aktivitäten:`,
      tac49: `gegen Gesetze verstoßen,
                  Vorschriften und/oder diese Bedingungen;`,
      tac50: `gegen Dritte verstoßen
                  Rechte;`,
      tac51: `erheblich beeinträchtigen
                  Berechtigte Interessen des Eigentümers;`,
      tac52: `den Eigentümer oder andere beleidigen
                  dritte Seite.`,
      tac53: `API-Nutzungsbedingungen`,
      tac54: `Benutzer können auf ihre Daten im Zusammenhang mit dieser Anwendung über zugreifen
                Anwendungsprogrammschnittstelle (API). Jede Nutzung der API,
                einschließlich der Nutzung der API über ein Produkt/eine Dienstleistung eines Drittanbieters
                der auf diese Anwendung zugreift, ist an diese Bedingungen gebunden und in
                Darüber hinaus durch die folgenden spezifischen Bedingungen:`,
      tac55: `Der Benutzer versteht ausdrücklich und stimmt zu, dass der Eigentümer die Verantwortung trägt
                  Wir übernehmen keinerlei Verantwortung und übernehmen keine Haftung für etwaige Schäden
                  oder Verluste, die sich aus der Nutzung der API durch den Benutzer ergeben
                  Nutzung von Produkten/Diensten Dritter, die auf Daten zugreifen
                  über die API.`,
      tac56: `VERKAUFSBEDINGUNGEN`,
      tac57: `Bereitstellung personenbezogener Daten`,
      tac58: `Um auf einige der hierüber bereitgestellten Produkte zuzugreifen oder diese zu erhalten
                Die Nutzung als Teil des Dienstes kann von Benutzern verlangt werden
                Geben Sie Ihre persönlichen Daten wie in diesem Antrag angegeben an.`,
      tac59: `Bezahlte Produkte`,
      tac60: `Einige der in dieser Anwendung bereitgestellten Produkte sind Teil von
                Die Bereitstellung des Dienstes erfolgt gegen Bezahlung.`,
      tac61: `Die für den Kauf geltenden Gebühren, Dauer und Bedingungen
                Solche Produkte werden unten und in den entsprechenden Abschnitten beschrieben
                dieser Anwendung.`,
      tac62: `Produktbeschreibung`,
      tac63: `Preise, Beschreibungen oder Verfügbarkeit der Produkte sind in aufgeführt
                den jeweiligen Abschnitten dieser Anwendung und unterliegen
                ohne Vorankündigung ändern.`,
      tac64: ` Während Produkte in dieser Anwendung mit dem präsentiert werden
                größtmögliche Genauigkeit technisch möglich, Darstellung hierzu
                Bewerbung mit allen Mitteln (gegebenenfalls auch
                grafisches Material, Bilder, Farben, Töne) dienen nur als Referenz
                und beinhaltet keine Garantie hinsichtlich der Eigenschaften des
                gekauftes Produkt.`,
      tac65: `Die Eigenschaften des ausgewählten Produkts werden erläutert
                während des Kaufvorgangs.`,
      tac66: `Kaufprozess`,
      tac67: `Alle Schritte von der Auswahl eines Produkts bis zum Bestellformular
                Teil des Kaufprozesses.`,
      tac68: `Der Kaufprozess umfasst diese Schritte:`,
      tac69: ` Benutzer müssen das gewünschte Produkt auswählen und überprüfen
                  Kaufauswahl.`,
      tac70: ` Nachdem Sie die in der angezeigten Informationen überprüft haben
                  Kaufauswahl, Benutzer können die Bestellung durch Absenden aufgeben
                  Es.`,
      tac71: `Auftragserteilung`,
      tac72: `Wenn der Nutzer eine Bestellung aufgibt, gilt Folgendes:`,
      tac73: `Die Abgabe einer Bestellung kommt zustande und ist für den Vertragsschluss maßgebend
                  Für den Nutzer entsteht daher eine Zahlungspflicht
                  Preis, Steuern und eventuelle weitere Gebühren und Auslagen, wie z
                  auf der Bestellseite angegeben.`,
      tac74: ` Für den Fall, dass das gekaufte Produkt eine Aktion seitens erfordert
                  Nutzer, etwa die Bereitstellung personenbezogener Informationen oder Daten,
                  Vorgaben oder Sonderwünsche werden bei der Auftragserteilung erstellt
                  eine entsprechende Mitwirkungspflicht des Nutzers.`,
      tac75: `Nach Absenden der Bestellung erhalten die Nutzer eine Quittung
                  Bestätigung, dass die Bestellung eingegangen ist.`,
      tac76: `Alle Benachrichtigungen im Zusammenhang mit dem beschriebenen Kaufvorgang
                werden an die vom Nutzer hierfür angegebene E-Mail-Adresse gesendet
                Zwecke.`,
      tac77: `Preise`,
      tac78: `Benutzer werden während des Kaufvorgangs und davor informiert
                Auftragserteilung, über etwaige Gebühren, Steuern und Kosten (einschließlich ggf
                eventuelle Versandkosten), die ihnen in Rechnung gestellt werden.`,
      tac79: `Die Preise für diese Anwendung werden angezeigt:`,
      tac80: `entweder exklusive oder inklusive aller anfallenden Gebühren und Steuern
                  und Kosten, abhängig von der Rubrik, die der Benutzer durchsucht`,
      tac81: `Angebote und Rabatte`,
      tac82: `Der Eigentümer kann Rabatte oder Sonderangebote dafür anbieten
                Kauf von Produkten. Ein solches Angebot oder ein solcher Rabatt gilt immer
                vorbehaltlich der Zulassungskriterien und der Allgemeinen Geschäftsbedingungen
                im entsprechenden Abschnitt dieses Antrags dargelegt.`,
      tac83: `Angebote und Rabatte werden immer ausschließlich vom Eigentümer gewährt
                Diskretion.`,
      tac84: `Wiederholte oder wiederkehrende Angebote oder Rabatte begründen keinen Anspruch
                oder Recht, das Benutzer in Zukunft durchsetzen können.`,
      tac85: `Je nach Fall gelten Rabatte oder Angebote für a
                Nur für begrenzte Zeit oder solange der Vorrat reicht. Ob Angebot oder Rabatt
                ist zeitlich begrenzt, die Zeitangaben beziehen sich auf die Zeitzone
                des Eigentümers, wie in den Standortangaben des Eigentümers angegeben
                dieses Dokuments, sofern nicht anders angegeben.`,
      tac86: `Gutscheine`,
      tac87: `Angebote oder Rabatte können auf Gutscheinen basieren`,
      tac88: `Bei einem Verstoß gegen die für Gutscheine geltenden Bedingungen gilt Folgendes:
                Der Eigentümer kann die Vertragserfüllung zu Recht verweigern
                Verpflichtungen und behält sich ausdrücklich das Recht vor, angemessene Maßnahmen zu ergreifen
                rechtliche Schritte zum Schutz seiner Rechte und Interessen einzuleiten.`,
      tac89: `Ungeachtet der nachstehenden Bestimmungen sind alle zusätzlichen oder
                Es gelten abweichende Regeln für die Verwendung des in der angezeigten Gutscheins
                entsprechende Informationsseite oder auf dem Coupon selbst
                immer vorherrschen.`,
      tac90: `Sofern nicht anders angegeben, gelten diese Regeln für die Nutzung von
                Gutscheine:`,
      tac91: `Jeder Coupon ist nur gültig, wenn er auf die Art und Weise und innerhalb dieses Rahmens verwendet wird
                  innerhalb des auf der Website und/oder im Coupon angegebenen Zeitrahmens;`,
      tac92: `Ein Gutschein kann nur sein
                  gelten in vollem Umfang zum tatsächlichen Zeitpunkt des Kaufs –
                  eine teilweise Nutzung ist nicht gestattet;`,
      tac93: `Sofern nicht anders angegeben, dürfen Gutscheine nur zum einmaligen Gebrauch verwendet werden
                  einmal pro Kauf und kann daher nur einmal angewendet werden
                  Zeit, auch bei Ratenkäufen;`,
      tac94: `Ein Gutschein kann nicht sein
                  kumulativ angewendet;`,
      tac95: `Der Coupon muss ausschließlich innerhalb der Frist eingelöst werden
                  im Angebot angegeben. Nach Ablauf dieser Frist gilt der Coupon
                  automatisch ablaufen, wodurch jede Möglichkeit für den Benutzer ausgeschlossen ist
                  die entsprechenden Rechte, einschließlich der Auszahlung, geltend zu machen;`,
      tac96: `Der Nutzer hat keinen Anspruch auf Gutschrift/Rückerstattung/Entschädigung, wenn
                  Es besteht ein Unterschied zwischen dem Wert des Coupons und dem
                  eingelöster Wert;`,
      tac97: `Der Gutschein ist ausschließlich für die nichtkommerzielle Nutzung bestimmt. Beliebig
                  Reproduktion, Fälschung und kommerzieller Handel mit
                  Der Coupon ist ebenso streng verboten wie jegliche illegale Aktivität
                  im Zusammenhang mit dem Kauf und/oder der Verwendung des Gutscheins.`,
      tac98: `Zahlungsarten`,
      tac99: `Es werden Informationen zu den akzeptierten Zahlungsmethoden bereitgestellt
                während des Kaufvorgangs verfügbar.`,
      tac100: `Einige Zahlungsmethoden sind möglicherweise nur gegen Aufpreis verfügbar
                Bedingungen oder Gebühren. In solchen Fällen können entsprechende Informationen vorliegen
                finden Sie im entsprechenden Abschnitt dieser Anwendung.`,
      tac101: `Alle Zahlungen werden unabhängig über Dritte abgewickelt
                Dienstleistungen. Daher sammelt diese Anwendung keine Daten
                Zahlungsinformationen – etwa Kreditkartendaten – aber nur
                erhält eine Benachrichtigung, sobald die Zahlung erfolgreich war
                vollendet. Der Benutzer kann die Datenschutzbestimmungen hierzu lesen
                Antrag, um mehr über die Datenverarbeitung und die Benutzerdaten zu erfahren
                Rechte hinsichtlich ihrer Daten.`,
      tac102: `Wenn eine Zahlung über die verfügbaren Methoden fehlschlägt oder abgelehnt wird
                durch den Zahlungsdienstleister ist der Inhaber unter Ziff
                Verpflichtung zur Erfüllung der Bestellung. Wenn eine Zahlung fehlschlägt oder
                verweigert wird, behält sich der Eigentümer das Recht vor, alle damit verbundenen Ansprüche geltend zu machen
                Kosten oder Schadensersatz seitens des Nutzers.`,
      tac103: `Kauf über App Store`,
      tac104: `Diese Anwendung oder bestimmte Produkte, die hier zum Verkauf angeboten werden
                Die Anwendung muss über einen App-Store eines Drittanbieters erworben werden. Zu
                Um auf solche Käufe zugreifen zu können, müssen Benutzer den Anweisungen folgen
                bereitgestellt im jeweiligen Online-Shop (z. B. „Apple App Store“)
                oder „Google Play“), die je nach Anbieter variieren können
                Gerät im Einsatz.`,
      tac105: `Einige Zahlungsmethoden sind möglicherweise nur gegen Aufpreis verfügbar
                Sofern nicht anders angegeben, erfolgen Käufe über Drittanbieter
                Online-Shops unterliegen ebenfalls den Geschäftsbedingungen Dritter
                Bedingungen, die im Falle von Widersprüchen oder Konflikten
                haben stets Vorrang vor diesen Bedingungen.`,
      tac106: `Benutzer, die über solche Online-Shops von Drittanbietern einkaufen, müssen dies tun
                Lesen Sie daher diese Verkaufsbedingungen sorgfältig durch und
                akzeptiere sie`,
      tac107: `Eigentumsvorbehalt am Produkt`,
      tac108: `Bis die Zahlung des Gesamtkaufpreises bei uns eingegangen ist
                Eigentümer, bestellte Produkte gehen nicht in das Eigentum des Benutzers über`,
      tac109: `Vorbehalt der Nutzungsrechte`,
      tac110: `Der Nutzer erwirbt keinerlei Rechte zur Nutzung des erworbenen Produkts
                bis der Gesamtkaufpreis beim Eigentümer eingegangen ist.`,
      tac111: `Lieferung`,
      tac112: `Lieferungen erfolgen an die vom Benutzer angegebene Adresse und in
                auf die in der Bestellübersicht angegebene Art und Weise`,
      tac113: `Bei der Lieferung sollten Benutzer den Inhalt der Lieferung überprüfen
                und melden Sie Unregelmäßigkeiten unverzüglich über den Kontakt
                Angaben in diesem Dokument oder wie in der Beschreibung beschrieben
                Lieferschein. Nutzer können die Annahme des Pakets bei sichtbarer Wahrnehmung verweigern
                beschädigt.`,
      tac114: `Die Lieferung der Waren erfolgt in die in angegebenen Länder oder Gebiete
                den entsprechenden Abschnitt dieser Anmeldung.`,
      tac115: `  Die Lieferzeiten sind in diesem Antrag oder während des angegebenen Datums angegeben
                Kaufprozess.`,
      tac116: `Lieferung fehlgeschlagen`,
      tac117: `Der Eigentümer kann nicht für Lieferfehler verantwortlich gemacht werden
                Ungenauigkeiten oder Unvollständigkeiten bei der Ausführung des Kaufs
                Bestellung des Nutzers, noch für etwaige Schäden oder Verzögerungen nach der Übergabe
                an einen vom Nutzer beauftragten und nicht angebotenen Spediteur oder
                vom Eigentümer empfohlen.`,
      tac118: `Wenn die Ware nicht rechtzeitig oder nicht innerhalb der vereinbarten Frist erhalten oder abgeholt wird
                Innerhalb der angegebenen Frist wird die Ware an den Eigentümer zurückgesandt.
                Wer wird den Benutzer kontaktieren, um einen zweiten Zustellversuch zu vereinbaren?
                oder sich auf das weitere Vorgehen zu einigen.`,
      tac119: `Sofern nicht anders vereinbart, erfolgt jeder Zustellversuch ab dem
                Zweiteres geht zu Lasten des Nutzers.`,
      tac120: `Erbringung von Dienstleistungen`,
      tac121: `Die erworbene Leistung ist zu erbringen bzw. zur Verfügung zu stellen
                innerhalb des in diesem Antrag angegebenen Zeitrahmens oder als
                vor der Auftragserteilung mitgeteilt werden.`,
      tac122: `Vertragsdauer`,
      tac123: `Probezeit`,
      tac124: `Benutzer haben die Möglichkeit, diese Anwendung zu testen oder auszuwählen
                Produkte während eines begrenzten und nicht verlängerbaren Testzeitraums unter Nr
                kosten. Einige Features oder Funktionen dieser Anwendung sind möglicherweise nicht verfügbar
                steht Benutzern während des Testzeitraums zur Verfügung. Weitere Konditionen
                für die Probezeit, einschließlich deren Dauer, gelten
                in diesem Antrag angegeben.`,
      tac125: `Die Probezeit endet automatisch und kann nicht umgewandelt werden
                in ein kostenpflichtiges Produkt, es sei denn, der Benutzer kauft ein solches aktiv
                kostenpflichtiges Produkt.`,
      tac126: `Abonnements`,
      tac127: `Abonnements ermöglichen es Benutzern, ein Produkt kontinuierlich oder regelmäßig im Laufe der Zeit zu erhalten. Einzelheiten zur Art des Abonnements und
Kündigung werden im Folgenden erläutert.`,
      tac128: `Abonnements mit fester Laufzeit`,
      tac129: `Bezahlte Abonnements mit fester Laufzeit beginnen an dem Tag, an dem die Zahlung beim Eigentümer eingeht, und gelten für die vom Benutzer gewählte oder anderweitig im Kaufvorgang angegebene Abonnementlaufzeit.`,
      tac130: `Nach Ablauf der Abonnementlaufzeit ist das Produkt nicht mehr zugänglich.`,
      tac131: `Abonnements werden über das Apple-Konto abgewickelt`,
      tac132: `Benutzer können ein Produkt über das Apple-Konto abonnieren
              mit ihrem Apple App Store-Konto verknüpft, indem sie die verwenden
              relevanten Prozess zu diesem Antrag. Dabei handelt es sich um Nutzer
              erkenne und akzeptiere das`,
      tac133: `Alle fälligen Zahlungen werden von ihrem Apple-Konto abgebucht;`,
      tac134: `Abonnements verlängern sich automatisch um die gleiche Dauer
                es sei denn, der Benutzer storniert mindestens 24 Stunden vor dem aktuellen Datum
                Frist läuft ab;`,
      tac135: `Sämtliche für die Verlängerung fälligen Gebühren oder Zahlungen werden in Rechnung gestellt
                innerhalb von 24 Stunden vor dem Ende des aktuellen Zeitraums;`,
      tac136: `Abonnements können in der Apple-App des Benutzers verwaltet oder gekündigt werden
                Kontoeinstellungen speichern.`,
      tac137: ` Das Vorstehende hat Vorrang vor etwaigen widersprüchlichen oder abweichenden Bestimmungen
              Bereitstellung dieser Bedingungen.`,
      tac138: `Beendigung`,
      tac139: `Abonnements können durch eine klare und eindeutige Mitteilung gekündigt werden
            Kündigungsmitteilung an den Vermieter unter den angegebenen Kontaktdaten
            in diesem Dokument oder – falls zutreffend – unter Verwendung der entsprechenden
            Steuerelemente in dieser Anwendung.`,
      tac140: `Automatische Verlängerung befristeter Abonnements`,
      tac141: ` Am Ende der ursprünglichen Laufzeit werden Abonnements automatisch abgeschlossen
            verlängert sich auf unbestimmte Zeit, es sei denn, der Nutzer kündigt vorher
            das Ende dieser Amtszeit.`,
      tac142: ` Die bei der Verlängerung fällige Gebühr wird über die Zahlungsart abgebucht
            die der Benutzer beim Kauf ausgewählt hat.`,
      tac143: `Nach der Verlängerung läuft das Abonnement auf unbestimmte Zeit
            und kann monatlich gekündigt werden.`,
      tac144: `Der Nutzer erhält eine Erinnerung an das bevorstehende Unlimited
            Fristverlängerung mit angemessenem Vorlauf unter Angabe des durchzuführenden Verfahrens
            befolgt, um die Verlängerung zu verhindern bzw. zu beenden
            Abonnement danach`,
      tac145: `Beendigung`,
      tac146: `Verlängerte Abonnements können jederzeit per E-Mail gekündigt werden
            klare und eindeutige Kündigungsmitteilung an den Eigentümer unter Verwendung der
            Die in diesem Dokument angegebenen Kontaktdaten oder – falls zutreffend – per
            Verwendung der entsprechenden Steuerelemente in dieser Anwendung.`,
      tac147: ` Sofern die Kündigung bis zum Ablauf des Jahres beim Eigentümer eingeht
            des laufenden Monats, das Abonnement erlischt an dessen Ende
            Monat.`,
      tac148: `Benutzerrechte`,
      tac149: `Widerrufsrecht`,
      tac150: ` Sofern keine Ausnahmen vorliegen, kann der Benutzer möglicherweise vom Vertrag zurücktreten
              Innerhalb der unten genannten Frist (in der Regel 14.00 Uhr) kündigen wir den Vertrag
              Tage), aus welchem ​​Grund auch immer und ohne Angabe von Gründen. Benutzer können lernen
              Weitere Informationen zu den Auszahlungsbedingungen finden Sie in diesem Abschnitt.`,
      tac151: `Das Widerrufsrecht gilt nicht für diesen Antrag`,
      tac152: ` Benutzer erkennen an und akzeptieren, dass das Widerrufsrecht nicht besteht
              gelten für Verträge, die über diese Anwendung geschlossen werden
              Art seines Angebots.`,
      tac153: `UK-Benutzerrechte`,
      tac154: `Widerrufsrecht`,
      tac155: ` Sofern keine Ausnahmen gelten, Benutzer, die Verbraucher in den Vereinigten Staaten sind
              Das Königreich hat nach britischem Recht ein gesetzliches Widerrufsrecht und kann dies auch tun
              Widerrufsrecht bei online abgeschlossenen Verträgen (Fernabsatz).
              Verträge) innerhalb der unten genannten Frist (in der Regel 14 Tage) zu kündigen,
              aus irgendeinem Grund und ohne Begründung.`,
      tac156: `Benutzer, die nicht als Verbraucher gelten, können nicht davon profitieren
              Rechte, die in diesem Abschnitt beschrieben sind. Benutzer können mehr darüber erfahren
              Stornobedingungen in diesem Abschnitt.`,
      tac157: ` Ausübung des Widerrufsrechts`,
      tac158: `Um von seinem Widerrufsrecht Gebrauch zu machen, muss der Nutzer eine E-Mail an den Eigentümer senden
              eindeutige Erklärung ihrer Absicht, sich aus dem zurückzuziehen
              Vertrag. Hierzu können Nutzer das Muster-Widerrufsformular verwenden
              verfügbar im Abschnitt „Definitionen“ dieses Dokuments.
              Dem Nutzer steht es jedoch frei, seine Absicht zum Widerruf zu äußern
              vom Vertrag durch eine eindeutige Erklärung in einer anderen Form zurücktreten
              geeigneter Weg. Um die Frist einzuhalten, innerhalb derer sie können
              Um von diesem Recht Gebrauch zu machen, muss der Benutzer zuvor die Widerrufserklärung absenden
              die Widerrufsfrist läuft ab.`,
      tac159: `Wann läuft die Widerrufsfrist ab?`,
      tac160: `Im Falle des Kaufs eines digitalen Inhalts, der nicht in einem bereitgestellt wird
                  greifbares Medium,`,
      tac161: `Die Widerrufsfrist endet 14 Tage nach dem Tag, an dem der
                Der Vertrag kommt zustande, es sei denn, der Nutzer hat auf das Recht verzichtet
                abzubrechen`,
      tac162: ` Auswirkungen einer Stornierung`,
      tac163: `Nutzer, die ordnungsgemäß vom Vertrag zurücktreten, erhalten eine Rückerstattung von
              dem Eigentümer für alle an den Eigentümer geleisteten Zahlungen, einschließlich, falls vorhanden,
              diejenigen, die die Versandkosten decken`,
      tac164: ` Etwaige zusätzliche Kosten, die sich aus der Wahl von a ergeben
              bestimmte Versandart, die nicht die kostengünstigste ist
              Die vom Eigentümer angebotene Standardlieferung wird nicht erstattet.`,
      tac165: `Eine solche Rückerstattung erfolgt unverzüglich und in jedem Fall
              Ereignis, spätestens 14 Tage nach dem Tag, an dem der Eigentümer ist
              über die Entscheidung des Nutzers, vom Vertrag zurückzutreten, informiert.
              Sofern mit dem Nutzer nichts anderes vereinbart wurde, erfolgt eine Rückerstattung
              mit dem gleichen Zahlungsmittel, das auch für die ursprüngliche Abwicklung verwendet wurde
              Transaktion. In jedem Fall entstehen dem Nutzer keinerlei Kosten bzw
              Gebühren aufgrund einer solchen Erstattung.`,
      tac166: `Ausnahmen vom Widerrufsrecht`,
      tac167: `Das Widerrufsrecht besteht nicht bei Verträgen:`,
      tac168: `für die Bereitstellung digitaler Inhalte, die nicht auf einem bereitgestellt werden
                körperliches Medium, wenn die Leistung beim Verbraucher begonnen hat
                vorherige ausdrückliche Zustimmung und mit ihrer Bestätigung, dass ihre
                Das Widerrufsrecht erlischt dadurch;`,
      tac169: `Brasilianische Benutzerrechte`,
      tac170: `Recht auf Bedauern`,
      tac171: `Sofern unten keine anwendbare Ausnahme angegeben ist, sind es Benutzer, die dies tun
                Verbraucher in Brasilien haben nach brasilianischem Recht ein gesetzliches Recht auf Reue
                Gesetz. Dies bedeutet, dass der Verbraucher ein Widerrufsrecht hat
                Verträge, die online geschlossen werden (Fernabsatzverträge oder unterzeichnete Verträge).
                außerhalb der Geschäftsräume) innerhalb von sieben (7) Tagen nach dem Datum
                der Vertragsabschluss oder der Erhalt der Ware bzw
                Dienst, aus welchem ​​Grund auch immer und ohne Begründung. Benutzer, die das tun
                Personen, die nicht als Verbraucher gelten, können die Rechte nicht in Anspruch nehmen
                in diesem Abschnitt beschrieben. Das Recht auf Reue kann ausgeübt werden
                durch den Verbraucher über die zu Beginn aufgeführten Kontaktkanäle
                dieses Dokuments und in Übereinstimmung mit den darin enthaltenen Richtlinien
                Abschnitt.`,
      tac172: `Das Recht auf Reue gilt für diesen Antrag nicht`,
      tac173: ` Benutzer erkennen an und akzeptieren, dass das Recht auf Reue nicht gilt
                gelten für Verträge, die über diese Anwendung geschlossen werden
                Art seines Angebots.`,
      tac174: `Garantien`,
      tac175: `Gesetzliche Konformitätsgarantie für Waren nach EU-Recht`,
      tac176: `Nach EU-Recht gilt für einen Zeitraum von mindestens 2 Jahren ab Lieferung:
                Händler garantieren die Konformität der Waren, die sie verkaufen
                Verbraucher.`,
      tac177: ` Wenn Benutzer als europäische Verbraucher gelten, gilt die gesetzliche Garantie
                Für die hier angebotenen Artikel gilt die Konformitätserklärung für Waren
                Anwendung gemäß den Gesetzen des Landes, in dem sie ansässig sind
                gewöhnlicher Aufenthalt.`,
      tac178: `Die nationalen Gesetze dieses Landes können den Benutzern weitergehende Rechte einräumen.`,
      tac179: `Verbraucher, die nicht als Europäer gelten, können von der rechtlichen Regelung profitieren
                Gewährleistung der Konformitätsrechte gemäß der
                Rechtsvorschriften des Landes, in dem sie ihren gewöhnlichen Aufenthalt haben.`,
      tac180: `Gesetzliche Konformitätsgarantie für digitale Produkte nach EU-Recht`,
      tac181: `Wenn Benutzer als europäische Verbraucher gelten, gilt die gesetzliche Garantie
                Die Konformitätserklärung gilt für die hier verfügbaren digitalen Produkte
                Anwendung gemäß den Gesetzen des Landes, in dem sie ansässig sind
                gewöhnlicher Aufenthalt.`,
      tac182: `Die nationalen Gesetze dieses Landes können den Benutzern weitergehende Rechte einräumen.`,
      tac183: `Konformitätsumfang digitaler Produkte`,
      tac184: `Einige der in dieser Anwendung verfügbaren digitalen Produkte können
                Merkmale aufweisen, die von den objektiven Anforderungen abweichen
                der Konformität, wie etwa ihre Interoperabilität, Kompatibilität,
                Gebrauchstauglichkeit, Funktionalität usw. Weitere Informationen finden Sie unter
                finden Sie in den entsprechenden Abschnitten dieser Anwendung und
                Wir werden im Kaufprozess darauf hingewiesen.`,
      tac185: `Verfall von Konformitätsansprüchen in Bezug auf digitale Produkte`,
      tac186: `Wenn der Eigentümer eine oder mehrere Aktualisierungen des Digitalen bereitstellt
                Produkt gekauft, der Benutzer ist verpflichtet, es zu installieren und/oder
                Führen Sie alle Aktualisierungen gemäß den Anweisungen durch
                vom Eigentümer zur Verfügung gestellt, wann immer er dazu aufgefordert wird. Unterlassen
                Die Installation oder Anwendung solcher Updates kann zum Verlust der Rechte führen
                Konformitätsansprüche in Bezug auf das digitale Produkt.`,
      tac187: `Vertragskonformität für Verbraucher im Vereinigten Königreich`,
      tac188: `Benutzer im Vereinigten Königreich, die als Verbraucher gelten, haben ein Recht darauf
                vertragsgemäße Ware zu erhalten.`,
      tac189: `Gesetzliche Konformitätsgarantie für Waren für Verbraucher in Brasilien`,
      tac190: `Die gesetzliche Garantie gilt für die mit dieser Anwendung verkauften Waren
                (sowohl physisch als auch digital) den folgenden Bedingungen entspricht:
                laut Verbraucherschutzgesetz:`,
      tac191: `Für Verbrauchsgüter gilt eine dreißigtägige (30-tägige) Garantie.
                  Und`,
      tac192: `Für langlebige Güter gilt eine Garantie von neunzig Tagen (90 Tagen)..`,
      tac193: `Die Gewährleistungsfrist beginnt mit dem Datum der Warenlieferung.`,
      tac194: `Die Garantie entfällt bei unsachgemäßer Verwendung, selbstverständlich
                Ereignisse oder wenn es einer anderen Wartung unterzogen wurde als
                das in dieser Anwendung bereitgestellte.`,
      tac195: `Die Gewährleistung kann über die Kontaktkanäle in Anspruch genommen werden
                die von dieser Anwendung bereitgestellt werden. Der Eigentümer trägt die Kosten hierfür
                ggf. Versand der Ware zur technischen Begutachtung.`,
      tac196: `Der Eigentümer kann nach eigenem Ermessen auch einen Vertrag anbieten
                Gewährleistung zusätzlich zur gesetzlichen Gewährleistung. Die Vorschriften
                Die für vertragliche Gewährleistungen geltenden Bestimmungen finden Sie in der
                Spezifikationen, die in dieser Anwendung bereitgestellt werden. Wenn nicht
                Bei der Auskunftserteilung gelten ausschließlich die gesetzlichen Bestimmungen
                anwenden.`,
      tac197: `Gesetzliche Konformitätsgarantie für Dienstleistungen für Verbraucher in
                Brasilien`,
      tac198: `Die gesetzliche Garantie gilt für die von dieser verkauften Dienstleistungen
                Die Anwendung entspricht den folgenden Bedingungen
                Verbraucherschutzkodex:`,
      tac199: `Für nicht dauerhafte Dienste gilt eine dreißigtägige (30-tägige) Frist.
                  Garantie; Und`,
      tac200: `Für dauerhafte Dienstleistungen gilt eine Garantie von neunzig Tagen (90 Tagen)..`,
      tac201: `Die Gewährleistungsfrist beginnt mit dem Ende der Leistungserbringung
                Dienstleistungen.`,
      tac202: `Die Garantie gilt nicht bei Servicemissbrauch,
                Naturereignisse oder wenn es einer Wartung unterzogen wurde
                andere als die in dieser Anwendung bereitgestellten.`,
      tac203: `Die Gewährleistung kann über die Kontaktkanäle in Anspruch genommen werden
                die von dieser Anwendung bereitgestellt werden. Gegebenenfalls wird der Eigentümer dies tun
                Die Kosten für den Versand etwaiger Waren zur technischen Begutachtung tragen wir.
                Der Eigentümer kann nach eigenem Ermessen auch einen Vertrag anbieten
                Gewährleistung zusätzlich zur gesetzlichen Gewährleistung.`,
      tac204: `Die für vertragliche Gewährleistungen geltenden Regelungen können sein
                finden Sie in den von dieser Anwendung bereitgestellten Spezifikationen. Wenn nein
                Es gelten ausschließlich die gesetzlichen Bestimmungen
                gelten.`,
      tac205: `Haftung und Freistellung`,
      tac206: `Sofern nicht ausdrücklich etwas anderes angegeben oder mit den Benutzern vereinbart wurde, gilt die
                Haftung des Eigentümers für Schäden im Zusammenhang mit der Ausführung
                des Vertrages werden ausgeschlossen, beschränkt und/oder reduziert
                im größtmöglichen gesetzlich zulässigen Umfang.`,
      tac207: `Entschädigung`,
      tac208: `Der Benutzer verpflichtet sich, den Eigentümer und dessen Eigentümer zu entschädigen und zu entschädigen
                  Tochtergesellschaften, verbundene Unternehmen, leitende Angestellte, Direktoren, Vertreter,
                  Co-Brander, Partner und Mitarbeiter schadlos von und gegen
                  jegliche Ansprüche oder Forderungen – einschließlich, aber nicht beschränkt auf Anwaltsansprüche
                  Gebühren und Kosten – die von Dritten aufgrund oder im Zusammenhang damit erhoben werden
                  Bei schuldhafter Verletzung dieser AGB sind Rechte Dritter ausgeschlossen
                  oder gesetzliche Bestimmungen im Zusammenhang mit der Nutzung des Dienstes durch
                  der Benutzer oder seine verbundenen Unternehmen, leitenden Angestellten, Direktoren, Vertreter,
                  Co-Brander, Partner und Mitarbeiter im zulässigen Umfang
                  geltendes Recht.`,
      tac209: ` Dies gilt auch für etwaige Ansprüche Dritter
                  Parteien (einschließlich, aber nicht beschränkt auf die Kunden des Eigentümers oder
                  Kunden) gegen den Eigentümer im Zusammenhang mit digitalen Produkten
                  vom Nutzer bereitgestellten Informationen, wie z. B. Konformitätsansprüche.`,
      tac210: ` Haftungsbeschränkung`,
      tac211: ` Sofern nicht ausdrücklich anders angegeben und unbeschadet
                  Nach geltendem Recht besteht kein Anspruch des Nutzers auf Schadensersatz
                  gegen den Eigentümer (oder jede natürliche oder juristische Person, die handelt).
                  in seinem Namen).`,
      tac212: `Dies gilt nicht bei Schäden des Lebens, der Gesundheit oder des Körpers
                  Integrität, Schäden, die aus der Verletzung von Material resultieren
                  vertragliche Pflichten wie z. B. jegliche Verpflichtung strengstens zu beachten
                  zur Erreichung des Vertragszwecks erforderlich sind und/oder
                  Schäden, die auf Vorsatz oder grober Fahrlässigkeit beruhen, sofern
                  Diese Anwendung wurde ordnungsgemäß und korrekt verwendet
                  der Benutzer`,
      tac213: `Es sei denn, dass der Schaden vorsätzlich oder grob grob verursacht wurde
                  Fahrlässigkeit vorliegt oder sie das Leben, die Gesundheit oder die körperliche Unversehrtheit beeinträchtigen,
                  Der Eigentümer haftet nur im Umfang typischer und vertragstypischer Schäden
                  bei Vertragsabschluss vorhersehbare Schäden
                  hinein.`,
      tac214: `Australischer Benutzer`,
      tac215: `Haftungsbeschränkung`,
      tac216: `Nichts in diesen Bedingungen schließt solche aus, schränkt sie ein oder ändert sie
                    Garantie, Bedingung, Gewährleistung, Recht oder Abhilfe, die die
                    Der Benutzer kann gemäß dem Competition and Consumer Act 2010 berechtigt sein
                    (Cth) oder eine ähnliche staatliche und territoriale Gesetzgebung und
                    die nicht ausgeschlossen, eingeschränkt oder geändert werden können
                    (nicht ausschließbares Recht). Im größtmöglichen gesetzlich zulässigen Umfang
                    Gesetz, unsere Haftung gegenüber dem Benutzer, einschließlich der Haftung für a
                    Verletzung eines nicht ausschließbaren Rechts und einer nicht ausschließbaren Haftung
                    anderweitig gemäß diesen Bedingungen ausgeschlossen ist, ist begrenzt
                    Es liegt im alleinigen Ermessen des Eigentümers, die erneute Leistung zu erbringen
                    Dienstleistungen oder die Zahlung der Kosten für die Inanspruchnahme der Dienstleistungen
                    wieder versorgt.`,
      tac217: `US-Benutzer`,
      tac218: `Haftungsausschluss`,
      tac219: `Diese Anwendung wird ausschließlich im „Istzustand“-Zustand bereitgestellt
                    verfügbar“-Basis. Die Nutzung des Dienstes erfolgt auf eigenes Risiko des Nutzers.
                    Im größtmöglichen nach geltendem Recht zulässigen Umfang ist der Eigentümer
                    lehnt ausdrücklich alle Bedingungen, Zusicherungen usw. ab
                    Garantien – ob ausdrücklich, stillschweigend, gesetzlich oder
                    andernfalls, einschließlich, aber nicht beschränkt auf stillschweigende
                    Garantie der Marktgängigkeit, Eignung für eine bestimmte Sache
                    Zweck oder Nichtverletzung von Rechten Dritter. NEIN
                    Ratschläge oder Informationen, ob mündlich oder schriftlich, eingeholt von
                    Der Benutzer wird vom Eigentümer oder über den Dienst erstellt
                    jegliche Garantie, die hier nicht ausdrücklich angegeben ist.`,
      tac220: `Ohne das Vorstehende einzuschränken, können der Eigentümer, seine Tochtergesellschaften,
                    verbundene Unternehmen, Lizenzgeber, leitende Angestellte, Direktoren, Vertreter,
                    Co-Brander, Partner, Lieferanten und Mitarbeiter nicht
                    garantieren, dass der Inhalt korrekt, zuverlässig oder korrekt ist;
                    dass der Dienst den Anforderungen der Benutzer entspricht; dass die
                    Der Service ist zu jeder bestimmten Zeit oder verfügbar
                    Standort, ununterbrochen oder sicher; dass etwaige Mängel bzw
                    Fehler werden korrigiert; oder dass der Dienst frei davon ist
                    Viren oder andere schädliche Komponenten. Alle heruntergeladenen Inhalte
                    oder anderweitig durch die Nutzung des Dienstes erlangt werden
                    Das Herunterladen erfolgt auf eigenes Risiko des Benutzers und die alleinige Verantwortung liegt beim Benutzer
                    verantwortlich für Schäden am Computersystem des Benutzers oder
                    Mobilgerät oder daraus resultierender Datenverlust
                    Download oder Nutzung des Dienstes durch Benutzer.`,
      tac221: `Der Eigentümer übernimmt keine Gewährleistung, Befürwortung, Garantie oder Übernahme
                    Verantwortung für beworbene Produkte oder Dienstleistungen bzw
                    von einem Dritten über den Dienst oder einen anderen angeboten werden
                    verlinkten Website oder Dienst, und der Eigentümer ist nicht a
                    Partei einer Transaktion zwischen Benutzern ist oder diese in irgendeiner Weise überwacht
                    und Drittanbieter von Produkten oder Dienstleistungen.`,
      tac222: `Es kann sein, dass der Dienst nicht mehr zugänglich ist oder nicht mehr funktioniert
                    ordnungsgemäß mit dem Webbrowser, dem mobilen Gerät und/oder des Benutzers
                    Betriebssystem. Der Eigentümer kann hierfür nicht haftbar gemacht werden
                    vermeintliche oder tatsächliche Schäden, die sich aus den Inhalten des Dienstes ergeben,
                    Betrieb oder Nutzung dieses Dienstes.`,
      tac223: `Es kann sein, dass der Dienst nicht mehr zugänglich ist oder nicht mehr funktioniert
                    ordnungsgemäß mit dem Webbrowser, dem mobilen Gerät und/oder des Benutzers
                    Betriebssystem. Der Eigentümer kann hierfür nicht haftbar gemacht werden
                    vermeintliche oder tatsächliche Schäden, die sich aus den Inhalten des Dienstes ergeben,
                    Betrieb oder Nutzung dieses Dienstes.`,
      tac224: `Das Bundesrecht, einige Bundesstaaten und andere Gerichtsbarkeiten sehen dies nicht vor
                    erlauben den Ausschluss und die Beschränkungen bestimmter impliziter Elemente
                    Garantien. Die oben genannten Ausschlüsse gelten möglicherweise nicht für Benutzer.
                    Diese Vereinbarung gewährt Benutzern bestimmte gesetzliche Rechte und Benutzer
                    kann auch andere Rechte haben, die von Staat zu Staat unterschiedlich sind.
                    Die Haftungsausschlüsse und Ausschlüsse dieser Vereinbarung gelten
                    gelten nicht in dem Umfang, der nach geltendem Recht verboten ist.`,
      tac225: ` Haftungsbeschränkungen`,
      tac226: `Soweit gesetzlich zulässig, in Nr
                    Veranstaltung werden der Eigentümer und seine Tochtergesellschaften, verbundenen Unternehmen,
                    leitende Angestellte, Direktoren, Agenten, Co-Brander, Partner,
                    Lieferanten und Mitarbeiter haften:`,
      tac227: ` alle indirekten, strafenden, zufälligen, besonderen oder Folgeschäden
                      oder exemplarische Schäden, einschließlich, aber nicht beschränkt auf Schäden
                      für entgangenen Gewinn, Geschäftswert, entgangene Nutzung, entgangene Daten oder sonstiges
                      immaterielle Verluste, die sich aus der Nutzung ergeben oder damit in Zusammenhang stehen
                      des Dienstes oder der Unfähigkeit, ihn zu nutzen; Und`,
      tac228: ` Schäden, Verluste oder Verletzungen, die durch Hackerangriffe entstehen,
                      Manipulation oder sonstiger unbefugter Zugriff oder Nutzung der
                      Dienst- oder Benutzerkonto oder die darin enthaltenen Informationen
                      darin;`,
      tac229: `etwaige Fehler, Irrtümer oder Ungenauigkeiten des Inhalts;`,
      tac230: `Personen- oder Sachschäden jeglicher Art
                      jeglicher Art, die sich aus dem Zugriff oder der Nutzung des Benutzers ergeben
                      Service;`,
      tac231: `jeglicher unbefugter Zugriff auf oder die Nutzung der sicheren Daten des Eigentümers
                      Servern und/oder allen gespeicherten personenbezogenen Daten
                      darin;`,
      tac232: `jede Unterbrechung oder Einstellung der Übermittlung an oder von
                      der Dienst;`,
      tac233: `etwaige Bugs, Viren, Trojaner oder Ähnliches
                      an oder über den Dienst übermittelt werden;`,
      tac234: ` etwaige Fehler oder Auslassungen in den Inhalten oder für etwaige Verluste oder
                      Schäden, die durch die Nutzung jeglicher Inhalte entstehen
                      veröffentlicht, per E-Mail verschickt, übermittelt oder auf andere Weise verfügbar gemacht werden
                      über den Dienst; und/oder`,
      tac235: `das verleumderische, beleidigende oder rechtswidrige Verhalten eines Benutzers
                      oder Dritter. In keinem Fall dürfen der Eigentümer und seine
                      Tochtergesellschaften, verbundene Unternehmen, leitende Angestellte, Direktoren, Vertreter,
                      Co-Brander, Partner, Lieferanten und Mitarbeiter haften
                      für etwaige Ansprüche, Verfahren, Verbindlichkeiten, Verpflichtungen,
                      Schäden, Verluste oder Kosten in darüber hinausgehender Höhe
                      vom Benutzer an den Eigentümer im Rahmen der vorstehenden 12 gezahlt
                      Monate oder die Dauer dieser Vereinbarung
                      zwischen dem Eigentümer und dem Benutzer, je nachdem, welcher Zeitraum kürzer ist.`,
      tac236: `Dieser Abschnitt zur Haftungsbeschränkung gilt für
                    im größtmöglichen gesetzlich zulässigen Umfang
                    Gerichtsstand, auf dem die angebliche Haftung beruht
                    Vertrag, unerlaubte Handlung, Fahrlässigkeit, verschuldensunabhängige Haftung oder sonstiges
                    auch wenn der Nutzer auf die Möglichkeit hingewiesen wurde
                    eines solchen Schadens.`,
      tac237: ` Einige Gerichtsbarkeiten erlauben den Ausschluss oder die Beschränkung nicht
                    von zufälligen oder Folgeschäden, daher das oben Genannte
                    Einschränkungen oder Ausschlüsse gelten möglicherweise nicht für den Benutzer. Der
                    Die Bedingungen gewähren dem Benutzer bestimmte gesetzliche Rechte, und der Benutzer kann dies auch tun
                    haben andere Rechte, die je nach Gerichtsbarkeit unterschiedlich sind
                    Zuständigkeit. Die Haftungsausschlüsse, Ausschlüsse und Beschränkungen
                    Haftungsansprüche nach den AGB gelten insoweit nicht
                    nach geltendem Recht verboten.`,
      tac238: ` Entschädigung`,
      tac239: `Der Benutzer verpflichtet sich, den Eigentümer zu verteidigen, zu entschädigen und zu halten
                    seine Tochtergesellschaften, verbundenen Unternehmen, leitenden Angestellten, Direktoren, Vertreter,
                    Cobrander, Partner, Lieferanten und Mitarbeiter schadlos zu halten
                    und gegen sämtliche Ansprüche oder Forderungen, Schadensersatzansprüche,
                    Verpflichtungen, Verluste, Verbindlichkeiten, Kosten oder Schulden und
                    Kosten, einschließlich, aber nicht beschränkt auf, Anwaltskosten und
                    Kosten, die sich daraus ergeben`,
      tac240: `Nutzung und Zugriff des Benutzers auf den Dienst, einschließlich aller
                      Daten oder Inhalte, die vom Benutzer übermittelt oder empfangen werden;`,
      tac241: `Verstöße des Benutzers gegen diese Bedingungen, einschließlich, aber nicht
                      beschränkt auf den Verstoß des Benutzers gegen eine der Zusicherungen
                      und Garantien, die in diesen Bedingungen dargelegt sind;`,
      tac242: `Verletzung von Rechten Dritter durch den Benutzer, einschließlich, aber
                      nicht beschränkt auf das Recht auf Privatsphäre oder geistiges Eigentum
                      Eigentumsrechte;`,
      tac243: `Verstöße des Benutzers gegen gesetzliche Gesetze, Regeln oder
                      Verordnung;`,
      tac244: `alle Inhalte, die vom Benutzerkonto übermittelt werden,
                      einschließlich Zugriff Dritter mit dem eindeutigen Benutzernamen des Benutzers,
                      Passwort oder ggf. eine andere Sicherheitsmaßnahme,
                      einschließlich, aber nicht beschränkt auf, irreführend, falsch oder
                      ungenaue Informationen;`,
      tac245: `Vorsätzliches Fehlverhalten des Benutzers; oder`,
      tac246: `gesetzliche Bestimmungen des Benutzers oder seiner verbundenen Unternehmen, leitenden Angestellten,
                      Direktoren, Agenten, Co-Brander, Partner, Lieferanten und
                      Mitarbeiter im gesetzlich zulässigen Umfang.`,
      tac247: `Gemeinsame Bestimmungen`,
      tac248: `Kein Verzicht`,
      tac249: `Das Versäumnis des Eigentümers, ein Recht oder eine Bestimmung gemäß geltend zu machen
                    Diese Bedingungen stellen keinen Verzicht auf ein solches Recht dar
                    oder Bereitstellung. Kein Verzicht gilt als weiterer oder weiterer Verzicht
                    fortgesetzter Verzicht auf diese oder eine andere Frist.`,
      tac250: `Dienstunterbrechung`,
      tac251: `Um das bestmögliche Serviceniveau zu gewährleisten, hat der Eigentümer
                    behält sich das Recht vor, den Dienst wegen Wartungsarbeiten zu unterbrechen,
                    Systemaktualisierungen oder andere Änderungen, Benachrichtigung der Benutzer
                    angemessen.`,
      tac252: `Im Rahmen der gesetzlichen Bestimmungen kann auch der Eigentümer dies beschließen
                    den Dienst ganz auszusetzen oder einzustellen. Wenn die
                    Der Dienst wird eingestellt, der Eigentümer wird mit den Benutzern zusammenarbeiten
                    um ihnen den Widerruf personenbezogener Daten oder Informationen zu ermöglichen und
                    respektiert die Rechte der Benutzer in Bezug auf die weitere Nutzung des Produkts
                    und/oder Entschädigung, wie im geltenden Recht vorgesehen.`,
      tac253: `Darüber hinaus ist der Dienst möglicherweise aufgrund von nicht verfügbar
                    Gründe, die außerhalb der angemessenen Kontrolle des Eigentümers liegen, wie z
                    Ereignisse „höherer Gewalt“ (Ausfälle der Infrastruktur bzw
                    Stromausfälle usw.).`,
      tac254: `Datenschutzrichtlinie`,
      tac255: ` Um mehr über die Verwendung ihrer persönlichen Daten zu erfahren, können Benutzer
                    können auf die Datenschutzrichtlinie dieser Anwendung verweisen.`,
      tac256: `Geistige Eigentumsrechte`,
      tac257: `Unbeschadet etwaiger spezifischerer Bestimmungen dieser Bestimmungen
                    Bedingungen, etwaige geistige Eigentumsrechte, wie z. B. Urheberrechte,
                    Markenrechte, Patentrechte und Designrechte im Zusammenhang mit
                    Diese Anwendung ist das ausschließliche Eigentum des Eigentümers bzw
                    seinen Lizenzgebern und unterliegen dem von ihnen gewährten Schutz
                    anwendbare Gesetze oder internationale Verträge in Bezug auf
                    geistiges Eigentum.`,
      tac258: `Alle Marken – nominell oder figurativ – und alle anderen
                    Marken, Handelsnamen, Dienstleistungsmarken, Wortmarken,
                    Illustrationen, Bilder oder Logos, die im Zusammenhang mit erscheinen
                    Diese Anwendung ist und bleibt das ausschließliche Eigentum von
                    Eigentümer oder seine Lizenzgeber und unterliegen dem Schutz
                    durch geltende Gesetze oder damit verbundene internationale Verträge gewährt
                    zum geistigen Eigentum.`,
      tac259: `Änderungen dieser Bedingungen`,
      tac260: ` Der Eigentümer behält sich das Recht vor, Änderungen oder sonstige Änderungen vorzunehmen
                    diese Bedingungen jederzeit widerrufen. In solchen Fällen wird der Eigentümer dies tun
                    den Nutzer angemessen über diese Änderungen informieren.`,
      tac261: `Solche Änderungen wirken sich nur auf die Beziehung zum Benutzer aus
                    ab dem den Benutzern mitgeteilten Datum.`,
      tac262: `Die fortgesetzte Nutzung des Dienstes bedeutet für den Benutzer
                      Annahme der überarbeiteten Bedingungen.`,
      tac263: `Wenn Benutzer nicht an die Änderungen gebunden sein möchten, müssen sie dies tun
                    die Nutzung des Dienstes einstellen und die Vereinbarung kündigen.`,
      tac264: `Maßgeblich für das Verhältnis ist die jeweils gültige Vorgängerversion
                    vor der Zustimmung des Benutzers. Der Benutzer kann jedes erhalten
                    vorherige Version vom Eigentümer.`,
      tac265: `Sofern gesetzlich erforderlich, wird der Eigentümer die Benutzer im Voraus benachrichtigen
                    wann die geänderten Bedingungen in Kraft treten.`,
      tac266: `Vertragsabtretung`,
      tac267: `Der Eigentümer behält sich das Recht zur Übertragung, Abtretung und Veräußerung vor
                    durch Novation oder die Übertragung einzelner oder aller Rechte oder Pflichten an einen Unterauftragnehmer
                    im Rahmen dieser Bedingungen unter Wahrung der berechtigten Interessen des Nutzers
                    berücksichtigen. Bestimmungen zu Änderungen dieser Bedingungen
                    gelten entsprechend`,
      tac268: `Der Nutzer ist nicht berechtigt, seine Rechte oder Pflichten abzutreten oder zu übertragen
                    unter diesen Bedingungen in irgendeiner Weise ohne die schriftliche Genehmigung
                    des Eigentümers`,
      tac269: `Kontakte`,
      tac270: `Alle Mitteilungen im Zusammenhang mit der Nutzung dieser Anwendung
                    ist an die dort genannten Kontaktdaten zu senden
                    dokumentieren`,
      tac271: `Salvatorische Klausel`,
      tac272: `Sollte eine Bestimmung dieser Bedingungen in Kraft treten oder werden
                    nach geltendem Recht ungültig oder nicht durchsetzbar, die
                    die Ungültigkeit oder Undurchsetzbarkeit dieser Bestimmung besteht nicht
                    Die Gültigkeit der übrigen Bestimmungen wird hiervon berührt
                    bleiben in vollem Umfang in Kraft und wirksam.`,
      tac273: `US-Benutzer`,
      tac274: `Jede solche Bestimmung ist ungültig oder nicht durchsetzbar
                    angemessen interpretiert, ausgelegt und umgestaltet werden
                    erforderlich, um es gültig, durchsetzbar und konsistent zu machen
                    seine ursprüngliche Absicht. Diese Bedingungen stellen die Gesamtheit dar
                    Vereinbarung zwischen Benutzern und dem Eigentümer in Bezug auf
                    Gegenstand dieser Vereinbarung und ersetzen alle anderen
                    Kommunikation, einschließlich, aber nicht beschränkt auf alle vorherigen
                    Vereinbarungen zwischen den Parteien in Bezug auf diesen Gegenstand
                    Gegenstand. Diese Bedingungen werden im vollen Umfang durchgesetzt
                    gesetzlich zulässig.`,
      tac275: `EU-Benutzer`,
      tac276: `Sollte eine Bestimmung dieser Bedingungen ungültig sein oder als ungültig erachtet werden,
                    ungültig oder nicht durchsetzbar sind, werden die Parteien ihr Möglichstes tun
                    Finden Sie auf gütliche Weise eine Einigung über gültige und
                    durchsetzbare Bestimmungen, die an die Stelle der Nichtigkeit treten,
                    ungültige oder nicht durchsetzbare Teile. Sollte dies nicht der Fall sein,
                    die nichtigen, ungültigen oder undurchführbaren Bestimmungen bleiben bestehen
                    an ihre Stelle treten die jeweils geltenden gesetzlichen Bestimmungen
                    nach geltendem Recht zulässig oder angegeben.`,
      tac277: `Unbeschadet des Vorstehenden gilt die Nichtigkeit, Ungültigkeit bzw
                    Unmöglichkeit, eine bestimmte Bestimmung dieser Bestimmungen durchzusetzen
                    Bedingungen machen nicht die gesamte Vereinbarung ungültig, es sei denn, die
                    abgetrennte Bestimmungen sind für die Vereinbarung wesentlich, oder von
                    eine solche Bedeutung, auf die die Parteien nicht eingegangen wären
                    vom Vertrag zurücktreten, wenn sie gewusst hätten, dass die Bestimmung dies nicht tun würde
                    gelten, oder in den Fällen, in denen die übrigen Bestimmungen gelten würden
                    Dies führt zu einer inakzeptablen Härte für irgendjemanden
                    Parteien.`,
      tac278: `Geltendes Recht`,
      tac279: `Diese Bedingungen unterliegen dem Recht des Ortes, an dem sie gelten
                    Der Sitz des Eigentümers ist im entsprechenden Abschnitt dieses Dokuments angegeben
                    Dokument, ohne Rücksicht auf Kollisionsnormen.`,
      tac280: `Verbreitung des nationalen Rechts`,
      tac281: `Unabhängig davon gilt jedoch, ob das Recht des Landes gilt
                    in dem sich der Nutzer befindet, gelten höhere Ansprüche
                    Verbraucherschutzstandards müssen solche höheren Standards gelten
                    sich durchsetzen.`,
      tac282: `Ausnahme für Verbraucher in der Schweiz`,
      tac283: `Wenn der Benutzer als Verbraucher in der Schweiz gilt, ist Swiss
                    Es gilt das Gesetz.`,
      tac284: `Ausnahme für Verbraucher in Brasilien`,
      tac285: ` Wenn der Benutzer als Verbraucher in Brasilien gilt und der
                    Produkt und/oder Dienstleistung wird in Brasilien vermarktet,
                    Es gilt brasilianisches Recht`,
      tac286: `Gerichtsstand`,
      tac287: `Die ausschließliche Zuständigkeit, über jede Kontroverse zu entscheiden
                    Alle Rechte, die sich aus diesen Bedingungen ergeben oder mit ihnen verbunden sind, liegen bei der
                    Gerichte des Ortes, an dem der Eigentümer seinen Sitz hat, wie angegeben
                    finden Sie im entsprechenden Abschnitt dieses Dokuments.`,
      tac288: `Ausnahme für Verbraucher in Europa`,
      tac289: `Das oben Genannte gilt nicht für Benutzer, die als solche qualifiziert sind
                    Europäische Verbraucher, noch an Verbraucher mit Sitz in den Vereinigten Staaten
                    Königreich, Schweiz, Norwegen oder Island.`,
      tac290: `Ausnahme für Verbraucher in Brasilien`,
      tac291: `Das oben Genannte gilt nicht für Benutzer in Brasilien, die dafür qualifiziert sind
                    Verbraucher.`,
      tac292: `Zugänglichkeit`,
      tac293: `Der Eigentümer verpflichtet sich, die Inhalte zugänglich zu machen
                  Benutzer mit Behinderungen. Wenn Benutzer eine Behinderung haben und sind
                  Aufgrund Ihrer Rechte ist es Ihnen nicht möglich, auf Teile dieser Anwendung zuzugreifen
                  Bei einer Behinderung sollten sie eine Mitteilung mit einer detaillierten Beschreibung abgeben
                  Beschreibung des aufgetretenen Problems. Wenn das Problem leicht ist
                  identifizierbar und lösbar gemäß
                  branchenübliche Informationstechnologie-Tools und -Techniken,
                  Der Eigentümer verpflichtet sich, sich umgehend darum zu kümmern.`,
      tac294: `Streitbeilegung`,
      tac295: `Einvernehmliche Streitbeilegung`,
      tac296: `Nutzer können etwaige Streitigkeiten dem Eigentümer vorlegen, der es versuchen wird
                    sie einvernehmlich lösen.`,
      tac297: `Das Recht des Nutzers, rechtliche Schritte einzuleiten, bleibt jedoch stets bestehen
                    unberührt, im Falle von Meinungsverschiedenheiten bezüglich der
                    Die Nutzung dieser Anwendung oder des Dienstes wird den Benutzern freundlicherweise gestattet
                    Bitte kontaktieren Sie den Eigentümer unter den angegebenen Kontaktdaten
                    in diesem Dokument.`,
      tac298: `Der Nutzer kann die Beschwerde einschließlich einer Stellungnahme einreichen
                    Beschreibung und ggf. die Einzelheiten der zugehörigen
                    Bestellung, Kauf oder Konto an die E-Mail-Adresse des Eigentümers senden
                    in diesem Dokument angegeben.`,
      tac299: `Der Eigentümer wird die Beschwerde unverzüglich bearbeiten
                    innerhalb von 2 Tagen nach Erhalt.`,
      tac300: `Online-Streitbeilegung für Verbraucher`,
      tac301: `Die Europäische Kommission hat eine Online-Plattform eingerichtet
                    für alternative Streitbeilegung, die eine erleichtert
                    außergerichtliche Methode zur Lösung von Streitigkeiten im Zusammenhang mit und
                    aus Online-Verkaufs- und Serviceverträgen.`,
      tac302: `Folglich kann jeder europäische Verbraucher oder Verbraucher mit Sitz in
                    Norwegen, Island oder Liechtenstein können eine solche Plattform nutzen
                    Beilegung von Streitigkeiten im Zusammenhang mit abgeschlossenen Verträgen
                    online eingegangen. Die Plattform ist`,
      tac303: `unter folgendem Link verfügbar.`,
      tac304: `Deutschland: Streitbeilegungsverfahren mit Verbraucher
                    Schlichtungsstellen`,
      tac305: `Der Eigentümer beteiligt sich nicht an alternativen Streitigkeiten
                    Streitbeilegungsverfahren für Verbraucher nach dem deutschen
                    Verbraucherstreitbeilegungsgesetz.`,
      tac306: `Frankreich: Mediation`,
      tac307: `Innerhalb eines Jahres nach Einreichung einer schriftlichen Beschwerde beim
                    Eigentümer bezüglich aller Streitigkeiten, die sich aus diesen Bedingungen ergeben,
                    Verbraucher haben das Recht, ein Schlichtungsverfahren einzuleiten
                    vor`,
      tac308: `jede von der französischen Regierung zugelassene Schlichtungsstelle. Der
                      Die entsprechende Liste finden Sie unter folgendem Link:`,
      tac309: `https://www.economie.gouv.fr/mediation-conso/mediateurs-references.`,
      tac310: `Definitionen und rechtliche Hinweise`,
      tac311: `Diese Anwendung (oder diese Anwendung)`,
      tac312: `Die Eigenschaft, die die Bereitstellung des Dienstes ermöglicht.`,
      tac313: `Vereinbarung`,
      tac314: `Jede rechtsverbindliche oder vertragliche Beziehung zwischen dem
                    Eigentümer und Benutzer unterliegen diesen Bedingungen.`,
      tac315: `Brasilianer (oder Brasilien)`,
      tac316: `Gilt dort, wo sich ein Benutzer, unabhängig von seiner Nationalität, aufhält
                    Brasilien.`,
      tac317: `Coupon`,
      tac318: `Jeder Code oder Gutschein, in gedruckter oder elektronischer Form, der
                    ermöglicht es dem Benutzer, das Produkt zu einem ermäßigten Preis zu kaufen
                    Preis.`,
      tac319: `Digitales Produkt`,
      tac320: `Ist ein Produkt, das besteht aus:`,
      tac321: `In digitaler Form erstellte und bereitgestellte Inhalte; und/oder`,
      tac322: `ein Dienst, der die Erstellung, Verarbeitung,
                      Speicherung oder Zugriff auf Daten in digitaler Form oder deren Weitergabe
                      oder jede andere Form der Interaktion mit digitalen Daten
                      vom Benutzer oder einem anderen Benutzer hiervon hochgeladen oder erstellt wurden
                      Anwendung.`,
      tac323: `Europäisch (oder Europa)`,
      tac324: `Gilt, wenn sich ein Benutzer, unabhängig von seiner Nationalität, in der befindet
                    EU.`,
      tac325: `Eigentümer (oder wir)`,
      tac326: `Gibt die natürliche(n) oder juristische(n) Person(en) an
                    stellt Benutzern diese Anwendung und/oder den Dienst zur Verfügung.`,
      tac327: `Produkt`,
      tac328: `Eine über diese Anwendung verfügbare Ware oder Dienstleistung, z
                    wie z.B. physische Güter, digitale Dateien, Software, Buchung
                    Dienstleistungen usw. und alle anderen Arten von Produkten separat
                    hierin definiert, wie z. B. digitale Produkte.`,
      tac329: `Service`,
      tac330: `Der von dieser Anwendung bereitgestellte Dienst, wie in beschrieben
                    Diese Bedingungen und diese Anwendung.`,
      tac331: `Bedingungen`,
      tac332: `Alle Bestimmungen gelten für die Nutzung dieser Anwendung
                    und/oder der Dienst, wie in diesem Dokument beschrieben, einschließlich
                    alle anderen zugehörigen Dokumente oder Vereinbarungen und in der jeweils aktuellen Fassung
                    von Zeit zu Zeit.`,
      tac333: `Vereinigtes Königreich (oder UK)`,
      tac334: `Gilt, wenn sich ein Benutzer, unabhängig von seiner Nationalität, in der befindet
                    Vereinigtes Königreich.`,
      tac335: `Benutzer (oder Sie)`,
      tac336: `Bezeichnet jede natürliche oder juristische Person, die dies nutzt
                    Anwendung.`,
      tac337: `Verbraucher`,
      tac338: `Verbraucher ist jeder Nutzer, der nach geltendem Recht als solcher gilt`,
      tac339: `Letzte Aktualisierung: 18. September 2024`,
    },
    cookie_policy: {
      policy: "Cookie-Richtlinie von ebbi",
      policy1: `Dieses Dokument informiert Benutzer über die Technologien, die dabei helfen
                Anwendung zur Erreichung der unten beschriebenen Zwecke. Solch
                Technologien ermöglichen es dem Eigentümer, auf Informationen zuzugreifen und diese zu speichern (z
                B. durch die Verwendung eines Cookies) oder Ressourcen nutzen (z. B. durch
                Ausführen eines Skripts) auf dem Gerät eines Benutzers, während dieser damit interagiert
                Anwendung.`,
      policy2: `Der Einfachheit halber werden alle diese Technologien als „Tracker“ definiert.
                innerhalb dieses Dokuments – es sei denn, es besteht ein Grund zur Differenzierung.
                Beispielsweise können Cookies sowohl im Web als auch auf Mobilgeräten verwendet werden
                Browsern wäre es unrichtig, im Internet über Cookies zu sprechen
                Kontext mobiler Apps, da es sich um einen browserbasierten Tracker handelt. Für
                Aus diesem Grund wird in diesem Dokument nur der Begriff „Cookies“ verwendet
                wobei es speziell auf diesen bestimmten Typ hinweisen soll
                Tracker.`,
      policy3: ` Einige der Zwecke, für die Tracker verwendet werden, erfordern möglicherweise auch
                die Einwilligung des Nutzers. Wann immer eine Einwilligung erteilt wird, kann diese freiwillig erfolgen
                jederzeit gemäß den hierin enthaltenen Anweisungen widerrufen werden
                dokumentieren.`,
      policy4: ` Diese Anwendung verwendet Tracker, die direkt vom Eigentümer verwaltet werden
                (sog. „First-Party“-Tracker) und Tracker, die es ermöglichen
                Dienstleistungen eines Dritten (sog. „Drittanbieter“)
                Tracker). Sofern in diesem Dokument nicht anders angegeben,
                Drittanbieter können auf die von ihnen verwalteten Tracker zugreifen.`,
      policy5: `  Die Gültigkeits- und Ablauffristen von Cookies und Ähnlichem
                Tracker können je nach der vom Eigentümer festgelegten Lebensdauer variieren
                den jeweiligen Anbieter. Einige von ihnen verfallen mit Beendigung des
                Browsersitzung des Benutzers. Zusätzlich zu den Angaben in der
                Beschreibungen in jeder der folgenden Kategorien finden Benutzer möglicherweise
                genauere und aktuellere Informationen zur Lebensdauer
                Spezifikation sowie alle anderen relevanten Informationen – wie z
                die Anwesenheit anderer Tracker – in den verlinkten Datenschutzrichtlinien von
                den jeweiligen Drittanbietern oder durch Kontaktaufnahme mit dem Eigentümer.`,
      policy6: `Wie diese Anwendung Tracker verwendet`,
      policy7: `Notwendig`,
      policy8: `Diese Anwendung verwendet sogenannte „technische“ Cookies und andere
                  ähnliche Tracker, um Aktivitäten auszuführen, die streng sind
                  die für den Betrieb oder die Bereitstellung des Dienstes erforderlich sind.`,
      policy9: `Von Dritten verwaltete Tracker`,
      policy10: `iubenda Datenschutzkontrollen und Cookie-Lösung (iubenda srl)`,
      policy11: ` Die Datenschutzkontroll- und Cookie-Lösung von iubenda ermöglicht dies
                  Eigentümer zum Sammeln und Speichern der Benutzerpräferenzen im Zusammenhang mit dem
                  Verarbeitung personenbezogener Daten, insbesondere deren Nutzung
                  von Cookies und anderen Trackern in dieser Anwendung.`,
      policy12: `Verarbeitete personenbezogene Daten: IP-Adresse und Tracker.`,
      policy13: `Ort der Verarbeitung: Italien`,
      policy14: `Datenschutzrichtlinie.`,
      policy15: `Speicherdauer:`,
      policy16: `_iub_cs-*: 1 Jahr`,
      policy17: `Funktionalität`,
      policy18: `Diese Anwendung verwendet Tracker, um grundlegende Interaktionen zu ermöglichen und
        Funktionalitäten, die es Benutzern ermöglichen, auf ausgewählte Funktionen von zuzugreifen
        des Dienstes und die Erleichterung der Kommunikation des Benutzers mit dem
        Eigentümer.`,
      policy19: `Von Dritten verwaltete Tracker`,
      policy20: `Mailingliste oder Newsletter ()`,
      policy21: `Durch die Anmeldung zur Mailingliste oder zum Newsletter wird die
                  Die E-Mail-Adresse des Benutzers wird zur Kontaktliste dieser Personen hinzugefügt
                  Wer kann E-Mail-Nachrichten mit Informationen über erhalten?
                  kommerzieller oder werblicher Art in Bezug auf diese Anwendung.
                  Möglicherweise wird dadurch auch Ihre E-Mail-Adresse zu dieser Liste hinzugefügt
                  nach der Anmeldung zu dieser Anwendung oder nach dem Kauf.`,
      policy22: `Verarbeitete personenbezogene Daten: E-Mail-Adresse, Tracker und Nutzungsdaten.`,
      policy23: `Erfahrung`,
      policy24: `Diese Anwendung verwendet Tracker, um die Qualität zu verbessern
                  Benutzererfahrung und ermöglichen Interaktionen mit externen Inhalten,
                  Netzwerke und Plattformen.`,
      policy25: `Von Dritten verwaltete Tracker`,
      policy26: `Google Fonts (Google LLC)`,
      policy27: ` Google Fonts ist ein Dienst zur Visualisierung von Schriftarten, der von bereitgestellt wird
                  Google LLC, das dieser Anwendung die Einbindung von Inhalten ermöglicht
                  dieser Art auf seinen Seiten.`,
      policy28: `Verarbeitete personenbezogene Daten: Tracker und Nutzungsdaten.`,
      policy29: `Ort der Verarbeitung: Vereinigte Staaten`,
      policy30: `Datenschutzrichtlinie.`,
      policy31: `Facebook-Like-Button und soziale Widgets (Meta Platforms, Inc.)`,
      policy32: `Der Facebook-Like-Button und die sozialen Widgets sind Dienste
                  Ermöglichen der Interaktion mit dem bereitgestellten sozialen Netzwerk Facebook
                  von Meta Platforms, Inc.`,
      policy33: `Verarbeitete personenbezogene Daten: Tracker und Nutzungsdaten.`,
      policy34: `Ort der Verarbeitung: Vereinigte Staaten`,
      policy35: `Datenschutzrichtlinie.`,
      policy36: `Opt-out.`,
      policy37: `Speicherdauer:`,
      policy38: `_fbp: 3 Monate`,
      policy39: `So verwalten Sie Präferenzen und erteilen oder widerrufen Ihre Einwilligung
                  diese Anwendung`,
      policy40: `Wann immer die Verwendung von Trackern auf einer Einwilligung basiert, können Benutzer dies tun
                  eine solche Einwilligung erteilen oder widerrufen, indem Sie Ihre Einwilligung festlegen oder aktualisieren
                  Einstellungen über das entsprechende Datenschutzauswahlfeld, das auf verfügbar ist
                  diese Anwendung.`,
      policy41: `In Bezug auf Tracker von Drittanbietern können Benutzer diese verwalten
                  Präferenzen über den entsprechenden Opt-out-Link (sofern vorhanden) durch
                  unter Verwendung der in der Datenschutzrichtlinie des Dritten angegebenen Mittel,
                  oder durch Kontaktaufnahme mit dem Dritten.`,
      policy42: `So steuern oder löschen Sie Cookies und ähnliche Technologien über
                  Ihre Geräteeinstellungen`,
      policy43: `Benutzer können dazu ihre eigenen Browsereinstellungen verwenden:`,
      policy44: `Sehen Sie, welche Cookies oder andere ähnliche Technologien gesetzt wurden
                    auf dem Gerät;`,
      policy45: `Blockieren Sie Cookies oder ähnliche Technologien;`,
      policy46: `Löschen Sie Cookies oder ähnliche Technologien aus dem Browser.`,
      policy47: `Die Browsereinstellungen erlauben jedoch keine differenzierte Steuerung der Einwilligung nach Kategorie.`,
      policy48: `Informationen zur Verwaltung von Cookies in den am häufigsten verwendeten Browsern finden Nutzer beispielsweise unter den folgenden Adressen:`,
      policy49: `Google Chrome`,
      policy50: `Mozilla Firefox`,
      policy51: `Apple-Safari`,
      policy52: `Microsoft Internet Explorer`,
      policy53: `Microsoft Edge`,
      policy54: `Mutig`,
      policy55: `Oper`,
      policy56: `Benutzer können auch bestimmte Kategorien von Trackern verwalten, auf denen sie verwendet werden
                  mobile Apps, indem Sie sich über entsprechende Geräteeinstellungen abmelden, z
                  B. die Gerätewerbeeinstellungen für mobile Geräte, oder
                  Tracking-Einstellungen im Allgemeinen (Benutzer können die Geräteeinstellungen öffnen
                  und suchen Sie nach der entsprechenden Einstellung).`,
      policy57: `Konsequenzen einer Verweigerung der Verwendung von Trackern`,
      policy58: `Es steht dem Nutzer frei, zu entscheiden, ob er die Nutzung gestattet oder nicht
                  Tracker. Bitte beachten Sie jedoch, dass Tracker dabei helfen
                  Anwendung für ein besseres und fortgeschrittenes Erlebnis
                  Funktionalitäten für Benutzer (im Einklang mit den in
                  dieses Dokument). Daher, wenn der Benutzer sich dafür entscheidet, die Nutzung zu blockieren
                  von Trackern ist der Eigentümer möglicherweise nicht in der Lage, entsprechende Informationen bereitzustellen
                  Merkmale.`,
      policy59: `Eigentümer und Datenverantwortlicher`,
      policy60: `Studio M, Unipessoal Lda.`,
      policy61: `David Marx Frh von Mueller`,
      policy62: `Stio Pelourinho`,
      policy63: `7630-578 Sao Teotonio`,
      policy64: `Beja`,
      policy65: `Portugal`,
      policy66: `Kontakt-E-Mail des Eigentümers:`,
      policy67: `hello@ebbiapp.com`,
      policy68: `Seit der Verwendung von Trackern von Drittanbietern über diese Anwendung
                  kann nicht vollständig vom Eigentümer kontrolliert werden, keine spezifischen Referenzen
                  an Drittanbieter-Tracker gelten als indikativ. In
                  Um vollständige Informationen zu erhalten, werden die Benutzer gebeten
                  die Datenschutzrichtlinien des jeweiligen Drittanbieters einzusehen
                  Dienstleistungen, die in diesem Dokument aufgeführt sind.`,
      policy69: `Angesichts der objektiven Komplexität des Trackings
                  Technologien, Benutzer werden aufgefordert, sich an den Eigentümer zu wenden
                  Sie möchten weitere Informationen zu deren Verwendung erhalten
                  Technologien dieser Anwendung.`,
      policy70: `Definitionen und rechtliche Hinweise`,
      policy71: `Persönliche Daten (oder Daten)`,
      policy72: `Alle Informationen, die direkt, indirekt oder im Zusammenhang mit
                  andere Informationen – einschließlich einer persönlichen Identifikationsnummer –
                  ermöglicht die Identifizierung oder Identifizierbarkeit einer natürlichen Person
                  Person.`,
      policy73: `Nutzungsdaten`,
      policy74: `Informationen, die automatisch über diese Anwendung erfasst werden (bzw
                  in dieser Anwendung eingesetzte Dienste Dritter), die dies können
                  Dazu gehören: die IP-Adressen oder Domänennamen der Computer
                  Der URI wird von den Benutzern verwendet, die diese Anwendung verwenden
                  Adressen (Uniform Resource Identifier), die Uhrzeit der
                  Anfrage, die Methode, mit der die Anfrage an den gesendet wurde
                  Server, die Größe der als Antwort empfangenen Datei, die numerische
                  Code, der den Status der Antwort des Servers angibt (erfolgreich).
                  Ergebnis, Fehler usw.), das Herkunftsland, die Merkmale von
                  der vom Nutzer verwendete Browser und das Betriebssystem, die
                  verschiedene Zeitdetails pro Besuch (z. B. die für jeden Besuch aufgewendete Zeit).
                  Seite innerhalb der Anwendung) und die Details zum Pfad
                  werden im Antrag unter besonderer Berücksichtigung der folgenden Punkte befolgt
                  Reihenfolge der besuchten Seiten und andere Parameter über das Gerät
                  Betriebssystem und/oder die IT-Umgebung des Benutzers.`,
      policy75: `Benutzer`,
      policy76: ` Die Person, die diese Anwendung nutzt, sofern nicht anders angegeben
                  angegeben, mit der betroffenen Person übereinstimmt.`,
      policy77: `Betroffene Person`,
      policy78: `Die natürliche Person, auf die sich die personenbezogenen Daten beziehen.`,
      policy79: `Datenverarbeiter (oder Auftragsverarbeiter)`,
      policy80: `Die natürliche oder juristische Person, Behörde, Einrichtung oder andere Person
                  Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet,
                  wie in dieser Datenschutzerklärung beschrieben.`,
      policy81: `Datenverantwortlicher (oder Eigentümer)`,
      policy82: `Die natürliche oder juristische Person, Behörde, Einrichtung oder andere Person
                  Körperschaft, die allein oder gemeinsam mit anderen das bestimmt
                  Zwecke und Mittel der Verarbeitung personenbezogener Daten, einschließlich
                  die Sicherheitsmaßnahmen bezüglich Betrieb und Nutzung
                  Anwendung. Sofern nicht anders angegeben, ist der Datenverantwortliche
                  der Eigentümer dieser Anwendung.`,
      policy83: `This Application`,
      policy84: `Die Mittel, mit denen die personenbezogenen Daten des Benutzers erfasst werden
                  und verarbeitet.`,
      policy85: `Dienst`,
      policy86: `Der von dieser Anwendung bereitgestellte Dienst, wie im beschrieben
                  relative Begriffe (falls verfügbar) und auf dieser Website/Anwendung.`,
      policy87: `Europäische Union (oder EU)`,
      policy88: ` Sofern nicht anders angegeben, sind alle darin enthaltenen Verweise
                  Das Dokument an die Europäische Union umfasst alle derzeitigen Mitgliedstaaten
                  zur Europäischen Union und zum Europäischen Wirtschaftsraum.`,
      policy89: `Cookie`,
      policy90: `Cookies sind Tracker, die aus kleinen gespeicherten Datenmengen bestehen
                  der Browser des Benutzers.`,
      policy91: `Tracker`,
      policy92: `  Der Tracker weist jede Technologie – z. B. Cookies – eindeutig darauf hin
                  Identifikatoren, Web-Beacons, eingebettete Skripte, E-Tags und
                  Fingerabdruck – das ermöglicht beispielsweise die Nachverfolgung von Benutzern
                  durch Zugriff auf oder Speicherung von Informationen auf dem Gerät des Benutzers.`,
      policy93: `Rechtliche Informationen`,
      policy94: `Diese Datenschutzrichtlinie bezieht sich ausschließlich auf diese Anwendung, sofern dies nicht der Fall ist
                  In diesem Dokument wird nichts anderes angegeben.`,
      policy95: `Letzte Aktualisierung: 18. September
                  2024`,
    },
    privacy_policy: {
      prp: `Datenschutzerklärung von ebbi`,
      prp1: `Diese Anwendung sammelt einige personenbezogene Daten von ihren Benutzern`,
      prp2: `Dieses Dokument kann zu Referenzzwecken über die Druckfunktion ausgedruckt werden
              Befehl in den Einstellungen eines beliebigen Browsers`,
      prp3: `Eigentümer und Datenverantwortlicher`,
      prp4: `Studio M, Unipessoal Lda.`,
      prp5: `David Marx Frh von Mueller`,
      prp6: `Stio Pelourinho`,
      prp7: `7630-578 Sao Teotonio`,
      prp8: `Beja`,
      prp9: `Portugal`,
      prp10: ` Kontakt-E-Mail des Eigentümers:`,
      prp11: `  : hello@ebbiapp.com`,
      prp12: `Arten der erfassten Daten`,
      prp13: `Zu den Arten personenbezogener Daten, die diese Anwendung erfasst, gehören:
                  Von sich aus oder durch Dritte gibt es: E-Mail-Adresse;
                  Nutzungsdaten; Tracker; IP-Adresse; verschiedene Arten von Daten; Benutzer
                  AUSWEIS; Sprache; Absturzdaten; Diagnostik; Produktinteraktion;
                  Kaufhistorie; Land`,
      prp14: `Ausführliche Informationen zu jeder Art der erfassten personenbezogenen Daten finden Sie hier
                  bereitgestellt in den entsprechenden Abschnitten dieser Datenschutzrichtlinie oder von
                  spezifische Erläuterungstexte, die vor den Daten angezeigt werden
                  Sammlung. Personenbezogene Daten können vom Benutzer freiwillig angegeben werden.
                  oder, im Falle von Nutzungsdaten, automatisch bei der Nutzung erfasst
                  diese Anwendung. Sofern nicht anders angegeben, werden alle Daten angefordert
                  durch diese Anwendung ist verpflichtend und unterbleibt die Bereitstellung dieser
                  Daten können dazu führen, dass diese Anwendung ihre Daten nicht bereitstellen kann
                  Dienstleistungen. In den Fällen, in denen dieser Antrag ausdrücklich darauf hinweist
                  Da einige Daten nicht obligatorisch sind, steht es den Nutzern frei, dies nicht zu tun
                  Geben Sie diese Daten ohne Auswirkungen auf die Verfügbarkeit weiter
                  oder das Funktionieren des Dienstes.`,
      prp15: `Benutzer, die sich nicht sicher sind, welche personenbezogenen Daten obligatorisch sind
                  können sich gerne an den Eigentümer wenden. Jegliche Verwendung von Cookies – bzw
                  andere Tracking-Tools – von dieser Anwendung oder von den Eigentümern von
                  Die von dieser Anwendung genutzten Dienste Dritter dienen diesem Zweck
                  der Bereitstellung des vom Nutzer gewünschten Dienstes darüber hinaus
                  alle anderen im vorliegenden Dokument und in der beschriebenen Zwecke
                  Cookie-Richtlinie.`,
      prp16: `Benutzer sind für alle personenbezogenen Daten Dritter verantwortlich
                  über diese Anwendung erhalten, veröffentlicht oder weitergegeben werden`,
      prp17: `Art und Ort der Datenverarbeitung`,
      prp18: `Verarbeitungsmethoden`,
      prp19: `Der Eigentümer ergreift geeignete Sicherheitsmaßnahmen, um dies zu verhindern
                    unbefugter Zugriff, Offenlegung, Änderung oder unbefugter Zugriff
                    Vernichtung der Daten.`,
      prp20: ` Die Datenverarbeitung erfolgt mittels Computer und/oder IT
                    aktivierte Tools, Einhaltung organisatorischer Verfahren und Modi
                    eng mit den angegebenen Zwecken verbunden. Zusätzlich zum
                    In einigen Fällen sind die Daten möglicherweise für bestimmte Eigentümer zugänglich
                    Arten von Verantwortlichen, die am Betrieb beteiligt sind
                    dieser Anwendung (Verwaltung, Vertrieb, Marketing, Recht,
                    Systemadministration) oder externe Parteien (wie z.B
                    Drittanbieter technischer Dienstleistungen, Postboten,
                    Hosting-Anbieter, IT-Unternehmen, Kommunikationsagenturen)
                    erforderlichenfalls vom Eigentümer als Datenverarbeiter ernannt. Der
                    Eine aktualisierte Liste dieser Parteien kann beim Eigentümer angefordert werden
                    jederzeit.`,
      prp21: `Ort`,
      prp22: `Die Daten werden in den Betriebsbüros des Eigentümers und in verarbeitet
                    alle anderen Orte, an denen die an der Verarbeitung beteiligten Parteien
                    liegen.`,
      prp23: `Abhängig vom Standort des Benutzers kann es zu Datenübertragungen kommen
                    Übermittlung der Daten des Nutzers in ein anderes Land als sein eigenes
                    eigen. Um mehr über den Ort der Verarbeitung dieser Daten zu erfahren
                    Übertragene Daten, Benutzer können den Abschnitt überprüfen, der sie enthält
                    Einzelheiten zur Verarbeitung personenbezogener Daten.`,
      prp24: `Aufbewahrungszeit`,
      prp25: `Sofern in diesem Dokument nicht anders angegeben, handelt es sich um personenbezogene Daten
                    werden so lange verarbeitet und gespeichert, wie dies erforderlich ist
                    Zweck, für den sie erhoben wurden und für den sie aufbewahrt werden dürfen
                    länger aufgrund einer geltenden gesetzlichen Verpflichtung oder auf Grundlage der
                    Einwilligung der Nutzer.`,
      prp26: `Die Zwecke der Verarbeitung`,
      prp27: `Die den Nutzer betreffenden Daten werden erhoben, um dem Eigentümer dies zu ermöglichen
                  Bereitstellung seines Dienstes, Einhaltung seiner gesetzlichen Verpflichtungen, Reaktion
                  auf Vollstreckungsanträge zu reagieren, seine Rechte und Interessen zu schützen (bzw
                  die seiner Benutzer oder Dritter), erkennen böswillige oder
                  betrügerische Aktivitäten sowie Folgendes: Kontaktaufnahme mit dem
                  Benutzer, Inhalte von externen Plattformen anzeigen, Interaktion
                  mit externen sozialen Netzwerken und Plattformen, Sammlung von
                  Datenschutzbezogene Präferenzen, Datenübertragung außerhalb der EU, Beta
                  Test-, Hosting- und Backend-Infrastruktur und Plattform
                  Dienstleistungen und Hosting.`,
      prp28: `Für spezifische Informationen zu den jeweils verwendeten personenbezogenen Daten
                  Zu diesem Zweck kann der Nutzer auf den Abschnitt „Detaillierte Informationen“ verweisen
                  zur Verarbeitung personenbezogener Daten“.`,
      prp29: `Detaillierte Informationen zur Verarbeitung personenbezogener Daten`,
      prp30: `Personenbezogene Daten werden für die folgenden Zwecke und Verwendungen erhoben
                  die folgenden Dienstleistungen:`,
      prp31: `Betatest`,
      prp32: `Diese Art von Dienst ermöglicht die Verwaltung des Benutzerzugriffs
                    auf diese Anwendung oder Teile davon für den Zweck
                    Testen einer bestimmten Funktion oder der gesamten Anwendung.`,
      prp33: ` Der Dienstanbieter kann automatisch Daten dazu sammeln
                    Abstürze und Statistiken im Zusammenhang mit der Nutzung durch den Benutzer
                    Bewerbung in persönlich identifizierbarer Form.`,
      prp34: ` App Center – Betatests (Microsoft Corporation)`,
      prp35: ` App Center – Betatests ist ein bereitgestellter Betatestdienst
                    von Microsoft Corporation. Diese Anwendung kann auch Push senden
                    Benachrichtigungen an den Benutzer.`,
      prp36: `Verarbeitete personenbezogene Daten: Absturzdaten; E-Mail-Adresse; Sprache;
                    Benutzer-ID`,
      prp37: `Ort der Verarbeitung: Vereinigte Staaten –`,
      prp38: `Datenschutzrichtlinie`,
      prp39: `Sammlung datenschutzbezogener Präferenzen`,
      prp40: ` Diese Art von Dienst ermöglicht dieser Anwendung das Sammeln und
                    Speichern Sie die Präferenzen der Benutzer in Bezug auf die Erfassung, Verwendung usw
                    Verarbeitung ihrer persönlichen Daten, wie von der verlangt
                    geltende Datenschutzgesetze.`,
      prp41: ` iubenda Datenschutzkontrollen und Cookie-Lösung (iubenda srl)`,
      prp42: `Die iubenda-Datenschutzkontroll- und Cookie-Lösung ermöglicht dies
                    Eigentümer, um Benutzerpräferenzen im Zusammenhang mit dem zu sammeln und zu speichern
                    Verarbeitung personenbezogener Daten, insbesondere auf die
                    Verwendung von Cookies und anderen Trackern in dieser Anwendung.`,
      prp43: `Verarbeitete personenbezogene Daten: IP-Adresse; Tracker.`,
      prp44: `Verarbeitungsort: Italy –`,
      prp45: `Datenschutzrichtlinie`,
      prp46: `Kontaktaufnahme mit dem Benutzer`,
      prp47: ` Kontaktformular ()`,
      prp48: `Durch das Ausfüllen des Kontaktformulars mit seinen Daten erhält der Nutzer
                    ermächtigt diese Anwendung, diese Daten für die Beantwortung zu verwenden
                    Anfragen nach Informationen, Kostenvoranschlägen oder anderen Anfragen
                    wie in der Kopfzeile des Formulars angegeben.`,
      prp49: `Verarbeitete personenbezogene Daten: E-Mail-Adresse; Nutzungsdaten.`,
      prp50: `Mailingliste oder Newsletter()`,
      prp51: `Durch die Anmeldung zur Mailingliste oder zum Newsletter wird die
                    Die E-Mail-Adresse des Benutzers wird zur Kontaktliste von hinzugefügt
                    diejenigen, die möglicherweise E-Mail-Nachrichten mit Informationen über erhalten
                    kommerzieller oder werblicher Art in Bezug auf diese Anwendung.
                    Möglicherweise wird auch Ihre E-Mail-Adresse zu dieser Liste hinzugefügt
                    Ergebnis der Anmeldung zu diesem Antrag oder nach der Erstellung eines
                    kaufen.`,
      prp52: `Verarbeitete personenbezogene Daten: E-Mail-Adresse; Tracker; Nutzungsdaten.`,
      prp53: `Inhalte von externen Plattformen anzeigen`,
      prp54: `Mit dieser Art von Dienst können Sie Inhalte anzeigen, die auf gehostet werden
                  externe Plattformen direkt von den Seiten dieser Anwendung aus
                  und mit ihnen interagieren. Solche Dienste werden oft als bezeichnet
                  Widgets, das sind kleine Elemente, die auf einer Website oder App platziert werden.
                  Sie stellen bestimmte Informationen bereit oder führen eine bestimmte Aufgabe aus
                  Funktion und ermöglichen oft eine Benutzerinteraktion. Diese Art von
                  Der Dienst sammelt möglicherweise weiterhin Webverkehrsdaten für die Seiten, auf denen
                  Der Dienst wird installiert, auch wenn Benutzer ihn nicht nutzen`,
      prp55: `Google Fonts (Google LLC)`,
      prp56: `Google Fonts ist ein Dienst zur Visualisierung von Schriftarten, der von bereitgestellt wird
                    Google LLC, das dieser Anwendung die Einbindung von Inhalten ermöglicht
                    dieser Art auf seinen Seiten.`,
      prp57: `Verarbeitete personenbezogene Daten: Tracker; Nutzungsdaten.`,
      prp58: `Ort der Verarbeitung: Vereinigte Staaten –`,
      prp59: `Datenschutzrichtlinie.`,
      prp60: `Hosting- und Backend-Infrastruktur`,
      prp61: `Diese Art von Dienst dient dem Hosten von Daten und Dateien
                    die die Ausführung und Verbreitung dieser Anwendung ermöglichen
                    um eine vorgefertigte Infrastruktur für den spezifischen Betrieb bereitzustellen
                    Funktionen oder Teile dieser Anwendung.`,
      prp62: `Einige der unten aufgeführten Dienste funktionieren ggf. möglicherweise
                    über geografisch verteilte Server, wodurch es möglich ist
                    Es ist schwierig, den tatsächlichen Standort des Personals zu bestimmen
                    Daten werden gespeichert.`,
      prp63: `Amazon Web Services (AWS) (Amazon Web Services, Inc.)`,
      prp64: ` Amazon Web Services (AWS) ist ein Hosting- und Backend-Dienst
                    bereitgestellt von Amazon Web Services, Inc.`,
      prp65: `Verarbeitete personenbezogene Daten: verschiedene Arten von Daten, wie in angegeben
                    die Datenschutzrichtlinie des Dienstes.`,
      prp66: `Ort der Verarbeitung: Deutschland –`,
      prp67: `Datenschutzrichtlinie.`,
      prp68: `Interaktion mit externen sozialen Netzwerken und Plattformen`,
      prp69: `Diese Art von Diensten ermöglichen die Interaktion mit sozialen Netzwerken
                    oder anderen externen Plattformen direkt von den Seiten dieser
                    Anwendung.`,
      prp70: `Die dadurch gewonnene Interaktion und Information
                    Die Anwendung unterliegt stets den Datenschutzeinstellungen des Nutzers
                    für jedes soziale Netzwerk.`,
      prp71: `Diese Art von Dienst sammelt möglicherweise weiterhin Verkehrsdaten für
                    Seiten, auf denen der Dienst installiert ist, auch wenn Benutzer dies nicht tun
                    Benutze es.`,
      prp72: `Es wird empfohlen, sich von den jeweiligen Diensten abzumelden
                    um sicherzustellen, dass die verarbeiteten Daten in dieser Anwendung verarbeitet werden
                    wird nicht wieder mit dem Profil des Benutzers verbunden.`,
      prp73: `Facebook-Like-Button und soziale Widgets (Meta Platforms, Inc.)`,
      prp74: `Der Facebook-Like-Button und die sozialen Widgets sind Dienste
                    Ermöglichen der Interaktion mit dem bereitgestellten sozialen Netzwerk Facebook
                    von Meta Platforms, Inc.`,
      prp75: `Verarbeitete personenbezogene Daten: Tracker; Nutzungsdaten.`,
      prp76: `Ort der Verarbeitung: Vereinigte Staaten–`,
      prp77: `Datenschutzrichtlinie.`,
      prp78: `Opt-out.`,
      prp79: `Plattformdienste und Hosting`,
      prp80: `Diese Dienste dienen dem Hosten und Ausführen von Schlüsseln
                    Komponenten dieser Anwendung und ermöglichen daher die
                    Bereitstellung dieser Anwendung innerhalb einer einheitlichen Plattform.
                    Solche Plattformen bieten dem Eigentümer eine breite Palette von Tools –
                    z.B. Analyse, Benutzerregistrierung, Kommentieren, Datenbank
                    Management, E-Commerce, Zahlungsabwicklung – das impliziert die
                    Erhebung und Verarbeitung personenbezogener Daten.`,
      prp81: `Einige dieser Dienste funktionieren geografisch verteilt
                    Servern, was es schwierig macht, den tatsächlichen Standort zu bestimmen
                    wo die personenbezogenen Daten gespeichert werden.`,
      prp82: `App Store Connect (Apple Inc.)`,
      prp83: `Diese Anwendung wird im App Store von Apple vertrieben
                    Plattform für den Vertrieb mobiler Apps, bereitgestellt von
                    Apple Inc.`,
      prp84: `Mit App Store Connect kann der Eigentümer diese Anwendung verwalten
                    im App Store von Apple. Je nach Konfiguration App
                    Store Connect stellt dem Eigentümer Analysedaten zum Benutzer zur Verfügung
                    Engagement und App-Entdeckung, Marketingkampagnen, Vertrieb,
                    In-App`,
      prp85: `Käufe und Zahlungen, um deren Leistung zu messen
                    Anwendung. App Store Connect erhebt nur solche Daten von
                    Benutzer, die zugestimmt haben, sie mit dem Eigentümer zu teilen. Benutzer können
                    Weitere Informationen zur Abmeldung über ihr Gerät finden Sie hier
                    Einstellungen`,
      prp86: `Hier`,
      prp87: `.`,
      prp88: `Verarbeitete personenbezogene Daten: Diagnose; Produktinteraktion;
                    Kaufhistorie; Nutzungsdaten; Benutzer-ID.`,
      prp89: `Ort der Verarbeitung: Vereinigte Staaten–`,
      prp90: `Datenschutzrichtlinie`,
      prp91: `.`,
      prp92: ` Google Play Store (Google Ireland Limited)`,
      prp93: `Diese Anwendung wird im Google Play Store vertrieben, a
                    Plattform für den Vertrieb mobiler Apps, bereitgestellt von
                    Google Ireland Limited.`,
      prp94: `Benutzer können diese Analysefunktion direkt über deaktivieren
                    ihre Geräteeinstellungen. Weitere Informationen zur Verwaltung
                    Die Analyseeinstellungen finden Sie unter`,
      prp95: `diese Seite`,
      prp96: `.`,
      prp97: `Verarbeitete personenbezogene Daten: Nutzungsdaten.`,
      prp98: `Ort der Verarbeitung: Irland–.`,
      prp99: `Datenschutzrichtlinie`,
      prp100: `.`,
      prp101: `Ionos (IONOS Group SE)`,
      prp102: `Ionos ist eine Plattform der IONOS Group SE, die es ermöglicht
                    Eigentümer zum Erstellen, Ausführen und Hosten dieser Anwendung.`,
      prp103: `Verarbeitete personenbezogene Daten: Land.`,
      prp104: `Ort der Verarbeitung: Deutschland –`,
      prp105: `Datenschutzrichtlinie`,
      prp106: `.`,
      prp107: ` Cookie-Richtlinie`,
      prp108: `Diese Anwendung verwendet Tracker. Um mehr zu erfahren, können Benutzer
                    konsultieren Sie die`,
      prp109: `Cookie-Richtlinie`,
      prp110: `.`,
      prp111: `Weitere Informationen für Benutzer`,
      prp112: `Rechtsgrundlage der Verarbeitung`,
      prp113: `Google Fonts ist ein Dienst zur Visualisierung von Schriftarten, der von bereitgestellt wird
                    Google LLC, das dieser Anwendung die Einbindung von Inhalten ermöglicht
                    dieser Art auf seinen Seiten.`,
      prp114: `Der Eigentümer kann gegebenenfalls personenbezogene Daten von Benutzern verarbeiten
                    Folgendes gilt:`,
      prp115: `Der Nutzer hat für einen oder mehrere bestimmte Zwecke seine Einwilligung gegeben
                      Zwecke.`,
      prp116: ` Die Bereitstellung der Daten ist für die Durchführung eines Auftrags erforderlich
                      Vereinbarung mit dem Nutzer und/oder für vorvertragliche Vereinbarungen
                      Verpflichtungen daraus;`,
      prp117: `Die Verarbeitung ist zur Einhaltung gesetzlicher Vorschriften erforderlich
                      Verpflichtung, der der Eigentümer unterliegt;`,
      prp118: `Die Verarbeitung steht im Zusammenhang mit einer Aufgabe, die im ausgeführt wird
                      öffentliches Interesse oder in Ausübung öffentlicher Gewalt erfolgt
                      Eigentum des Eigentümers;`,
      prp119: `Die Verarbeitung ist für die Zwecke der rechtmäßigen Zwecke erforderlich
                      Interessen, die der Eigentümer oder ein Dritter verfolgt.`,
      prp120: `In jedem Fall hilft der Eigentümer gerne bei der Klärung des Problems
                    spezifische Rechtsgrundlage, die für die Verarbeitung gilt, und in
                    insbesondere ob die Bereitstellung personenbezogener Daten a
                    gesetzliche oder vertragliche Anforderung oder eine Anforderung
                    notwendig, um einen Vertrag abzuschließen.`,
      prp121: `Weitere Informationen zur Aufbewahrungszeit`,
      prp122: `Sofern in diesem Dokument nicht anders angegeben, handelt es sich um personenbezogene Daten
                    werden so lange verarbeitet und gespeichert, wie dies erforderlich ist
                    Zweck, für den sie erhoben wurden und für den sie aufbewahrt werden dürfen
                    länger aufgrund einer geltenden gesetzlichen Verpflichtung oder auf Grundlage der
                    Einwilligung der Nutzer.`,
      prp123: `daher:`,
      prp124: `Personenbezogene Daten, die für Zwecke im Zusammenhang mit der erhoben werden
                      Erfüllung eines Vertrags zwischen dem Eigentümer und dem Nutzer
                      werden bis zur vollständigen Vertragserfüllung aufbewahrt
                      durchgeführt.`,
      prp125: `Persönliche Daten, die für die Zwecke des Eigentümers erhoben werden
                      Berechtigte Interessen bleiben so lange wie erforderlich gewahrt
                      solche Zwecke erfüllen. Benutzer finden möglicherweise spezifische Informationen
                      hinsichtlich der vom Eigentümer verfolgten berechtigten Interessen
                      in den entsprechenden Abschnitten dieses Dokuments oder von
                      Kontaktaufnahme mit dem Eigentümer.`,
      prp126: `Dem Eigentümer kann gestattet werden, personenbezogene Daten länger aufzubewahren
                    Zeitraum, in dem der Benutzer einer solchen Verarbeitung zugestimmt hat,
                    solange die Einwilligung nicht widerrufen wird. Darüber hinaus ist die
                    Der Eigentümer kann verpflichtet sein, personenbezogene Daten länger aufzubewahren
                    Frist, wann immer dies zur Erfüllung einer rechtlichen Verpflichtung oder erforderlich ist
                    Anordnung einer Behörde. Sobald die Aufbewahrungsfrist abgelaufen ist,
                    Personenbezogene Daten werden gelöscht. Daher ist das Recht von
                    Auskunft, Recht auf Löschung, Recht auf Berichtigung usw
                    das Recht auf Datenübertragbarkeit kann danach nicht mehr durchgesetzt werden
                    Ablauf der Aufbewahrungsfrist.`,
      prp127: `Die Rechte der Nutzer basieren auf der Datenschutz-Grundverordnung
                    Verordnung (DSGVO)`,
      prp128: `Benutzer können bestimmte Rechte in Bezug auf ihre Daten ausüben
                    vom Eigentümer verarbeitet.`,
      prp129: `Insbesondere haben Benutzer das Recht, Folgendes zu tun:
                    soweit gesetzlich zulässig:`,
      prp130: `Ihre Einwilligung können Sie jederzeit widerrufen.`,
      prp131: `Nutzer haben das Recht, ihre Einwilligung zu widerrufen, sofern sie eine Einwilligung erteilt haben
                      zuvor ihre Einwilligung zur Verarbeitung ihrer Daten gegeben haben
                      Persönliche Daten.`,
      prp132: `Widerspruch gegen die Verarbeitung Ihrer Daten einlegen.`,
      prp133: `Nutzer haben das Recht, der Verarbeitung ihrer Daten zu widersprechen
                      Daten, wenn die Verarbeitung auf einer anderen Rechtsgrundlage erfolgt
                      als Zustimmung.`,
      prp134: `Greifen Sie auf ihre Daten zu.`,
      prp135: `Benutzer haben das Recht zu erfahren, ob Daten von verarbeitet werden
                      Der Eigentümer kann Offenlegungen zu bestimmten Aspekten einholen
                      die Verarbeitung abzuwickeln und eine Kopie der Daten zu erhalten
                      Verarbeitung.`,
      prp136: `Überprüfen Sie den Sachverhalt und fordern Sie eine Korrektur an.`,
      prp137: ` Benutzer haben das Recht, die Richtigkeit ihrer Daten zu überprüfen
                      und bitten Sie um Aktualisierung oder Korrektur.`,
      prp138: `Beschränken Sie die Verarbeitung Ihrer Daten.`,
      prp139: `Nutzer haben das Recht, die Verarbeitung ihrer Daten einzuschränken
                      Daten. In diesem Fall wird der Eigentümer seine Daten nicht verarbeiten
                      für andere Zwecke als die Speicherung.`,
      prp140: `Ihre personenbezogenen Daten löschen oder auf andere Weise entfernen lassen.`,
      prp141: `Nutzer haben das Recht, die Löschung ihrer Daten zu verlangen
                      vom Eigentümer.`,
      prp142: `Erhalten Sie ihre Daten und lassen Sie sie an einen anderen übertragen
                        Regler.`,
      prp143: `Benutzer haben das Recht, ihre Daten in einer strukturierten,
                      allgemein verwendetes und maschinenlesbares Format und ggf
                      technisch machbar, die Übermittlung an einen anderen zu veranlassen
                      Steuerung ohne Behinderung.`,
      prp144: `einer Beschwerde ausweichen.`,
      prp145: `Nutzer haben das Recht, Ansprüche bei ihrem zuständigen Ansprechpartner geltend zu machen
                      Datenschutzbehörde.`,
      prp146: `Darüber hinaus haben Nutzer das Recht, sich über die Rechtsgrundlagen zu informieren
                    Datenübermittlungen ins Ausland, auch ins Ausland
                    Organisation, die dem Völkerrecht unterliegt oder von gegründet wurde
                    zwei oder mehr Ländern, etwa der UNO, und über die Sicherheit
                    Maßnahmen des Eigentümers zum Schutz seiner Daten.`,
      prp147: `Einzelheiten zum Widerspruchsrecht gegen die Verarbeitung`,
      prp148: `Wenn personenbezogene Daten im öffentlichen Interesse verarbeitet werden, gilt Folgendes:
                    Ausübung einer dem Eigentümer übertragenen offiziellen Autorität oder für
                    die Zwecke der vom Eigentümer verfolgten berechtigten Interessen,
                    Der Nutzer kann einer solchen Verarbeitung unter Angabe einer Begründung widersprechen
                    im Zusammenhang mit ihrer besonderen Situation, um dies zu rechtfertigen
                    Einspruch.`,
      prp149: `Benutzer müssen jedoch wissen, dass ihre personenbezogenen Daten personenbezogen sein sollten
                    zu Direktmarketingzwecken verarbeitet werden, können sie Widerspruch einlegen
                    dieser Verarbeitung jederzeit unentgeltlich und unentgeltlich widersprechen
                    Angabe einer Begründung. Wogegen der Nutzer Widerspruch einlegt
                    Verarbeitung der personenbezogenen Daten zu Direktmarketingzwecken
                    werden für diese Zwecke nicht mehr verarbeitet. Lernen
                    ob der Eigentümer personenbezogene Daten direkt verarbeitet
                    Zu Marketingzwecken können Benutzer auf die entsprechenden Abschnitte verweisen
                    dieses Dokuments.`,
      prp150: `So üben Sie diese Rechte aus`,
      prp151: `Alle Anfragen zur Ausübung von Benutzerrechten können an die gerichtet werden
                    Eigentümer über die in diesem Dokument angegebenen Kontaktdaten.
                    Solche Anfragen sind kostenlos und werden von der beantwortet
                    Eigentümer so früh wie möglich und immer innerhalb eines Monats,
                    Bereitstellung der gesetzlich vorgeschriebenen Informationen für Benutzer. Beliebig
                    Berichtigung oder Löschung personenbezogener Daten oder Einschränkung
                    Die Verarbeitung wird vom Eigentümer jedem mitgeteilt
                    ggf. Empfänger, an den die personenbezogenen Daten weitergegeben wurden
                    offengelegt, es sei denn, dies erweist sich als unmöglich oder erforderlich
                    unverhältnismäßiger Aufwand. Auf Wunsch des Benutzers wird der Eigentümer dies tun
                    Informieren Sie sie über diese Empfänger.`,
      prp152: `Übermittlung personenbezogener Daten außerhalb der Europäischen Union`,
      prp153: `Übermittlung personenbezogener Daten außerhalb der Europäischen Union`,
      prp154: `Datenübermittlung in Länder, die europäische Standards garantieren`,
      prp155: `Wenn dies die Bedingung für die Datenübertragung ist, erfolgt die Übertragung von
                    Es erfolgt eine Übermittlung personenbezogener Daten aus der EU an Drittländer
                    gemäß einem Angemessenheitsbeschluss der Europäischen Kommission.
                    Die Europäische Kommission erlässt für bestimmte Zwecke Angemessenheitsbeschlüsse
                    Länder, wann immer es der Ansicht ist, dass dieses Land besitzt und
                    Bereitstellung vergleichbarer Standards zum Schutz personenbezogener Daten
                    in der EU-Datenschutzgesetzgebung festgelegt. Benutzer können eine finden
                    aktualisierte Liste aller Angemessenheitsentscheidungen, die auf der Europäischen Union erlassen wurden
                    Website der Kommission.`,
      prp156: `Zusätzliche Informationen zur Datenerhebung und -verarbeitung`,
      prp157: `Rechtliche Schritte`,
      prp158: `Die personenbezogenen Daten des Nutzers können von der Website für rechtliche Zwecke verwendet werden
                    Eigentümer vor Gericht oder in den Phasen, die zu einer möglichen rechtlichen Einleitung führen
                    Maßnahmen, die sich aus der unsachgemäßen Verwendung dieser Anwendung oder der
                    damit verbundene Dienstleistungen. Der Nutzer erklärt, sich darüber im Klaren zu sein, dass der Eigentümer
                    Auf Anfrage der Öffentlichkeit kann es erforderlich sein, personenbezogene Daten offenzulegen
                    Behörden.`,
      prp159: `Zusätzliche Informationen zu den personenbezogenen Daten des Benutzers`,
      prp160: `Zusätzlich zu den in dieser Datenschutzerklärung enthaltenen Informationen
                    Richtlinie kann diese Anwendung dem Benutzer zusätzliche Informationen bereitstellen
                    und Kontextinformationen zu bestimmten Diensten oder
                    die Erhebung und Verarbeitung personenbezogener Daten auf Anfrage.`,
      prp161: `Systemprotokolle und Wartung`,
      prp162: `Für Betriebs- und Wartungszwecke sind diese Anwendung und
                    Alle Dienste Dritter können Dateien sammeln, die aufzeichnen
                    Interaktion mit dieser Anwendung (Systemprotokolle) oder andere verwenden
                    Zu diesem Zweck werden personenbezogene Daten (z. B. die IP-Adresse) erhoben.`,
      prp163: `Informationen, die nicht in dieser Richtlinie enthalten sind`,
      prp164: `Weitere Einzelheiten zur Erhebung oder Verarbeitung von
                    Persönliche Daten können jederzeit vom Eigentümer angefordert werden.
                    Bitte beachten Sie die Kontaktinformationen am Anfang
                    dokumentieren.`,
      prp165: `Änderungen dieser Datenschutzrichtlinie`,
      prp166: `Der Eigentümer behält sich das Recht vor, Änderungen an dieser Datenschutzerklärung vorzunehmen
                    Richtlinie jederzeit durch Benachrichtigung seiner Benutzer auf dieser Seite ändern und
                    ggf. innerhalb dieser Anmeldung und/oder – soweit
                    technisch und rechtlich machbar – Versenden einer Mitteilung an Benutzer
                    über alle dem Eigentümer zur Verfügung stehenden Kontaktinformationen. Es ist
                    Es wird dringend empfohlen, diese Seite häufig zu überprüfen und darauf zu verweisen
                    das Datum der letzten Änderung unten aufgeführt.`,
      prp167: `Sollten sich die Änderungen auf die durchgeführten Verarbeitungstätigkeiten auswirken?
                    Auf der Grundlage der Zustimmung des Nutzers holt der Eigentümer neue ein
                    Einwilligung des Nutzers, sofern erforderlich.`,
      prp168: `Definitionen und rechtliche Hinweise`,
      prp169: `Persönliche Daten (oder Daten)`,
      prp170: `Alle Informationen, die direkt, indirekt oder in Verbindung stehen
                    mit anderen Informationen – einschließlich einer persönlichen Identifikation
                    Nummer – ermöglicht die Identifizierung oder Identifizierbarkeit eines
                    natürliche Person.`,
      prp171: `Nutzungsdaten`,
      prp172: `Durch diese Anwendung automatisch erfasste Informationen
                    (oder in dieser Anwendung eingesetzte Dienste Dritter), die
                    kann Folgendes umfassen: die IP-Adressen oder Domänennamen der Computer
                    Der URI wird von den Benutzern verwendet, die diese Anwendung verwenden
                    Adressen (Uniform Resource Identifier), die Uhrzeit der
                    Anfrage, die Methode, mit der die Anfrage an den gesendet wurde
                    Server, die Größe der als Antwort empfangenen Datei, die
                    Zahlencode, der den Status der Antwort des Servers angibt
                    (erfolgreiches Ergebnis, Fehler usw.), das Herkunftsland, die
                    Funktionen des verwendeten Browsers und Betriebssystems
                    dem Nutzer die verschiedenen Zeitangaben pro Besuch (z.B. die Uhrzeit).
                    der für jede Seite innerhalb der Anwendung aufgewendeten Betrag) und die Einzelheiten
                    über den Pfad, der innerhalb der Anwendung mit besonderem Inhalt verfolgt wird
                    Verweis auf die Reihenfolge der besuchten Seiten und anderes
                    Parameter über das Betriebssystem des Geräts und/oder des Benutzers
                    IT-Umgebung.`,
      prp173: `Benutzer`,
      prp174: `Die Person, die diese Anwendung nutzt, sofern nicht anders angegeben
                    angegeben, mit der betroffenen Person übereinstimmt.`,
      prp175: `Betroffene Person`,
      prp176: `Die natürliche Person, auf die sich die personenbezogenen Daten beziehen.`,
      prp177: `Datenverarbeiter (oder Auftragsverarbeiter)`,
      prp178: `Die natürliche oder juristische Person, Behörde, Einrichtung oder andere Person
                    Stelle, die personenbezogene Daten im Auftrag verarbeitet
                    Verantwortlicher, wie in dieser Datenschutzerklärung beschrieben.`,
      prp179: ` Datenverantwortlicher (oder Eigentümer)`,
      prp180: `Die natürliche oder juristische Person, Behörde, Einrichtung oder andere Person
                    Körperschaft, die allein oder gemeinsam mit anderen das bestimmt
                    Zwecke und Mittel der Verarbeitung personenbezogener Daten,
                    einschließlich der Sicherheitsmaßnahmen bezüglich des Betriebs und
                    Nutzung dieser Anwendung. Der Datenverantwortliche, sofern nicht anders angegeben
                    angegeben, ist der Eigentümer dieser Anwendung.`,
      prp181: `Diese Anwendung`,
      prp182: `Die Mittel, mit denen die personenbezogenen Daten des Benutzers erfasst werden
                    und verarbeitet.`,
      prp183: `Dienst`,
      prp184: `Der von dieser Anwendung bereitgestellte Dienst, wie im beschrieben
                    relative Begriffe (falls verfügbar) und auf dieser Website/Anwendung.`,
      prp185: `Europäische Union (oder EU)`,
      prp186: `Sofern nicht anders angegeben, sind alle darin enthaltenen Verweise
                    Das Dokument an die Europäische Union umfasst alle derzeitigen Mitglieder
                    Staaten der Europäischen Union und dem Europäischen Wirtschaftsraum.`,
      prp187: `Cookie`,
      prp188: `Cookies sind Tracker, die aus kleinen gespeicherten Datenmengen bestehen
                    im Browser des Benutzers.`,
      prp189: `Tracker`,
      prp190: `Der Tracker weist jede Technologie – z. B. Cookies – eindeutig darauf hin
                    Identifikatoren, Web-Beacons, eingebettete Skripte, E-Tags und
                    Fingerabdruck – das ermöglicht die Verfolgung von Benutzern, z
                    beispielsweise durch Zugriff auf oder Speicherung von Informationen über den Benutzer
                    Gerät.`,
      prp191: `Rechtliche Informationen`,
      prp192: `Diese Datenschutzrichtlinie bezieht sich ausschließlich auf diese Anwendung, sofern dies nicht der Fall ist
                    In diesem Dokument wird nichts anderes angegeben.`,
      prp193: `Diese Datenschutzrichtlinie bezieht sich ausschließlich auf diese Anwendung, sofern dies nicht der Fall ist
                    Letzte Aktualisierung: 18. September 2024`,
    },
    vision: {
      visionTitle: "VISION",
      text: "Bei Studio M kombinieren wir die Kraft von künstlicher Intelligenz mit menschlicher Empathie und Expertise. Unsere Vision ist klar: das Recht auf mentale Gesundheitsfürsorge zu demokratisieren und es zu einem universellen Gut zu machen – für alle, jederzeit und überall.",

      text1:
        "In einer Welt, in der mehr als eine Milliarde Menschen unter psychischen Erkrankungen leiden, braucht es mehr als traditionelle Lösungen.",

      text2:
        "Hinter ebbi steht eine tiefe persönliche Leidenschaft für psychologisches Wohlbefinden und die Überzeugung, dass jeder Mensch - unabhängig von Zeit, Ort und finanziellen Möglichkeiten - Zugang zu qualitativer psychologischer Betreuung haben sollte. ",

      ceoName: "D. Marx, founder",

      text3:
        "Mit ebbi brechen wir die Barrieren, die Menschen von der notwendigen Hilfe trennen. Hohe Kosten, lange Wartezeiten, geografische Einschränkungen oder persönlicher Inkompatibilität mit einem Therapeuten? Diese Hürden gehören der Vergangenheit an! ebbi beweist, dass künstliche Intelligenz die Rolle eines einfühlsamen Beraters übernehmen kann – stets verfügbar, immer verständnisvoll. Mit jeder Interaktion wird ebbi smarter, lernt dazu und bietet tiefgreifende, persönliche Unterstützung.",

      text4:
        "Mit unseren beiden ersten, KI-gestützten Apps ebbi und FU, die von mentalem Wohlbefinden und Empowering bis hin zu echter Lernhilfe reichen, bieten wir sofortige Unterstützung in den emotionalen Momenten des Alltags für jeden. Was uns dabei auszeichnet, ist die nahtlose Verbindung von innovativer, mobiler Technologie und tiefem Verständnis für menschliche Bedürfnisse.",
      text5:
        "Was uns dabei auszeichnet, ist die nahtlose Verbindung von innovativer, mobiler Technologie und tiefem Verständnis für menschliche Bedürfnisse.",
      text6:
        "Unsere ersten Apps sind nur der Anfang eines revolutionären Prozesses, der das Leben von Milliarden Menschen verändern wird. Wir stehen an der Schwelle einer revolutionären Ära in der mentalen Gesundheitsfürsorge. Eine Ära, in der jeder Mensch die Unterstützung erhält, die er verdient, zu jedem emotionalen Moment seines Lebens. Und wir sind nicht allein! Begleitet werden wir von den ungeahnten Möglichkeiten im Zusammenspiel mit der sich rasant entwickelnden Künstlichen Intelligenz. ",
      text7: "Gemeinsam für eine bessere Zukunft.",
    },
  },
});

strings.setLanguage("en");

export default strings;
